<div class="uk-animation-slide-left-small" uk-grid>
  <div class="uk-width-1-1">

    <h2 class="uk-heading-divider">Manage Wallet</h2>

    <div class="uk-card uk-card-default uk-margin" *ngIf="!walletService.isLedgerWallet()">
      <div class="uk-card-header">
        <h3 class="uk-card-title">Change Wallet Password</h3>
      </div>
      <div class="uk-card-body">
        <div uk-grid>
          <div class="uk-width-1-2@s">
            <input class="uk-input" [(ngModel)]="newPassword" placeholder="New Password" type="password">
            <!-- <password-strength-meter *ngIf="newPassword.length > 0" [password]="newPassword" [enableFeedback]="true" [minPasswordLength]="6"></password-strength-meter> -->
            <span class="password-helper" *ngIf="newPassword.length > 0 && newPassword.length < 3">Password must be at least 6 characters long</span>
            <span class="password-helper" *ngIf="confirmPassword.length >= 6 && newPassword !== confirmPassword">Passwords do not match!</span>
          </div>
          <div class="uk-width-1-2@s">
            <input class="uk-input" [(ngModel)]="confirmPassword" placeholder="Confirm New Password" type="password">
          </div>
        </div>
      </div>
      <div class="uk-card-footer uk-text-right@s uk-text-center nlt-button-group">
        <button [disabled]="newPassword.length < 3 || (newPassword !== confirmPassword)" (click)="changePassword()" class="uk-button uk-button-primary uk-width-auto@s uk-width-1-1">Update Password</button>
      </div>
    </div>

    <div style="display: none;" class="uk-card uk-card-default uk-margin" *ngIf="!walletService.isLedgerWallet()">
      <div class="uk-card-header">
        <h3 class="uk-card-title">Backup Wallet</h3>
      </div>
      <div class="uk-card-body">
        <p *ngIf="wallet.locked && wallet.type === 'seed'">
          <span uk-icon="icon: lock;"></span> Unlock wallet to access the secret recovery seed/mnemonic.
        </p>
        <p *ngIf="wallet.locked && (wallet.type === 'privateKey' || wallet.type === 'expandedKey')">
          <span uk-icon="icon: lock;"></span> Unlock wallet to access the private key.
        </p>
        <p *ngIf="!wallet.locked && wallet.type === 'seed'">
          To backup your wallet's secret recovery mnemonic, <a [title]="('configure-wallet.new-wallet.copy-secret-recovery-mnemonic' | transloco)" ngxClipboard [cbContent]="seedMnemonic()" (cbOnSuccess)="notifications.sendSuccess('Wallet mnemonic copied to clipboard!')" uk-tooltip>click here</a> to copy it to your clipboard.
        </p>
        <p *ngIf="!wallet.locked && wallet.type === 'seed'">
          To backup your wallet's secret recovery seed, <a [title]="( 'configure-wallet.new-wallet.copy-secret-recovery-seed' | transloco )" ngxClipboard [cbContent]="wallet.seed" (cbOnSuccess)="notifications.sendSuccess('Wallet seed copied to clipboard!')" uk-tooltip>click here</a> to copy it to your clipboard.<br>
        </p>
        <p *ngIf="!wallet.locked && (wallet.type === 'privateKey' || wallet.type === 'expandedKey')">
          To backup your wallet account's private key, <a title="Copy Private Key" ngxClipboard [cbContent]="wallet.seed" (cbOnSuccess)="notifications.sendSuccess('Private Key copied to clipboard!')" uk-tooltip>click here</a> to copy it to your clipboard.<br>
        </p>
      </div>
    </div>

    <div class="uk-card uk-card-default uk-margin" *ngIf="!walletService.isLedgerWallet()">
      <div class="uk-card-header">
        <h3 class="uk-card-title">Export Nault File</h3>
      </div>
      <ng-container *ngIf="wallet.locked else walletUnlocked">
        <div class="uk-card-body">
          <span uk-icon="icon: lock;"></span> Unlock wallet to access the export tool.
        </div>
      </ng-container>
      <ng-template #walletUnlocked>
        <ng-container *ngIf="wallet.password else walletHasNoPassword">
          <div class="uk-card-body">
            <!-- Use this export tool to simplify wallet transfer to other devices. -->
            <!-- <br> -->
            Your data is encrypted with your wallet's password, and then can be imported into Nault on any device.
            <br>
            <br>
            <div *ngIf="showQRExport" uk-grid>
      
              <div class="uk-width-1-1@s uk-width-1-2@m uk-width-1-3@l">
                <img [src]="QRExportImg" alt="QR code" style="border-radius: 6px; max-width: 200px;">
              </div>
              <div class="uk-width-1-1@s uk-width-1-2@m uk-width-2-3@l">
                <br>
                Scan QR code on any device to import Nault wallet.<br>
                <br>
                <!-- You can also import your wallet by using the URL below. -->
                <!-- <br><br> -->
                <input type="text" class="uk-input" style="max-width: 750px;" value="{{ QRExportUrl }}">
                <div class="nlt-button-group uk-margin-small-top">
                  <button ngxClipboard [cbContent]="QRExportUrl" (cbOnSuccess)="notifications.sendSuccess('Wallet export copied to clipboard!')" class="uk-button uk-button-secondary" style="margin-left: 0;">Copy Clipboard</button>
                  <button (click)="exportToFile()" class="uk-button uk-button-primary">Download File</button>
                </div>
              </div>
            </div>
          </div>
          <div class="uk-card-footer uk-text-right@s uk-text-center nlt-button-group" *ngIf="!showQRExport">
            <button (click)="exportWallet()" class="uk-button uk-button-primary uk-width-auto@s uk-width-1-1">Export Wallet</button>
          </div>
        </ng-container>
        <ng-template #walletHasNoPassword>
          <div class="uk-card-body">
            You need to set a secure wallet password before you can use this export tool.
          </div>
        </ng-template>
      </ng-template>
    </div>

    <div class="uk-card uk-card-default uk-margin">
      <div class="uk-card-header">
        <h3 class="uk-card-title">Export Transaction History</h3>
      </div>
      <div *ngIf="!csvExportStarted" class="uk-card-body">
        <p>
          Use this tool to export the transaction history of a specific account as a CSV file.
        </p>
      </div>
      <div *ngIf="csvExportStarted" class="uk-card-body">
        <p>
          Due to limitations in backend servers, you can only request {{transactionHistoryLimit}} transactions per export.<br>
          However, you can use an offset of X to continue exporting from (X + 1). Another way is connecting Nault to a <a routerLink="/configure-app" routerLinkActive="active">custom server</a> for unlimited history.<br>
        </p>
        <div uk-grid>
          <div class="uk-width-1-1 narrow-div">
            <div class="uk-form-horizontal">
              <div class="uk-margin">
                <label class="uk-form-label" for="account">Account</label>
                <div class="uk-form-controls">
                  <select class="uk-select" id="account" [(ngModel)]="csvAccount">
                    <option *ngFor="let account of accounts" [value]="account.id">{{account.addressBookName ? account.addressBookName + ' - ' : '#' + account.index + ' - ' }} {{ account.id | squeeze }} ({{ account.balance | rai: 'mnano,true' | amountsplit: 0 }}{{ account.balance | rai: 'mnano,true' | amountsplit: 1 }} XNO)</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="uk-width-1-1 narrow-div">
            <div class="uk-form-horizontal">
              <div class="uk-margin">
                <label class="uk-form-label" for="tx-count">Transaction Limit <span uk-icon="icon: info;" uk-tooltip title="Maximum number of transactions to export. Blank or 0 equals unlimited but only effective if using a custom server."></span></label>
                <div class="uk-form-controls">
                  <div class="uk-inline uk-width-1-1">
                    <input [(ngModel)]="csvCount" class="uk-input uk-margin-small-bottom {{invalidCsvCount ? 'uk-form-danger':''}}" id="tx-count" type="text" (ngModelChange)="csvCountChange(csvCount)" placeholder="" autocomplete="off">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="uk-width-1-1 narrow-div">
            <div class="uk-form-horizontal">
              <div class="uk-margin">
                <label class="uk-form-label" for="tx-order">Transaction Order</label>
                <div class="uk-form-controls">
                  <div class="uk-inline uk-width-1-1">
                    <select class="uk-select" [(ngModel)]="selectedOrder" id="tx-order">
                      <option *ngFor="let option of orderOptions" [ngValue]="option.value">{{ option.name }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="uk-width-1-1 narrow-div">
            <div class="uk-form-horizontal">
              <div class="uk-margin">
                <label class="uk-form-label" for="tx-offset">Offset (optional) <span uk-icon="icon: info;" uk-tooltip title="Start the export after this index. 10 means starting at transaction 11. Blank or 0 equals no offset."></span></label>
                <div class="uk-form-controls">
                  <div class="uk-inline uk-width-1-1">
                    <input [(ngModel)]="csvOffset" class="uk-input uk-margin-small-bottom {{invalidCsvOffset ? 'uk-form-danger':''}}" id="tx-offset" type="text" (ngModelChange)="csvOffsetChange(csvOffset)" placeholder="" autocomplete="off">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="uk-card-footer uk-text-right@s uk-text-center nlt-button-group">
        <button *ngIf="!csvExportStarted" class="uk-button uk-button-primary uk-width-1-1@s uk-width-auto@m uk-float-right" type="button" [disabled]="!settings.settings.serverAPI" (click)="csvInit()">{{settings.settings.serverAPI ? 'GET STARTED':'NOT AVAILABLE IN OFFLINE MODE'}}</button>
        <button *ngIf="csvExportStarted && !exportingCsv" class="uk-button uk-button-primary uk-width-1-1@s uk-width-auto@m uk-float-right" type="button" [disabled]="!exportEnabled" (click)="exportToCsv()">{{exportEnabled ? 'EXPORT HISTORY':'COOLDOWN'}}</button>
        <button *ngIf="csvExportStarted && exportingCsv" class="uk-button uk-button-secondary uk-disabled uk-width-1-1@s uk-width-auto@m uk-float-right nlt-icon-button nlt-icon-button-inline" type="button"><span class="uk-margin-right" uk-spinner="ratio: 0.5;"></span> FETCHING HISTORY...</button>
      </div>
    </div>

  </div>
</div>
