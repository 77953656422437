<div class="uk-animation-slide-left-small" uk-grid>
  <div class="uk-width-1-1" id="address-book-sortable">

    <div class="uk-margin-bottom" uk-grid>
      <div class="uk-width-expand@s uk-width-1-1">
        <h2 class="uk-heading-divider uk-text-truncate">
          Representatives Book
          <a [routerLink]="'/representatives'" style="font-size: 12px; margin-left: 25px;">BACK TO REPRESENTATIVES OVERVIEW</a>
        </h2>
      </div>
      <div class="uk-width-auto@s uk-width-1-1 uk-text-right" *ngIf="activePanel == 0">
        <button class="uk-button uk-button-secondary uk-align-right uk-width-auto@s" (click)="addEntry()">Add New Entry</button>
      </div>
    </div>

    <div class="uk-width-1-1 nlt-page-intro" *ngIf="activePanel == 0">
      <p>
        You can use this page as a notebook for representatives. Here you can set custom display names to be shown throughout the application, mark representatives as trusted (please note that this will suppress warnings), or mark representatives that should be avoided.
      </p>
    </div>

    <div class="uk-animation-slide-left-small" *ngIf="activePanel == 0" uk-grid>
      <div class="uk-width-1-1">
        <div class="uk-card uk-margin">

          <ul class="uk-list uk-list-striped" style="margin-bottom: 0;">
            <li class="uk-list-header">
              <div uk-grid>
                <div class="uk-width-2-5">Name</div>
                <div class="uk-width-expand">Address</div>
                <div class="uk-width-1-6 uk-text-center" style="padding-left: 0;">Actions</div>
              </div>
            </li>
          </ul>

          <ul class="uk-list uk-list-striped" style="margin-top: 0;">
            <li class="" *ngFor="let representative of representatives$ | async;" [attr.data-account]="representative.id">
              <div uk-grid>
                <div class="uk-width-2-5 uk-text-truncate uk-visible-toggle">
                  <div uk-grid>
                    <div class="uk-width-expand uk-text-truncate">
                      <a (click)="editEntry(representative)" class="uk-link-text" title="Edit Entry" uk-tooltip>{{ representative.name }}</a>
                    </div>
                    <ul class="uk-iconnav uk-width-auto" style="padding-left: 0;">
                      <!--<li><span class="uk-sortable-handle uk-margin-small-right" uk-icon="icon: table"></span></li>-->
                      <li *ngIf="representative.trusted"><span class="uk-text-success" uk-icon="icon: star" uk-tooltip title="Representative marked as trusted"></span></li>
                      <li *ngIf="representative.warn"><span class="uk-text-small uk-text-warning" uk-tooltip title="Representative marked as 'should be avoided'"><span uk-icon="icon: warning"></span> Avoid</span></li>
                    </ul>
                  </div>
                </div>
                <div class="uk-width-expand uk-text-truncate uk-visible-toggle">
                  <div uk-grid>
                    <div class="uk-width-expand uk-text-truncate">
                      <a [routerLink]="'/account/' + representative.id" class="nano-address-clickable nano-address-monospace uk-display-block" title="View Account Details" uk-tooltip>
                        <app-nano-account-id [accountID]="representative.id"></app-nano-account-id>
                      </a>
                    </div>
                    <ul class="nano-address-actions uk-iconnav">
                      <li><a ngxClipboard [cbContent]="representative.id" (cbOnSuccess)="copied()" uk-icon="icon: copy" title="Copy Account Address" uk-tooltip></a></li>
                    </ul>
                  </div>

                </div>
                <div class="uk-width-1-6 uk-text-center" style="padding-left: 0;">
                  <a (click)="deleteRepresentative(representative.id)" class="uk-text-danger" title="Delete Entry" uk-tooltip><span uk-icon="icon: trash;"></span></a>
                </div>
              </div>

            </li>
            <li *ngIf="!(representatives$ | async).length" class="uk-text-center">
              You do not have any representatives saved yet, <a (click)="addEntry()">click here to add new entry</a>.
            </li>
          </ul>

        </div>
      </div>
    </div>

    <div class="uk-animation-slide-left-small" *ngIf="activePanel == 1" uk-grid>
      <div class="uk-width-1-1">
        <div class="uk-card uk-card-default">
          <div class="uk-card-header">
            <h2 class="uk-card-title">{{ creatingNewEntry ? 'Add New Entry' : 'Edit Entry' }}</h2>
          </div>
          <div class="uk-card-body">
            <div class="uk-form-horizontal">
              <div class="uk-margin">
                <label class="uk-form-label" for="new-address-name">Name</label>
                <div class="uk-form-controls">
                  <input type="text" class="uk-input" id="new-address-name" [(ngModel)]="newRepName" (keyup.enter)="saveNewRepresentative()" placeholder="Representative Name">
                </div>
              </div>

              <div class="uk-margin">
                <label class="uk-form-label" for="new-address-account">Address</label>
                <div class="uk-form-controls">
                  <input type="text" class="uk-input" id="new-address-account" [(ngModel)]="newRepAccount" [disabled]="!creatingNewEntry" placeholder="nano_abc123">
                </div>
              </div>

              <div class="uk-margin">
                <label class="uk-form-label">Representative Flags</label>
                <div class="uk-form-controls">
                  <div uk-grid>
                    <div class="uk-width-1-1">
                      <label style="cursor: pointer;" uk-tooltip title="Suppress all warnings about this representative"><input type="checkbox" class="uk-checkbox" [(ngModel)]="newRepTrusted"> &nbsp; Trusted Representative</label>
                    </div>
                    <div class="uk-width-1-1 uk-margin-small-top">
                      <label style="cursor: pointer;" uk-tooltip title="Warn when using this representative"><input type="checkbox" class="uk-checkbox" [(ngModel)]="newRepWarn"> &nbsp; Avoid Representative</label>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>
          <div class="uk-card-footer uk-text-right@s nlt-button-group">
            <button class="uk-button uk-button-danger uk-width-1-1@s uk-width-auto@m" (click)="cancelNewRep()">Cancel</button>
            <button class="uk-button uk-button-primary uk-width-1-1@s uk-width-auto@m" (click)="saveNewRepresentative()">Save</button>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>
