<div uk-grid>
  <div class="uk-width-1-1">
    <h2 *ngIf="shouldSign">Sign Nano Block</h2>
    <h2 *ngIf="!shouldSign">Process Nano Block</h2>

    <!-- Confirmation Panel -->
    <div uk-grid *ngIf="activePanel == 'confirm'" class="uk-animation-slide-left">
      <div class="uk-width-1-1">
        <div class="uk-card uk-card-default uk-width-1-1 uk-text-center" style="margin-bottom: 20px;">
          <div class="uk-card-body" style="padding: 15px;">
            <div [class.uk-text-danger]="txType == txTypes.send" [class.uk-text-success]="txType == txTypes.receive" [class.text-rep-change]="txType == txTypes.change">
              <div *ngIf="txType == txTypes.change" style="margin-bottom: 7px;" uk-icon="icon: cog; ratio: 1.2;"><br></div>
              <div *ngIf="!qrCodeImageBlock && shouldSign">You are about to sign a block to {{txTypeMessage}}</div>
              <div *ngIf="qrCodeImageBlock && shouldSign">You have signed a block to {{txTypeMessage}}</div>
              <div *ngIf="!blockProcessed && !shouldSign">You are about to {{txTypeMessage}}</div>
              <div *ngIf="blockProcessed && !shouldSign">You have processed a block to {{txTypeMessage}}</div>
            </div>

            <div *ngIf="txType != txTypes.change">
              <div class="block-amount-primary" [class.uk-text-danger]="txType == txTypes.send" [class.uk-text-success]="txType == txTypes.receive">
                <span class="amount-integer">{{ rawAmount | rai: 'mnano,true' | amountsplit: 0 }}</span>
                <span class="amount-fractional">{{ rawAmount | rai: 'mnano,true' | amountsplit: 1 }}</span>
                <span class="currency-name">NANO</span>
              </div>
              <div style="margin: 8px 0 -1px 0;" class="text-half-muted" *ngIf="settings.settings.displayCurrency && amountFiat !== null">{{ amountFiat | fiat: settings.settings.displayCurrency }}</div>
              <div style="margin: 1px 0 -1px 0;" class="uk-text-muted" *ngIf="settings.settings.displayCurrency && amountFiat !== null">{{ price.price.lastPrice | fiat: settings.settings.displayCurrency }} / NANO</div>
            </div>
          </div>
        </div>

        <div class="account-snippet-container" style="margin-bottom: 20px;">
          <div class="account-snippet uk-text-truncate">
            <div class="uk-card uk-card-default uk-text-truncate" [class.identicons-enabled]="(settings.settings.identiconsStyle !== 'none')">
              <div class="uk-card-header">
                <div *ngIf="txType === txTypes.change" class="snippet-caption uk-text-small text-half-muted">Account</div>
                <div *ngIf="txType !== txTypes.change" class="snippet-caption uk-text-small text-half-muted">From Account</div>
                <div class="uk-flex uk-flex-nowrap uk-width-auto uk-text-truncate" style="max-width: calc(100% - 35px);">
                  <a [routerLink]="'/account/' + fromAccountID" class="uk-link-reset account-container uk-text-truncate" uk-tooltip title="View Account Details">
                    <div class="identicon-address-book-row">
                      <app-nano-identicon scale="12" [accountID]="fromAccountID" [settingIdenticonsStyle]="settings.settings.identiconsStyle" class="nano-identicon" *ngIf="(settings.settings.identiconsStyle !== 'none')"></app-nano-identicon>
                      <div class="account-label uk-text-truncate" *ngIf="fromAddressBook">{{ fromAddressBook }}</div>
                    </div>
                    <div class="nano-address-clickable nano-address-monospace uk-text-truncate"><app-nano-account-id [accountID]="fromAccountID"></app-nano-account-id></div>
                  </a>
                  <div class="snippet-address-actions uk-width-auto uk-flex uk-flex-bottom">
                    <ul class="uk-iconnav">
                      <li><a ngxClipboard [cbContent]="fromAccountID" (cbOnSuccess)="copied()" uk-icon="icon: copy" title="Copy Account Address" uk-tooltip></a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div *ngIf="txType !== txTypes.change" class="uk-card-body">
                <div uk-grid>
                  <div class="uk-width-1-2 uk-text-muted">
                    <div class="uk-text-small text-half-muted" style="height: 25px;">Balance</div>
                    <span class="block-balance">
                      <span class="balance-amount-primary">
                        <span class="amount-integer">{{ fromAccountBalance ? (fromAccountBalance | rai: 'mnano,true' | amountsplit: 0) : 'N/A' }}</span>
                        <span class="amount-fractional">{{ fromAccountBalance ? (fromAccountBalance | rai: 'mnano,true' | amountsplit: 1) : '' }}</span>
                        <span class="currency-name">{{ fromAccountBalance ? 'NANO' : '' }}</span>
                      </span>
                    </span>
                  </div>
                  <div class="uk-width-1-2 uk-text-right">
                    <div class="uk-text-small text-half-muted" style="height: 25px;"></div>
                    <span class="block-balance">
                      <span class="balance-amount-primary uk-text-danger">
                        <span class="amount-sign">-</span>
                        <span class="amount-integer">{{ rawAmount | rai: 'mnano,true' | amountsplit: 0 }}</span>
                        <span class="amount-fractional">{{ rawAmount | rai: 'mnano,true' | amountsplit: 1 }}</span>
                        <span class="currency-name">NANO</span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="account-snippet uk-text-truncate">
            <div class="uk-card uk-card-default uk-text-truncate" [class.identicons-enabled]="(settings.settings.identiconsStyle !== 'none')">
              <div class="uk-card-header">
                <div *ngIf="txType === txTypes.change" class="snippet-caption uk-text-small text-half-muted">New Representative</div>
                <div *ngIf="txType !== txTypes.change" class="snippet-caption uk-text-small text-half-muted">To Account</div>
                <div *ngIf="txType !== txTypes.change" class="uk-flex uk-flex-nowrap uk-width-auto uk-text-truncate" style="max-width: calc(100% - 35px);">
                  <a [routerLink]="'/account/' + toAccountID" class="uk-link-reset account-container uk-text-truncate" uk-tooltip title="View Account Details">
                    <div class="identicon-address-book-row">
                      <app-nano-identicon scale="12" [accountID]="toAccountID" [settingIdenticonsStyle]="settings.settings.identiconsStyle" class="nano-identicon" *ngIf="(settings.settings.identiconsStyle !== 'none')"></app-nano-identicon>
                      <div class="account-label uk-text-truncate" *ngIf="toAddressBook">{{ toAddressBook }}</div>
                    </div>
                    <div class="nano-address-clickable nano-address-monospace uk-text-truncate"><app-nano-account-id [accountID]="toAccountID"></app-nano-account-id></div>
                  </a>
                  <div class="snippet-address-actions uk-width-auto uk-flex uk-flex-bottom">
                    <ul class="uk-iconnav">
                      <li><a ngxClipboard [cbContent]="toAccountID" (cbOnSuccess)="copied()" uk-icon="icon: copy" title="Copy Account Address" uk-tooltip></a></li>
                    </ul>
                  </div>
                </div>
                <div *ngIf="txType === txTypes.change" class="uk-flex uk-flex-nowrap uk-width-auto uk-text-truncate" style="max-width: calc(100% - 35px);">
                  <a [routerLink]="'/account/' + currentBlock.representative" class="uk-link-reset account-container uk-text-truncate" uk-tooltip title="View Account Details">
                    <div class="identicon-address-book-row">
                      <app-nano-identicon scale="12" [accountID]="currentBlock.representative" [settingIdenticonsStyle]="settings.settings.identiconsStyle" class="nano-identicon" *ngIf="(settings.settings.identiconsStyle !== 'none')"></app-nano-identicon>
                      <div class="account-label uk-text-truncate" *ngIf="repAddressBook">{{ repAddressBook }}</div>
                    </div>
                    <div class="nano-address-clickable nano-address-monospace uk-text-truncate"><app-nano-account-id [accountID]="currentBlock.representative"></app-nano-account-id></div>
                  </a>
                  <div class="snippet-address-actions uk-width-auto uk-flex uk-flex-bottom">
                    <ul class="uk-iconnav">
                      <li><a ngxClipboard [cbContent]="currentBlock.representative" (cbOnSuccess)="copied()" uk-icon="icon: copy" title="Copy Account Address" uk-tooltip></a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div *ngIf="txType !== txTypes.change" class="uk-card-body">
                <div uk-grid>
                  <div class="uk-width-1-2 uk-text-muted">
                    <div class="uk-text-small text-half-muted" style="height: 25px;">Balance</div>
                    <span class="block-balance">
                      <span class="balance-amount-primary">
                        <span class="amount-integer">{{ toAccountBalance && txType === txTypes.receive ? (toAccountBalance | rai: 'mnano,true' | amountsplit: 0) : 'N/A' }}</span>
                        <span class="amount-fractional">{{ toAccountBalance && txType === txTypes.receive ? (toAccountBalance | rai: 'mnano,true' | amountsplit: 1) : '' }}</span>
                        <span class="currency-name">{{ toAccountBalance && txType === txTypes.receive ? 'NANO' : '' }}</span>
                      </span>
                    </span>
                  </div>
                  <div class="uk-width-1-2 uk-text-right">
                    <div class="uk-text-small text-half-muted" style="height: 25px;"></div>
                    <span class="block-balance">
                      <span class="balance-amount-primary uk-text-success">
                        <span class="amount-sign">+</span>
                        <span class="amount-integer">{{ rawAmount | rai: 'mnano,true' | amountsplit: 0 }}</span>
                        <span class="amount-fractional">{{ rawAmount | rai: 'mnano,true' | amountsplit: 1 }}</span>
                        <span class="currency-name">NANO</span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="shouldSign" class="uk-card uk-card-default uk-width-1-1" style="padding: 20px 20px;">
          <span><strong>Signing Method </strong></span><span uk-icon="icon: info;" uk-tooltip title="The block is signed with a private key. If using the build-in wallet, it first needs to be created using any of the available methods."></span><br>
          <div class="uk-inline uk-width-1-1" style="margin-top: 10px; max-width: 1000px;">
            <div uk-grid>
              <div class="uk-width-1-4@m" style="margin-top: 10px !important;"><label><input class="uk-radio" type="radio" name="signtype" value={{signTypes[0]}} [(ngModel)]="signTypeSelected" (change)="signTypeChange()"> {{signTypes[0]}}</label></div>
              <div class="uk-width-1-4@m" style="margin-top: 10px !important;"><label><input class="uk-radio" type="radio" name="signtype" value={{signTypes[1]}} [(ngModel)]="signTypeSelected" (change)="signTypeChange()"> {{signTypes[1]}}</label></div>
              <div class="uk-width-1-4@m" style="margin-top: 10px !important;"><label><input class="uk-radio" type="radio" name="signtype" value={{signTypes[2]}} [(ngModel)]="signTypeSelected" (change)="signTypeChange()"> {{signTypes[2]}}</label></div>
              <div class="uk-width-1-4@m" style="margin-top: 10px !important;"><label><input class="uk-radio" type="radio" name="signtype" value={{signTypes[3]}} [(ngModel)]="signTypeSelected" (change)="signTypeChange()"> {{signTypes[3]}}</label></div>
            </div>
          </div>
          <br><br>
          <span><strong>Proof of Work </strong></span><span uk-icon="icon: info;" uk-tooltip title="Proof of Work (PoW) can be optionally generated now and included in the block, or it will be added for you before the block is published. The threshold of 1x is normally ok but you may need higher if the network is saturated with transactions and you want it prioritized. Custom multiplier only works for local PoW source CPU/GPU."></span><br>
          <div class="uk-inline uk-width-auto">
            <select class="uk-select" [(ngModel)]="selectedThreshold" (change)="changeThreshold()">
              <option *ngFor="let option of thresholds" [value]="option.value">{{ option.name }}</option>
            </select>
          </div>
          <label class="uk-margin-small-left"><input class="uk-checkbox" type="checkbox" name="pow" value="pow" [(ngModel)]="shouldGenWork" (change)="powChange()"> Include</label>
          <br><br>

          <!-- Seed and index-->
          <div *ngIf="signTypeSelected === signTypes[1]">
            <div class="uk-width-1-1 narrow-div">
              <div class="uk-form-horizontal">
                <div class="uk-margin">
                  <label class="uk-form-label" for="source-wallet">Signing Wallet <span uk-icon="icon: info;" uk-tooltip title="The seed or mnemonic that contains the account you are signing for."></span></label>
                  <div class="uk-form-controls">
                    <div class="uk-inline uk-width-1-1" style="height: 40px;">
                      <a class="uk-form-icon uk-form-icon-flip" uk-icon="icon: camera" (click)="openQR('seed1','generic')" uk-tooltip title="Scan from QR code"></a>
                      <input [(ngModel)]="sourceSecret" class="uk-input uk-margin-small-bottom {{validSeed ? '':'uk-form-danger'}}" id="source-wallet" type="text" (ngModelChange)="seedChange(sourceSecret)" placeholder="Secret recovery seed, BIP39 seed or 24-word secret recovery mnemonic" autocomplete="off">
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="uk-width-1-1 narrow-div">
              <div class="uk-form-horizontal">
                <div class="uk-margin">
                  <label class="uk-form-label" for="start-index">Account Index <span uk-icon="icon: info;" uk-tooltip title="The index at where the account you are signing for is located in the seed. Maximum 4,294,967,295."></span></label>
                  <div class="uk-form-controls">
                    <div class="uk-inline uk-width-1-1">
                      <input [(ngModel)]="index" class="uk-input uk-margin-small-bottom {{validIndex ? '':'uk-form-danger'}}" id="start-index" type="text" maxLength="10" (ngModelChange)="indexChange(index)" placeholder="0 to 4294967295" autocomplete="off">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END Seed and index-->

          <!-- Private key-->
          <div *ngIf="signTypeSelected === signTypes[2]">
            <div class="uk-width-1-1 narrow-div">
              <div class="uk-form-horizontal">
                <div class="uk-margin">
                  <label class="uk-form-label" for="source-wallet">Private Key <span uk-icon="icon: info;" uk-tooltip title="The private key that derive the account you are signing for."></span></label>
                  <div class="uk-form-controls">
                    <div class="uk-width-1-1">
                      <div class="uk-inline uk-width-1-1" style="height: 40px; margin-bottom: 10px;">
                        <a class="uk-form-icon uk-form-icon-flip" uk-icon="icon: camera" (click)="openQR('priv1','hash')" uk-tooltip title="Scan from QR code"></a>
                        <input [(ngModel)]="sourcePriv" class="uk-input uk-margin-small-bottom {{validPrivkey ? '':'uk-form-danger'}}" maxLength="128" id="source-wallet" type="text" (ngModelChange)="privkeyChange(sourcePriv)" placeholder="Private key or Expanded private key" autocomplete="off">
                      </div>
                      <label><input class="uk-checkbox" type="checkbox" name="expanded" value='expanded' [(ngModel)]="privateKeyExpanded" (change)="privkeyChange(sourcePriv)"> Expanded private key </label><span uk-icon="icon: info;" uk-tooltip title="If using a 64 or 128 char expanded key."></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END Private key-->

          <!-- Multisig-->
          <div *ngIf="signTypeSelected === signTypes[3]">
            <div class="uk-width-1-1 narrow-div">
              <p>Each participant of the multisig will provide their key. Enter number of participants, copy the signing link and share it with the others.</p>
              <div class="uk-form-horizontal">
                <div class="uk-margin">
                  <label class="uk-form-label" for="participants">Participants <span uk-icon="icon: info;" uk-tooltip title="Number of participants to multi-sign including yourself"></span></label>
                  <div class="uk-form-controls">
                    <div class="uk-inline uk-width-1-1">
                      <input [(ngModel)]="participants" class="uk-input uk-margin-small-bottom {{validParticipants ? '':'uk-form-danger'}}" [disabled]="this.activeStep > 1" id="participants" type="number" maxLength="3" (ngModelChange)="participantChange(participants)" placeholder="2 to 999" autocomplete="off">
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="uk-width-1-1 narrow-div">
              <div class="uk-form-horizontal">
                <div class="uk-margin">
                  <label class="uk-form-label" for="source-wallet">Private Key <span uk-icon="icon: info;" uk-tooltip title="Any private key that is part of the multisig address you want to sign."></span></label>
                  <div class="uk-form-controls">
                    <div class="uk-width-1-1">
                      <div class="uk-inline uk-width-1-1" style="height: 40px; margin-bottom: 10px;">
                        <a class="uk-form-icon uk-form-icon-flip" uk-icon="icon: camera" (click)="openQR('priv1','hash')" uk-tooltip title="Scan from QR code"></a>
                        <input [(ngModel)]="sourcePriv" class="uk-input uk-margin-small-bottom {{validPrivkey ? '':'uk-form-danger'}}" [disabled]="this.activeStep > 1" maxLength="64" id="source-wallet" type="text" (ngModelChange)="privkeyChangeMulti(sourcePriv)" placeholder="Private key" autocomplete="off">
                      </div>
                    </div>
                  </div>
                  <label *ngIf="!isDesktop"><input class="uk-checkbox" type="checkbox" name="multitab" value='multitab' [(ngModel)]="tabChecked" (change)="tabModeCheck()"> Multi-Tab Mode </label><span *ngIf="!isDesktop" uk-icon="icon: info;" uk-tooltip title="If using one browser tab for each private key on the same computer."></span>
                </div>
              </div>
            </div>

            <div *ngIf="this.activeStep > 1">
              <div class="uk-width-1-1 narrow-div" >
                <div class="uk-form-horizontal">
                  <div class="uk-margin">
                    <label class="uk-form-label" for="output-data">Output Data <span uk-icon="icon: info;" uk-tooltip title="Copy this to the 'Input Data' for each participant"></span></label>
                    <div class="uk-form-controls">
                      <div class="uk-width-1-1">
                        <div class="uk-inline uk-width-1-1" style="height: 40px; margin-bottom: 10px;">
                          <a *ngIf="outputMultisigData !== ''" class="uk-form-icon uk-form-icon-flip second-icon" ngxClipboard [cbContent]="outputMultisigData" (cbOnSuccess)="copied()" uk-icon="icon: copy" title="Copy Output Value" uk-tooltip></a>
                          <a *ngIf="outputMultisigData !== ''" class="uk-form-icon uk-form-icon-flip icon-qr-code" (click)="qrModal.show()" uk-tooltip title="Show QR Code"></a>
                          <input [(ngModel)]="outputMultisigData" class="uk-input uk-margin-small-bottom" style="padding-right:70px !important" disabled id="output-data" type="text" placeholder="" autocomplete="off">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="uk-width-1-1 narrow-div" >
                <div class="uk-form-horizontal">
                  <div class="uk-margin">
                    <label class="uk-form-label">Input Data <span uk-icon="icon: info;" uk-tooltip title="Output data from participants to be added"></span></label>
                    <div class="uk-form-controls">
                      <ul class="uk-list uk-list-striped">
                        <li *ngFor="let data of inputMultisigData">
                          <div uk-grid>
                            <div class="uk-width-5-6 uk-text-truncate">
                              <ng-container>
                                <div class="uk-text-truncate">{{ data }}</div>
                              </ng-container>
                            </div>
                            <div class="action-remove uk-width-1-6 uk-text-right">
                              <a (click)="removeSelectedData(data)" class="uk-text-danger" title="Remove From List" uk-tooltip><span uk-icon="icon: close;"></span></a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div *ngIf="!showAddBox && !this.isInputAddDisabled" class="add-button uk-width-auto" (click)="setFocus()"><span uk-icon="icon: plus"></span> Add participant data from step {{(this.activeStep - 1)}}</div>
                          <div *ngIf="showAddBox" class="uk-inline uk-width-1-1">
                            <a class="uk-form-icon uk-form-icon-flip" uk-icon="icon: plus" (click)="addMultisigInputData()" uk-tooltip title="Add input data"></a>
                            <input [(ngModel)]="inputAdd" [disabled]="this.isInputAddDisabled" maxLength="130" class="uk-input {{validInputAdd ? '':'uk-form-danger'}}" type="text" placeholder="{{(this.activeStep - 1) + ':abc...'}}" (ngModelChange)="inputAddChange(inputAdd)" (keyup.enter)="addMultisigInputData()" autocomplete="off" #dataAddFocus>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="uk-width-1-1 narrow-div">
              <div class="uk-inline uk-width-1-1">
                <div uk-grid>
                  <div class="uk-width-1-2@l" style="max-width: 350px; margin-top: 20px !important;">
                    <a class="uk-button uk-button-primary uk-width-1-1" ngxClipboard [cbContent]="multisigLink" (cbOnSuccess)="copied()">COPY SIGNING LINK</a>
                  </div>
                  <div class="uk-width-1-2@l" style="max-width: 350px; margin-top: 20px !important;">
                    <button class="uk-button uk-button-primary uk-width-1-1" (click)="resetMultisig()">RESET MULTISIG</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END Multisig-->

          <span class="uk-text-danger">{{signatureMessage}}</span>
          <span class="uk-text-success">{{signatureMessageSuccess}}</span>

        </div>
        <br>

        <div *ngIf="processedHash" class="uk-card uk-card-default uk-width-1-1 uk-text-center" style="padding: 20px 20px;">
          <div class="uk-visible-toggle">
            <div uk-grid class="uk-width-auto">
              <div class="uk-width-auto uk-text-truncate" style="max-width: calc(100% - 35px); flex: auto;">
                <span class="uk-text-success">Successfully processed block: </span><a [routerLink]="'/transaction/' + processedHash" class="uk-link-text" title="View Block Details" uk-tooltip>{{ processedHash }}</a>
              </div>
              <div class="uk-width-auto" style="padding-left: 10px; flex: auto;">
                <ul class="uk-iconnav">
                  <li><a ngxClipboard [cbContent]="processedHash" (cbOnSuccess)="copied()" uk-icon="icon: copy" title="Copy Block Hash" uk-tooltip></a></li>
                </ul>
              </div>
            </div>
          </div>

          <br>
          <a *ngIf="processedHash" [routerLink]="'/account/' + signatureAccount" [queryParams]="{ sign: 1 }" class="uk-button uk-button-primary uk-width-auto" title="Account detail view" uk-tooltip>Account Detail View</a>
        </div>

        <div *ngIf="!processedHash">
          <div class="nlt-button-group uk-width-1-1 uk-text-right">
            <button class="uk-button uk-button-danger uk-width-1-1@s uk-width-auto@m" (click)="cancel()">Cancel</button>
            <button *ngIf="!confirmingTransaction && shouldSign && signTypeSelected !== signTypes[3]" class="uk-button uk-button-primary uk-width-1-1@s uk-width-auto@m" (click)="confirmTransaction()">Confirm & Sign</button>
            <button *ngIf="!confirmingTransaction && shouldSign && signTypeSelected === signTypes[3]" class="uk-button uk-button-primary uk-width-1-1@s uk-width-auto@m" (click)="startMultisig()" [disabled]="!this.validPrivkey || !this.validParticipants || finalSignature || (this.activeStep > 1 && !this.isInputAddDisabled)">{{this.activeStep !== 4 ? (this.activeStep === 1 ? 'Start Multi-signing' : 'Step '+ (this.activeStep - 1) + '/3 | Next') : 'Step '+ (this.activeStep - 1) + '/3 | Final Step'}}</button>
            <button *ngIf="confirmingTransaction && shouldSign" class="uk-button uk-button-primary uk-disabled uk-width-1-1"><span class="uk-margin-right" uk-spinner></span> Loading</button>
            <button *ngIf="!confirmingTransaction && !shouldSign" class="uk-button uk-button-primary uk-width-1-1@s uk-width-auto@m" (click)="confirmBlock()">Confirm & Process</button>
            <button *ngIf="confirmingTransaction && !shouldSign" class="uk-button uk-button-primary uk-disabled uk-width-1-1"><span class="uk-margin-right" uk-spinner></span> Loading</button>
          </div>
        </div>
      </div>
    </div>
    <!-- End Confirmation Panel -->

    <div uk-grid *ngIf="activePanel == 'error'" class="uk-animation-slide-left">
      <div class="uk-width-1-1">
        <div class="uk-card uk-card-default uk-width-1-1 uk-text-center">
          <span style="display: block; padding-top: 8px;">The provided block info is not valid or Nault got connection issues.<br/> Try set Nault in Offline Mode from the app settings if you want to be offline.<br/></span>
        </div>
      </div>
    </div>

  </div>
</div>

<!-- Modal for result QR -->
<div class="modal-position-bottom" id="signed-modal" uk-modal>
  <div class="uk-modal-dialog uk-modal-body modal-qr">
    <button class="uk-modal-close-default" type="button" uk-close></button>
    <div class="uk-modal-header">
        <h2 class="uk-modal-title">Signed Block</h2>
    </div>
    <div class="uk-modal-body modal-qr-body">
      <p><a routerLink="/qr-scan" routerLinkActive="active" class="uk-modal-close">Scan</a> the signed QR with an online Nault, or copy the "Signed Block" to <a routerLink="/remote-signing" routerLinkActive="active" class="uk-modal-close">Step 3</a>.</p>
      
      <div *ngIf="qrString && finalSignature" class="uk-width-1-1 uk-text-center" style="display: flex; justify-content: center;">
        <span class="uk-text-small" uk-tooltip title="Signed block string to be copied to remote device" style="overflow-wrap: anywhere;"><strong>Signed Block</strong></span>
        <ul class="uk-iconnav" style="padding-left: 0;">
          <li><a ngxClipboard [cbContent]="qrString" (cbOnSuccess)="copied()" uk-icon="icon: copy" title="Copy" uk-tooltip></a></li>
        </ul>
        <span class="uk-text-small" uk-tooltip title="Signature to be embedded into the final block" style="overflow-wrap: anywhere; margin-left: 50px;"><strong>Signature</strong></span>
        <ul class="uk-iconnav" style="padding-left: 0;">
          <li><a ngxClipboard [cbContent]="finalSignature" (cbOnSuccess)="copied()" uk-icon="icon: copy" title="Copy" uk-tooltip></a></li>
        </ul>
      </div>

      <div class="uk-width-1-1 uk-text-center">
        <img *ngIf="qrCodeImageBlock" [src]="qrCodeImageBlock" alt="QR code"><br />
      </div>
    </div>
  </div>
</div>

<!-- Modal for output data QR -->
<div class="modal-position-bottom" id="qr-code-modal" uk-modal>
  <div class="uk-modal-dialog uk-margin-auto-vertical">
    <button class="uk-modal-close-default" type="button" uk-close></button>
    <div class="uk-modal-body">
      <div class="uk-width-1-1 uk-text-center">
        <img *ngIf="qrCodeImageOutput else qrCodeGenerating" [src]="qrCodeImageOutput" class="qr-code" title="Output Data">
        <ng-template #qrCodeGenerating>
          <div class="qr-code-placeholder uk-align-center"><div uk-spinner="ratio: 2;"></div></div>
        </ng-template>
      </div>
    </div>
  </div>
</div>


