<div class="uk-animation-slide-left-small" uk-grid>
  <div
    class="uk-width-1-1"
    [class.add-whitespace-underneath]="walletAccount && (showFullDetailsOnSmallViewports === false)"
  >

    <div class="nano-card is-card" *ngIf="walletAccount && (showFullDetailsOnSmallViewports === false)">
      <div class="logo"></div>
      <div class="card-contents">
        <div class="account-name-container">
          <div class="name">{{
              addressBookEntry
            ? addressBookEntry
            : (('general.account' | transloco) + ' #' + walletAccount.index)
          }}</div>
          <div #selectButton class="select-button closed" (click)="mobileAccountMenuModal.show()">
            <div class="circle"></div>
            <div class="circle"></div>
            <div class="circle"></div>
          </div>
        </div>
        <div class="account-balances">
          <ng-container *ngIf="!loadingAccountDetails else cardBalancesLoading">
            <div class="balance-container primary">
              <!-- <div class="currency-name">NANO</div> -->
              <div class="amount-container">
                <div class="amount-integer">{{ !account ? 0 : (account.balance || 0 | rai: 'mnano,true') | amountsplit: 0 }}</div>
                <div class="amount-fractional">{{ !account ? 0 : (account.balance || 0 | rai: 'mnano,true') | amountsplit: 1 }}</div>
                <div class="amount-updating" *ngIf="wallet.updatingBalance && isConfigured()"><div uk-spinner="ratio: 0.5;"></div></div>
              </div>
            </div>
            <div class="balance-container converted" *ngIf="settings.settings.displayCurrency">
              <div class="currency-name"><span class="estimate-symbol">~</span>{{ settings.settings.displayCurrency }}</div>
              <div class="amount-container">
                <div class="amount-integer">{{ (account.balanceFiat | fiat: settings.settings.displayCurrency) | amountsplit: 0 }}</div>
                <div class="amount-fractional">{{ (account.balanceFiat | fiat: settings.settings.displayCurrency) | amountsplit: 1 }}</div>
              </div>
            </div>
          </ng-container>
          <ng-template #cardBalancesLoading>
            <div class="spinner-container">
              <div uk-spinner="ratio: 0.6;"></div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>

    <h2
      class="uk-heading-divider"
      [class.hide-on-small-viewports]="walletAccount && (showFullDetailsOnSmallViewports === false)"
    >{{ walletAccount ? ('account-details.account-details' | transloco) : ('account-details.network-explorer' | transloco) }}</h2>

    <div
      class="uk-card uk-card-default uk-margin"
      [class.hide-on-small-viewports]="walletAccount && (showFullDetailsOnSmallViewports === false)"
    >

      <div class="account-details-header uk-card-header uk-visible-toggle">

        <div uk-grid>

          <app-nano-identicon scale="12" [accountID]="accountID" [settingIdenticonsStyle]="settings.settings.identiconsStyle" class="nano-identicon" *ngIf="(settings.settings.identiconsStyle !== 'none')"></app-nano-identicon>

          <div class="uk-width-1-1 uk-width-expand@s">

            <div
              *ngIf="walletAccount && (addressBookEntry || showEditAddressBook)"
              class="account-type-label"
              [class.editing-name]="showEditAddressBook"
            >
              {{ ('general.account' | transloco) + ' #' + walletAccount.index }}
            </div>
            <div
              *ngIf="!walletAccount"
              class="account-type-label"
              [class.editing-name]="showEditAddressBook"
            >
              {{ 'account-details.external-address' | transloco }}
            </div>

            <div uk-grid>
              <div class="uk-width-1-1" *ngIf="showEditAddressBook">
                <div class="uk-margin">
                  <div class="uk-inline uk-width-1-1">
                    <a class="uk-form-icon uk-form-icon-flip uk-text-success" (click)="saveAddressBook()" uk-icon="icon: check" uk-tooltip [title]="'general.save' | transloco"></a>
                    <a class="uk-form-icon uk-form-icon-flip uk-text-danger" style="margin-right: 26px;" (click)="showEditAddressBook = false" uk-tooltip [title]="'general.cancel' | transloco" uk-icon="icon: close"></a>
                    <input class="uk-input" (keyup.enter)="saveAddressBook()" [(ngModel)]="addressBookModel" [placeholder]="'account-details.address-book-name-leave-blank-to-remove' | transloco" type="text" style="padding-right: 60px;" maxlength="64">
                  </div>
                </div>
              </div>
              <div class="uk-width-1-1" *ngIf="!showEditAddressBook">
                <div class="uk-width-1-1">
                  <div uk-grid>
                  <h3 class="uk-width-auto uk-card-title uk-text-truncate" style="max-width: calc(100% - 35px);">{{
                      addressBookEntry
                    ? addressBookEntry
                    : (
                        walletAccount
                      ? (('general.account' | transloco) + ' #' + walletAccount.index)
                      : ('account-details.no-label-set' | transloco)
                    )
                  }}</h3>
                    <div class="uk-width-auto" style="padding-left: 10px; margin-top: 3px;">
                      <a style="display: contents;"><span uk-icon="icon: pencil;" uk-tooltip (click)="showEditAddressBook = !showEditAddressBook" [title]="'general.edit' | transloco"></span></a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-1 uk-margin-small-top">
                <div uk-grid>
                  <div class="nano-address-monospace uk-width-auto uk-text-small uk-text-truncate" style="max-width: calc(100% - 60px);">
                    <app-nano-account-id [accountID]="accountID" [known]="known"></app-nano-account-id>
                  </div>
                  <div class="nano-address-actions uk-width-auto" style="margin-top: 0;">
                    <ul class="uk-iconnav">
                      <li><a ngxClipboard [cbContent]="accountID" (cbOnSuccess)="copied()" uk-icon="icon: copy" [title]="'general.copy' | transloco" uk-tooltip></a></li>
                      <li><a (click)="qrModal.show()" [title]="'general.show-qr-code' | transloco" uk-tooltip class="icon-qr-code"></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="uk-card-header uk-form-horizontal uk-width-expand" *ngIf="settings.settings.serverAPI">

        <div uk-grid>

          <div class="representative-details uk-width-1-1@s uk-width-1-2@l">

            <div class="details-header">{{ 'general.representative' | transloco }}</div>

            <div *ngIf="!loadingAccountDetails else repLoading">
              <div class="uk-width-auto uk-text-truncate" *ngIf="(account && account.representative) else noRepresentative">
                <div class="representative-label-container">
                  <div class="account-label rep">{{ repLabel ? repLabel : ('general.unknown' | transloco) }}</div>
                  <div class="uk-width-auto uk-flex-inline uk-flex-middle" style="padding-left: 10px;" *ngIf="account && account.representative">
                    <div class="uk-text-small text-half-muted" style="margin-right: 15px" *ngIf="!repVotingWeight.isZero()">{{ repVotingWeight.toFixed(2) }}%</div>
                    <ul class="uk-iconnav">
                      <li *ngIf="repDonationAddress">
                        <a class="rep-donation-icon" uk-icon="icon: heart;" [title]="'account-details.donate-to-representative' | transloco" uk-tooltip routerLink="/send" [queryParams]="{ to: repDonationAddress }"></a>
                      </li>
                      <li *ngIf="walletAccount">
                        <a uk-icon="icon: pencil;" title="Change Representative" uk-tooltip routerLink="/representatives" [queryParams]="{ hideOverview: true, showRecommended: true, accounts: accountID }"></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="nano-address-monospace" [title]="account.representative">
                  <app-nano-account-id [accountID]="account.representative" middle="off" [known]="known"></app-nano-account-id>
                </div>
              </div>
              <ng-template #noRepresentative>
                <div class="uk-text-muted" style="margin-top: 8px;">{{ 'change-rep-widget.representative-none' | transloco }}</div>
              </ng-template>
            </div>
            <ng-template #repLoading>
              <div class="uk-margin-small-top">
                <div uk-spinner="ratio: 0.5;" style="vertical-align: 2px;"></div>
              </div>
            </ng-template>

          </div>

          <div class="balance-details uk-width-1-1@s uk-width-1-2@l">
            <div class="details-header">{{ 'general.balance' | transloco }}</div>
            <ng-container *ngIf="!loadingAccountDetails else balanceLoading">
              <div>
                <div class="account-amounts-primary">
                  <div
                    class="account-amounts-primary-confirmed"
                    [title]="( (account && account.balanceRaw && account.balanceRaw.gt(0) ) ? ( '+' + ( account.balanceRaw.toString(10)  ) + ' raw' ) : '' )"
                  >
                    <span class="amount-currency-symbol xno-symbol"></span>
                    <span class="amount-integer">{{ !account ? 0 : (account.balance || 0 | rai: 'mnano,true') | amountsplit: 0 }}</span>
                    <span class="amount-fractional">{{ !account ? 0 : (account.balance || 0 | rai: 'mnano,true') | amountsplit: 1 }}</span>
                  </div>
                  <div
                    *ngIf="account && account.pending && (account.pending > 0)"
                    class="incoming-label"
                    [title]="( (account && account.pendingRaw && account.pendingRaw.gt(0) ) ? ( '+' + ( account.pendingRaw.toString(10) ) + ' raw' ) : '' )"
                  >
                    <div class="text-snippet">{{ 'general.new' | transloco }}</div>
                    <div class="text-full">
                      <div class="amount-sign">+</div>
                      <div class="amount-currency-symbol xno-symbol"></div>
                      <div class="amount-integer">{{ account.pending | rai: 'mnano,true' | amountsplit: 0 }}</div>
                      <div class="amount-fractional">{{ account.pending | rai: 'mnano,true' | amountsplit: 1 }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="account-amounts-converted text-half-muted">
                <ng-container *ngIf="account">{{ (account.balanceFiat | fiat: settings.settings.displayCurrency) }}</ng-container>
              </div>
            </ng-container>
          </div>
          <ng-template #balanceLoading>
            <div class="uk-margin-small-top">
              <div uk-spinner="ratio: 0.5;" style="vertical-align: 2px;"></div>
            </div>
          </ng-template>
        </div>

      </div>

      <div class="uk-card-header uk-form-horizontal" *ngIf="settings.settings.serverAPI">

        <div class="details-header advanced-options-link" (click)="showAdvancedOptions = !showAdvancedOptions">
          {{ 'account-details.advanced-options' | transloco }}
          <div [class]="['chevron', ( showAdvancedOptions ? 'chevron-up' : 'chevron-down' )]"></div>
        </div>

        <div class="advanced-options" *ngIf="showAdvancedOptions">
          <div class="advanced-option" *ngIf="(account.confirmation_height != null) && (account.block_count != null) && (account.block_count > 0)">
            <div>
              <div>{{ 'account-details.confirmed-blocks' | transloco }}:</div>
              <div>
                {{ account.confirmation_height | translocoDecimal  }} / {{ account.block_count | translocoDecimal }}
              </div>
              <div class="uk-text-small text-half-muted">
                {{ ( (account.confirmation_height / account.block_count) * 100 ) | number:'1.0-2' }}%
              </div>
            </div>
          </div>
          <div class="advanced-option">
            <div>{{ 'account-details.enable-remote-signing-multisig' | transloco }}</div>
            <input class="uk-checkbox" type="checkbox" name="remoteVisible" value="1" [(ngModel)]="remoteVisible">
          </div>
        </div>

      </div>

      <div class="uk-card-header uk-form-horizontal" *ngIf="remoteVisible">

        <div class="details-header">{{ 'account-details.block-signing' | transloco }}</div>

        <p>Create a new SEND or CHANGE block below. For any incoming transaction in the list: Use SIGN BLOCK.</p>
        <button class="uk-button uk-button-primary" (click)="showRemoteModal()">CREATE NEW BLOCK</button>

      </div>

    </div>

    <div class="transactions-list-header uk-width-1-1 uk-flex uk-flex-center uk-flex-middle uk-margin-small-bottom">
      <h3 class="uk-margin-remove-bottom">{{ 'account-details.transactions' | transloco }}</h3>
      <ul class="uk-width-auto uk-iconnav uk-margin-remove-left" style="margin-top: -1px;">
        <li>
          <div
            uk-spinner="ratio: 0.5;"
            class="icon-transactions-refresh spinner"
            *ngIf="(loadingIncomingTxList || loadingTxList || loadingAccountDetails) else notUpdatingTxList"
          ></div>
          <ng-template #notUpdatingTxList>
            <a
              class="icon-transactions-refresh"
              [class.disabled]="!manualRefreshAllowed"
              [class.uk-text-muted]="!manualRefreshAllowed"
              uk-icon="icon: refresh;"
              [title]="'general.reload' | transloco"
              uk-tooltip
              (click)="onRefreshButtonClick()"
            ></a>
          </ng-template>
        </li>
      </ul>
    </div>

    <div class="uk-overflow-hidden">

      <div class="transactions-list-mobile only-on-small-viewports">
        <ng-container *ngFor="let transaction of pendingBlocks">
          <app-nano-transaction-mobile
            [transaction]="transaction"
            [isHidden]="transaction.received"
            [settingIdenticonsStyle]="settings.settings.identiconsStyle"
            (click)="showMobileMenuForTransaction(transaction)"
          ></app-nano-transaction-mobile>
        </ng-container>

        <!-- Don't show if "Loading transactions..." is already shown -->
        <div class="rounded-area" *ngIf="(loadingIncomingTxList && !loadingTxList && !loadingAccountDetails)">
          <div style="text-align: center;"><span class="uk-margin-right" uk-spinner></span> {{ 'account-details.loading-incoming-transactions' | transloco }}</div>
        </div>

        <ng-container *ngFor="let transaction of accountHistory; let transactionIdx = index;">
          <div
            class="date-header text-half-muted"
            *ngIf="(
                ( accountHistory[transactionIdx - 1] == null )
              || (
                  transaction.local_date_string
                !== accountHistory[transactionIdx - 1].local_date_string
              )
            )"
          >{{
              (transaction.local_date_string === dateStringToday)
            ? 'Today'
            : (
                (transaction.local_date_string === dateStringYesterday)
              ? 'Yesterday'
              : transaction.local_date_string
            )
          }}</div>
          <app-nano-transaction-mobile
            [transaction]="transaction"
            [settingIdenticonsStyle]="settings.settings.identiconsStyle"
            (click)="showMobileMenuForTransaction(transaction)"
          ></app-nano-transaction-mobile>
        </ng-container>

        <div class="rounded-area" *ngIf="(loadingIncomingTxList || loadingTxList || loadingAccountDetails) else notLoadingTransactionsMobile">
          <div style="text-align: center;"><span class="uk-margin-right" uk-spinner></span> {{ 'account-details.loading-transactions' | transloco }}</div>
        </div>
        <ng-template #notLoadingTransactionsMobile>
          <div class="rounded-area interactable" *ngIf="!accountHistory.length && !pendingBlocks.length">
            <div style="text-align: center;">{{ 'account-details.no-transactions' | transloco }}</div>
          </div>
          <div class="rounded-area interactable more-results" (click)="loadMore()" *ngIf="accountHistory.length + 1 >= pageSize && pageSize <= maxPageSize">
            {{ 'general.show-more' | transloco }}<br>
            <div class="chevron chevron-down"></div>
          </div>
        </ng-template>
      </div>

      <table class="transactions-list hide-on-small-viewports uk-table uk-table-striped uk-table-small" style="margin-top: 0;">
        <thead>
        <tr>
          <th scope="col" class="type-column"></th>
          <th scope="col" class="account-column">{{ 'general.account' | transloco }} / {{ 'general.block' | transloco }}</th>
          <th scope="col" class="amount-column">{{ 'general.amount' | transloco }}</th>
          <th scope="col" class="date-column">{{ 'general.date' | transloco }}</th>
        </tr>
        </thead>
        <tbody>

        <tr *ngFor="let pending of pendingBlocks" [class]="{ 'uk-text-muted': true, 'uk-hidden': pending.received }">
          <td class="type-column">
            <span class="type uk-text-small" uk-icon="icon: plus-circle; ratio: 1.2;"></span>
          </td>
          <td class="account-column uk-visible-toggle uk-text-truncate">
            <div uk-grid class="uk-flex-nowrap">
              <div class="uk-width-auto uk-text-truncate" style="max-width: calc(100% - 35px);">
                <a [routerLink]="'/account/' + pending.account" class="account-container uk-text-truncate" [title]="'general.view-details' | transloco" uk-tooltip>
                  <div class="identicon-name-row uk-text-truncate">
                    <app-nano-identicon scale="6" [accountID]="pending.account" [settingIdenticonsStyle]="settings.settings.identiconsStyle" class="nano-identicon" *ngIf="(settings.settings.identiconsStyle !== 'none')"></app-nano-identicon>
                    <div class="account-label uk-text-truncate" *ngIf="pending.addressBookName">{{ pending.addressBookName }}</div>
                  </div>
                  <div class="nano-address-clickable nano-address-monospace uk-text-truncate">
                    <app-nano-account-id [accountID]="pending.account" middle="auto" [known]="known"></app-nano-account-id>
                  </div>
                </a>
              </div>
              <div class="nano-address-actions uk-width-auto uk-flex uk-flex-bottom">
                <ul class="uk-iconnav">
                  <li><a ngxClipboard [cbContent]="pending.account" (cbOnSuccess)="copied()" uk-icon="icon: copy" [title]="'general.copy' | transloco" uk-tooltip></a></li>
                </ul>
              </div>
            </div>
            <a [routerLink]="'/transaction/' + pending.hash" class="uk-link-text uk-text-small uk-text-muted uk-text-truncate block-hash block-hash-monospace" [title]="'general.view-details' | transloco" uk-tooltip>{{ pending.hash }}</a>
          </td>
          <ng-template #transactionActionsOrDate>
            <ng-container *ngIf="(walletAccount || remoteVisible) else noActionsAvailable">
              <div class="button-container" *ngIf="walletAccount">
                <button *ngIf="!pending.loading" class="uk-button uk-button-primary uk-text-center uk-width-auto" type="button" (click)="receiveReceivableBlock(pending)">RECEIVE</button>
                <button *ngIf="pending.loading" class="uk-button uk-button-secondary uk-disabled uk-button-small nlt-icon-button nlt-icon-button-inline"><span class="spinner" uk-spinner="ratio: 0.5;"></span> Receiving</button>
              </div>
              <div class="button-container" *ngIf="remoteVisible">
                <button class="uk-button uk-button-primary uk-text-center uk-width-auto remote-sign" type="button" (click)="generateReceive(pending.hash)">SIGN BLOCK</button>
              </div>
            </ng-container>
            <ng-template #noActionsAvailable>
              <span
                class="transaction-date"
                [class.uk-text-muted]="!pending.local_timestamp"
                [class.date-unknown]="!pending.local_timestamp"
              >
                <span class="date">{{ pending.local_date_string }}</span>
                <span class="time">{{ pending.local_time_string }}</span>
              </span>
            </ng-template>
          </ng-template>
          <td class="amount-column uk-text-middle uk-text-muted" [title]="('Incoming Transaction') + ( (pending.amountRaw && (pending.amountRaw > 0) ) ? ( ', +' + ( pending.amountRaw.toString(10) ) + ' raw' ) : '' )">
            <span class="uk-text-small">{{ 'general.blockactions.ready-to-receive' | transloco }}</span><br>
            <span class="amount-integer">{{ pending.amount | rai: 'mnano,true' | amountsplit: 0 }}</span>
            <span class="amount-fractional">{{ pending.amount | rai: 'mnano,true' | amountsplit: 1 }}</span>
            <span class="currency-name">NANO</span>
            <div class="compact-actions-date">
              <ng-template [ngTemplateOutlet]="transactionActionsOrDate"></ng-template>
            </div>
          </td>
          <td class="date-column uk-text-middle uk-text-truncate">
            <ng-template [ngTemplateOutlet]="transactionActionsOrDate"></ng-template>
          </td>
        </tr>

        <!-- Don't show if "Loading transactions..." is already shown -->
        <tr *ngIf="(loadingIncomingTxList && !loadingTxList && !loadingAccountDetails)">
          <td colspan="4" style="text-align: center;"><span class="uk-margin-right" uk-spinner></span> {{ 'account-details.loading-incoming-transactions' | transloco }}</td>
        </tr>

        <tr *ngFor="let history of accountHistory">
          <td class="type-column">
            <span class="type uk-text-small uk-text-success" uk-icon="icon: plus-circle; ratio: 1.2;" *ngIf="(history.type == 'receive' || history.subtype == 'receive' || history.type == 'open')"></span>
            <span class="type uk-text-small uk-text-danger" uk-icon="icon: minus-circle; ratio: 1.2;" *ngIf="(history.type == 'send' || history.subtype == 'send')"></span>
            <span class="type uk-text-small rep-change" uk-icon="icon: cog; ratio: 1.2;" *ngIf="(history.type == 'change' || history.subtype == 'change')"></span>
          </td>
          <td class="account-column uk-text-middle uk-visible-toggle uk-text-truncate">
            <div uk-grid class="uk-flex-nowrap">
              <div class="uk-width-auto uk-text-truncate" style="max-width: calc(100% - 35px);">
                <a [routerLink]="'/account/' + (history.link_as_account || history.account)" class="account-container uk-text-truncate" [title]="'general.view-details' | transloco" uk-tooltip>
                  <div class="identicon-name-row uk-text-truncate">
                    <app-nano-identicon scale="6" [accountID]="history.link_as_account || history.account" [settingIdenticonsStyle]="settings.settings.identiconsStyle" class="nano-identicon" *ngIf="(settings.settings.identiconsStyle !== 'none')"></app-nano-identicon>
                    <div class="account-label uk-text-truncate" *ngIf="history.addressBookName">{{ history.addressBookName }}</div>
                  </div>
                  <div class="nano-address-clickable nano-address-monospace uk-text-truncate">
                    <app-nano-account-id [accountID]="history.link_as_account || history.account" middle="auto" [known]="known"></app-nano-account-id>
                  </div>
                </a>
              </div>
              <div class="nano-address-actions uk-width-auto uk-flex uk-flex-bottom" style="margin-bottom: 11px !important; flex-shrink: 0;">
                <ul class="uk-iconnav">
                  <li><a ngxClipboard [cbContent]="history.link_as_account || history.account" (cbOnSuccess)="copied()" uk-icon="icon: copy" [title]="'general.copy' | transloco" uk-tooltip></a></li>
                </ul>
              </div>
            </div>
            <a [routerLink]="'/transaction/' + history.hash" class="uk-link-text uk-text-small uk-text-muted uk-text-truncate block-hash block-hash-monospace" [title]="'general.view-details' | transloco" uk-tooltip>{{ history.hash }}</a>
          </td>
          <ng-template #transactionActionsOrDate>
            <span
              class="transaction-date"
              [class.uk-text-muted]="!history.local_timestamp"
              [class.date-unknown]="!history.local_timestamp"
            >
              <span class="date">{{ history.local_date_string }}</span>
              <span class="time"> {{ history.local_time_string }}</span>
            </span>
            <div class="label-unconfirmed" *ngIf="(history.confirmed === false)">
              <div class="uk-text-small uk-text-muted">{{ 'account-details.awaiting-confirmation' | transloco }}</div>
            </div>
          </ng-template>
          <td [ngClass]="{
            'amount-column': true,
            'uk-text-middle': true,
            'uk-text-success': (history.type == 'receive' || history.subtype == 'receive' || history.type == 'open' || history.subtype == 'open'),
            'uk-text-danger': (history.type == 'send' || history.subtype == 'send'),
            'rep-change': (history.type == 'change' || history.subtype == 'change')
          }">
            <span *ngIf="isNaN(history.amount)">
              <span
                class="uk-text-small"
                [class.text-half-muted]="(history.confirmed === false)"
                [title]="( (history.confirmed === false) ? ('account-details.this-change-has-not-yet-been-confirmed-by-the-network' | transloco) : '' )"
                uk-tooltip
              >
                {{ 'general.blockactions.rep-change' | transloco }}
                <span class="icon-unconfirmed" uk-icon="icon: clock;" *ngIf="(history.confirmed === false)"></span>
              </span>
            </span>
            <span *ngIf="!isNaN(history.amount)">
              <ng-container *ngIf="(history.type == 'receive' || history.subtype == 'receive' || history.type == 'open')">
                <span class="uk-text-small"
                [class.text-half-muted]="(history.confirmed === false)"
                [title]="( (history.confirmed === false) ? ('account-details.this-transaction-has-not-yet-been-confirmed-by-the-network' | transloco) : '' )"
                uk-tooltip
              >
                  {{ 'general.blockactions.received' | transloco }}
                  <span class="icon-unconfirmed" uk-icon="icon: clock;" *ngIf="(history.confirmed === false)"></span>
                </span><br>
              </ng-container>
              <ng-container *ngIf="(history.type == 'send' || history.subtype == 'send')">
                <span class="uk-text-small"
                  [class.text-half-muted]="(history.confirmed === false)"
                  [title]="( (history.confirmed === false) ? ('account-details.this-transaction-has-not-yet-been-confirmed-by-the-network' | transloco) : '' )"
                  uk-tooltip
                >
                  {{ 'general.blockactions.sent' | transloco }}
                  <span class="icon-unconfirmed" uk-icon="icon: clock;" *ngIf="(history.confirmed === false)"></span>
                </span><br>
              </ng-container>
              <span class="amount-integer">{{ history.amount | rai: 'mnano,true' | amountsplit: 0 }}</span>
              <span class="amount-fractional">{{ history.amount | rai: 'mnano,true' | amountsplit: 1 }}</span>
              <span class="currency-name">NANO</span>
              <div class="compact-actions-date text-half-muted">
                <ng-template [ngTemplateOutlet]="transactionActionsOrDate"></ng-template>
              </div>
            </span>
          </td>
          <td class="date-column uk-text-middle uk-text-truncate">
            <ng-template [ngTemplateOutlet]="transactionActionsOrDate"></ng-template>
          </td>
        </tr>

        <tr *ngIf="(loadingIncomingTxList || loadingTxList || loadingAccountDetails) else notLoadingTransactions">
          <td colspan="4" style="text-align: center;"><span class="uk-margin-right" uk-spinner></span> {{ 'account-details.loading-transactions' | transloco }}</td>
        </tr>
        <ng-template #notLoadingTransactions>
          <tr *ngIf="!accountHistory.length && !pendingBlocks.length">
            <td colspan="4" style="text-align: center;">{{ 'account-details.no-transactions' | transloco }}</td>
          </tr>
          <tr *ngIf="accountHistory.length + 1 >= pageSize && pageSize <= maxPageSize">
            <td colspan="4" class="more-results">
              <a (click)="loadMore()" class="uk-link-text">
                {{ 'general.show-more' | transloco }}<br>
                <div class="chevron chevron-down"></div>
              </a>
            </td>
          </tr>
        </ng-template>
        </tbody>
      </table>
    </div>

  </div>
</div>

<!-- Modal for remote receive incoming -->
<div class="modal-position-bottom" id="receive-modal" uk-modal>
  <div class="uk-modal-dialog uk-modal-body modal-qr">
    <button class="uk-modal-close-default" type="button" uk-close></button>
    <div class="uk-modal-header">
        <h2 class="uk-modal-title">Unsigned Incoming Block</h2>
    </div>
    <div class="uk-modal-body modal-qr-body">
      <a routerLink="/qr-scan" routerLinkActive="active" class="uk-modal-close">Scan</a> the unsigned QR with an offline Nault, or copy the "Unsigned Block" to <a routerLink="/remote-signing" routerLinkActive="active" class="uk-modal-close">Step 2</a><br><br>

      <div *ngIf="blockHashReceive" class="uk-width-1-1 uk-text-center uk-margin-small-bottom" style="display: flex; justify-content: center;">
        <span class="uk-text-small" uk-tooltip title="Unsigned block string to be copied to remote device" style="overflow-wrap: anywhere;"><strong>Unsigned Block</strong></span>
        <ul class="uk-iconnav" style="padding-left: 0;">
          <li><a ngxClipboard [cbContent]="qrString" (cbOnSuccess)="copied()" uk-icon="icon: copy" title="Copy" uk-tooltip></a></li>
        </ul>
        <span class="uk-text-small" uk-tooltip title="Final block hash to be signed or verified" style="overflow-wrap: anywhere; margin-left: 50px;"><strong>Block Hash</strong></span>
        <ul class="uk-iconnav" style="padding-left: 0;">
          <li><a ngxClipboard [cbContent]="blockHashReceive" (cbOnSuccess)="copied()" uk-icon="icon: copy" title="Copy" uk-tooltip></a></li>
        </ul>
      </div>

      <div class="uk-width-1-1 uk-text-center">
        <img *ngIf="qrCodeImageBlockReceive" [src]="qrCodeImageBlockReceive" class="block-qr" alt="QR code"><br />
      </div>
    </div>
  </div>
</div>

<!-- Modal for creating send and change block for remote signing-->
<div class="modal-position-bottom" id="block-modal" uk-modal>
  <div class="uk-modal-dialog uk-modal-body modal-block">
    <button class="uk-modal-close-default" type="button" uk-close></button>
    <div class="uk-modal-header">
        <h2 class="uk-modal-title">Create Block for Remote Signing</h2>
    </div>
    <div class="uk-modal-body modal-block-body">
      <ol>
        <li>Generate a nano block below. No wallet import needed.</li>
        <li><a routerLink="/qr-scan" routerLinkActive="active" class="uk-modal-close">Scan</a> the unsigned QR with an offline Nault, or copy the "Unsigned Block" to <a routerLink="/remote-signing" routerLinkActive="active" class="uk-modal-close">Step 2</a>.</li>
      </ol>

      <div class="uk-margin-small-bottom">
        <strong style="margin-right: 6px;">Block Type</strong>
        <span uk-icon="icon: info;" uk-tooltip title="The block type to create for remote signing. Receiving can be done from the transactions list."></span>
      </div>
      <label class="uk-margin-medium-right"><input class="uk-radio" type="radio" name="blocktype" value={{blockTypes[0]}} [(ngModel)]="blockTypeSelected"> {{blockTypes[0]}}</label>
      <label class="uk-margin-medium-right"><input class="uk-radio" type="radio" name="blocktype" value={{blockTypes[1]}} [(ngModel)]="blockTypeSelected"> {{blockTypes[1]}}</label>
      <br><br>

      <div *ngIf="blockTypeSelected === blockTypes[0]" class="uk-form-horizontal">
        <div class="uk-margin">
          <label class="uk-form-label" for="form-horizontal-text2">To Account</label>
          <div class="uk-form-controls">
            <div class="form-input-destination uk-inline uk-width-1-1">
              <a class="uk-form-icon uk-form-icon-flip" uk-icon="icon: camera" (click)="openQR('account1','account')" uk-tooltip title="Scan from QR code"></a>
              <input (blur)="validateDestination()" (input)="searchAddressBook()" (focus)="searchAddressBook()" [(ngModel)]="toAccountID" [ngClass]="{ 'uk-form-success': toAccountStatus === 2, 'uk-form-danger': toAccountStatus === 0 }" class="uk-input" id="form-horizontal-text2" type="text" placeholder="Account to send to" autocomplete="off">

              <div *ngIf="(addressBookResults$ | async).length" [hidden]="!showAddressBook" class="uk-animation-slide-down-small uk-width-1-1 uk-card uk-card-default uk-card-body uk-position-absolute" style="z-index: 15000">
                <ul class="uk-nav uk-nav-default">
                  <li class="uk-nav-header">Address Book Results</li>
                  <li class="uk-nav-divider"></li>
                  <li *ngFor="let book of addressBookResults$ | async">
                    <a (click)="selectBookEntry(book.account)">{{ book.name }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="uk-form-controls" *ngIf="addressBookMatch">
            <div class="uk-inline uk-width-1-1">
              <span class="account-label blue uk-margin-small-top">{{ addressBookMatch }}</span>
            </div>
          </div>
        </div>

        <div uk-grid>
          <div class="uk-width-1-1">
            <label class="uk-form-label" for="form-horizontal-text">Amount</label>
            <div class="form-amount uk-form-controls">
              <div uk-grid>
                <div class="uk-width-1-1">
                  <div class="uk-inline uk-width-1-1">
                    <a class="uk-form-icon uk-form-icon-flip form-icon-btn" (click)="setMaxAmount()" style="padding-right: 7px;" uk-tooltip title="Set Maximum Amount">Max</a>
                    <input [(ngModel)]="amount" [ngClass]="{ 'uk-form-danger': amountStatus === 0 }" class="uk-input" id="form-horizontal-text" (input)="syncFiatPrice()" type="text" placeholder="Amount of {{ selectedAmount.name }} to send" maxlength="40">
                  </div>
                  <div *ngIf="amountRaw.gt(0)" class="uk-width-1-1 uk-width-3-5@m uk-text-small uk-text-muted uk-margin-remove">
                    <span uk-tooltip title="Additional raw to send">+{{ amountRaw.toString(10) }} raw</span> &nbsp; <a class="uk-text-danger" (click)="resetRaw()" uk-tooltip title="Remove the additional raw amount from the total amount" uk-icon="icon: close"></a>
                  </div>
                  <div class="uk-width-1-1" style="margin-top: 10px;" *ngIf="settings.settings.displayCurrency">
                    <div class="uk-width-1-1 uk-inline">
                      <a class="uk-form-icon uk-link-reset uk-link-muted" style="padding-left: 7px;" uk-tooltip title="Last Price: {{ price.price.lastPrice | fiat: settings.settings.displayCurrency }} / XNO">{{ settings.settings.displayCurrency | currencySymbol }}</a>
                      <input [(ngModel)]="amountFiat" (input)="syncNanoPrice()" style="padding-left: 50px !important;" class="uk-input" id="form-horizontal-text-fiat" type="text" placeholder="Amount of {{ settings.settings.displayCurrency }} to send">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="blockTypeSelected === blockTypes[1]" class="uk-form-horizontal">
        <div class="uk-margin">
          <label class="uk-form-label" for="form-horizontal-text3">Representative</label>
          <div class="uk-form-controls">
            <div class="form-input-destination uk-inline uk-width-1-1">
              <a class="uk-form-icon uk-form-icon-flip" uk-icon="icon: camera" (click)="openQR('rep1','account')" uk-tooltip title="Scan from QR code"></a>
              <input (blur)="validateRepresentative()" (input)="searchRepresentatives()" (focus)="searchRepresentatives()" (keyup.enter)="generateChange()" [(ngModel)]="representativeModel" class="uk-input" [ngClass]="{ 'uk-form-success': repStatus === 2, 'uk-form-danger': repStatus === 0 }" id="form-horizontal-text3" type="text" placeholder="nano_abc...123" #repInput>
              
              <div *ngIf="(representativeResults$ | async).length" [hidden]="!showRepresentatives" class="nlt-dropdown uk-animation-slide-down-small uk-width-1-1 uk-card uk-card-default uk-card-body uk-position-absolute" style="z-index: 15000">
                <ul class="uk-nav uk-nav-default">
                  <li class="uk-nav-header">Representative List Results</li>
                  <li class="uk-nav-divider"></li>
                  <li *ngFor="let rep of representativeResults$ | async">
                    <a (click)="selectRepresentative(rep.id)">
                      {{ rep.name }}
                      <span *ngIf="rep.trusted" class="uk-text-success" style="margin-left: 4px;"><span uk-icon="icon: star;"></span></span>
                      <span *ngIf="rep.warn" class="uk-text-warning" style="margin-left: 4px;"><span uk-icon="icon: warning;"></span> Avoid</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="uk-form-controls" *ngIf="representativeListMatch">
            <div class="uk-inline uk-width-1-1 uk-margin-small-top">
              <span class="account-label rep">{{ representativeListMatch }}</span>
            </div>
          </div>
        </div>
      </div>
      <br>
      <button *ngIf="blockTypeSelected === blockTypes[0]" class="uk-button uk-button-primary uk-text-center uk-width-1-1" type="button" style="margin-bottom: 20px;" (click)="generateSend()">Generate Send Block</button>
      <button *ngIf="blockTypeSelected === blockTypes[1]" class="uk-button uk-button-primary uk-text-center uk-width-1-1" type="button" style="margin-bottom: 20px;" (click)="generateChange()">Generate Change Block</button>

      <div *ngIf="blockHash" class="uk-width-1-1 uk-text-center" style="display: flex; justify-content: center;">
        <span class="uk-text-small" uk-tooltip title="Unsigned block string to be copied to remote device" style="overflow-wrap: anywhere;"><strong>Unsigned Block</strong></span>
        <ul class="uk-iconnav" style="padding-left: 0;">
          <li><a ngxClipboard [cbContent]="qrString" (cbOnSuccess)="copied()" uk-icon="icon: copy" title="Copy" uk-tooltip></a></li>
        </ul>
        <span class="uk-text-small" uk-tooltip title="Final block hash to be signed or verified" style="overflow-wrap: anywhere; margin-left: 50px;"><strong>Block Hash</strong></span>
        <ul class="uk-iconnav" style="padding-left: 0;">
          <li><a ngxClipboard [cbContent]="blockHash" (cbOnSuccess)="copied()" uk-icon="icon: copy" title="Copy" uk-tooltip></a></li>
        </ul>
      </div>
      <div class="uk-width-1-1 uk-text-center uk-margin-small-top">
        <img *ngIf="qrCodeImageBlock" [src]="qrCodeImageBlock" class="block-qr" alt="QR code"><br />
      </div>
    </div>
  </div>
</div>

<div class="mobile-actions-fixed" *ngIf="walletAccount && (showFullDetailsOnSmallViewports === false)">
  <div class="actions-area">
    <button routerLink="/receive">{{ 'general.receive' | transloco }}</button>
    <button routerLink="/send">{{ 'general.send' | transloco }}</button>
  </div>
</div>

<div class="modal-position-bottom" id="qr-code-modal" uk-modal>
  <div class="uk-modal-dialog uk-margin-auto-vertical">
    <button class="uk-modal-close-default" type="button" uk-close></button>
    <div class="uk-modal-body">
      <div class="uk-width-1-1 uk-text-center">
        <img *ngIf="qrCodeImage else qrCodeGenerating" [src]="qrCodeImage" class="qr-code">
        <ng-template #qrCodeGenerating>
          <div class="qr-code-placeholder uk-align-center"><div uk-spinner="ratio: 2;"></div></div>
        </ng-template>
        <hr>
        <div class="account-label uk-margin-small-bottom">{{
            addressBookEntry
          ? addressBookEntry
          : (
              walletAccount
            ? (('general.account' | transloco) + ' #' + walletAccount.index)
            : ( 'account-details.external-address' | transloco )
          )
        }}</div>
        <div class="uk-width-1-1">
          <div uk-grid class="uk-flex uk-flex-center">
            <span class="nano-address-monospace uk-width-auto">
              <app-nano-account-id [accountID]="accountID" middle="off" [known]="known"></app-nano-account-id>
            </span>
            <span class="nano-address-actions uk-width-auto" style="vertical-align: 1px;">
              <ul class="uk-iconnav">
                <li><a ngxClipboard [cbContent]="accountID" (cbOnSuccess)="copied()" uk-icon="icon: copy" [title]="'general.copy' | transloco" uk-tooltip></a></li>
              </ul>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-position-bottom" id="mobile-account-menu-modal" uk-modal>
  <div class="uk-modal-dialog">
    <div class="uk-modal-body">
      <div class="uk-width-1-1">
        <h3 class="menu-header">{{
            addressBookEntry
          ? addressBookEntry
          : (
              walletAccount
            ? (('general.account' | transloco) + ' #' + walletAccount.index)
            : ( 'account-details.external-address' | transloco )
          )
        }}</h3>
        <hr>
        <div
          class="menu-option"
          [routerLink]="( '/account/' + accountID )"
          (click)="mobileAccountMenuModal.hide()"
        >
          <span class="icon" uk-icon="icon: cog; ratio: 1.3;" style="vertical-align: 2px;"></span>
          <span>View account details</span>
        </div>
        <div
          class="menu-option"
          routerLink="/accounts"
          (click)="mobileAccountMenuModal.hide()"
        >
          <span class="icon icon-leave" uk-icon="icon: sign-out; ratio: 1.4;"></span>
          <span>Select a different account</span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-position-bottom" id="mobile-transaction-menu-modal" uk-modal>
  <div class="uk-modal-dialog">
    <div class="uk-modal-body">
      <div class="transactions-list-mobile">
        <app-nano-transaction-mobile
          [isInteractable]="false"
          [transaction]="mobileTransactionData"
          [settingIdenticonsStyle]="settings.settings.identiconsStyle"
          *ngIf="mobileTransactionData"
        ></app-nano-transaction-mobile>
      </div>
      <div class="uk-width-1-1" *ngIf="mobileTransactionData">
        <hr>
        <ng-container *ngIf="mobileTransactionData.isReceivable === true">
          <div
            class="menu-option uk-text-success"
            (click)="onReceiveFundsPress(mobileTransactionData)"
            *ngIf="walletAccount"
          >
            <span
              class="icon icon-download" uk-icon="icon: download; ratio: 1.5;"
              style="vertical-align: 2px;"
              *ngIf="!mobileTransactionData.received"
            ></span>
            <span *ngIf="!mobileTransactionData.received && !mobileTransactionData.loading">Receive funds</span>
            <span *ngIf="!mobileTransactionData.received && mobileTransactionData.loading">Receiving...</span>
            <span
              class="icon" uk-icon="icon: check; ratio: 1.3;"
              style="vertical-align: 2px;"
              *ngIf="mobileTransactionData.received"
            ></span>
            <span *ngIf="mobileTransactionData.received">Received!</span>
          </div>
          <div
            class="menu-option"
            (click)="mobileTransactionMenuModal.hide(); generateReceive(mobileTransactionData.hash)"
            *ngIf="remoteVisible"
          >
            <span class="icon" uk-icon="icon: pencil; ratio: 1.3;" style="vertical-align: 2px;"></span>
            <span>Sign block</span>
          </div>
        </ng-container>
        <ng-container *ngIf="mobileTransactionData.isReceivable !== true">
          <div
            class="menu-option"
            routerLink="/send"
            [queryParams]="{
              to: mobileTransactionData.account,
              amount: util.nano.rawToMnano(mobileTransactionData.amount)
            }"
            (click)="mobileTransactionMenuModal.hide()"
            *ngIf="(
                walletAccount
              && !isNaN(mobileTransactionData.amount)
              && (
                  mobileTransactionData.type === 'receive'
                || mobileTransactionData.subtype === 'receive'
                || mobileTransactionData.type === 'open'
              )
            )"
          >
            <span class="icon" uk-icon="icon: reply; ratio: 1.3;" style="vertical-align: 2px;"></span>
            <span>{{ 'account-details.send-funds-back' | transloco }}</span>
          </div>
          <div
            class="menu-option"
            ngxClipboard
            [cbContent]="mobileTransactionData.hash"
            (cbOnSuccess)="mobileTransactionMenuModal.hide(); copied()"
          >
            <span class="icon icon-copy" uk-icon="icon: copy; ratio: 1.5;" style="vertical-align: 2px;">
              <span class="icon-inner">#</span>
            </span>
            <span>{{ 'account-details.copy-transaction-id' | transloco }}</span>
          </div>
          <div
            class="menu-option"
            ngxClipboard
            [cbContent]="(mobileTransactionData.link_as_account || mobileTransactionData.account)"
            (cbOnSuccess)="mobileTransactionMenuModal.hide(); copied()"
          >
            <span class="icon icon-copy" uk-icon="icon: copy; ratio: 1.5;" style="vertical-align: 2px;">
              <span class="icon-inner icon-user" uk-icon="icon: user; ratio: 0.6;"></span>
            </span>
            <ng-container *ngIf="isNaN(mobileTransactionData.amount)">
              <span>{{ 'account-details.copy-representatives-address' | transloco }}</span>
            </ng-container>
            <ng-container *ngIf="!isNaN(mobileTransactionData.amount)">
              <ng-container *ngIf="(
                  mobileTransactionData.type === 'receive'
                || mobileTransactionData.subtype === 'receive'
                || mobileTransactionData.type === 'open'
              )">
                <span>{{ 'account-details.copy-senders-address' | transloco }}</span>
              </ng-container>
              <ng-container *ngIf="(
                  mobileTransactionData.type === 'send'
                || mobileTransactionData.subtype === 'send'
              )">
                <span>{{ 'account-details.copy-recipients-address' | transloco }}</span>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
        <div
          class="menu-option"
          [routerLink]="( '/transaction/' + mobileTransactionData.hash )"
          (click)="mobileTransactionMenuModal.hide()"
        >
          <span class="icon" uk-icon="icon: search; ratio: 1.3;" style="vertical-align: 2px;"></span>
          <span>{{ 'account-details.view-transaction-details' | transloco }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
