<div class="uk-animation-slide-left-small" uk-grid *transloco="let t">
  <div class="uk-width-1-1" id="address-book-sortable">
    
    <div class="uk-margin-bottom" uk-grid>
      <div class="uk-width-expand@s uk-width-1-1">
        <h2 class="uk-heading-divider uk-text-truncate">
          {{ 'general.address-book' | transloco }}
          <a *ngIf="activePanel !== 1" (click)="activePanel =  activePanel === 0 ? 2 : 0; addressBookShowFileExport = false;" style="font-size: 12px; margin-left: 25px;">{{ activePanel === 2 ? ('address-book.back-to-address-book' | transloco | uppercase) : ('address-book.import-export' | transloco | uppercase) }}</a>
        </h2>
      </div>
      <div class="uk-width-auto@s uk-width-1-1 uk-text-right">
        <button class="uk-button uk-button-secondary uk-align-right uk-width-auto@s" (click)="addEntry()" *ngIf="activePanel === 0">{{ 'address-book.add-new-entry' | transloco }}</button>
      </div>
    </div>

    <div class="uk-width-1-1 nlt-page-intro" *ngIf="activePanel === 0">
      <p>
        {{ 'address-book.you-can-use-the-address-book-to-store.1' | transloco }}<br>
        {{ 'address-book.you-can-use-the-address-book-to-store.2' | transloco }}
      </p>
      <div class="account-amounts-primary uk-width-1-1">
        <div class="details-header advanced-options-link" (click)="showAdvancedOptions = !showAdvancedOptions">
          {{ 'address-book.show-total-tracked-balance' | transloco }}
          <div [class]="['chevron', ( showAdvancedOptions ? 'chevron-up' : 'chevron-down' )]"></div>
        </div>

        <div class="advanced-options" *ngIf="showAdvancedOptions">
          <span [title]="( totalTrackedBalanceRaw.gt(0) ? ( '+' + ( totalTrackedBalanceRaw.toString(10) ) + ' raw' ) : '' )">
            <span class="amount-integer">{{ totalTrackedBalance | rai: 'mnano,true' | amountsplit: 0 }}</span>
            <span class="amount-fractional">{{ totalTrackedBalance | rai: 'mnano,true' | amountsplit: 1 }}</span>
            <span class="currency-name">NANO</span>
          </span>
          <div *ngIf="totalTrackedPending.gt(0)" class="incoming-label">
            <div class="text-snippet">{{ 'general.new' | transloco }}</div>
            <div class="text-full">+{{ totalTrackedPending | rai: 'mnano,true' | amountsplit: 0 }}{{ totalTrackedPending | rai: 'mnano,true' | amountsplit: 1 }} NANO</div>
          </div>
          <div class="account-amounts-converted uk-width-1-1 text-half-muted">
            {{ totalTrackedBalanceFiat | fiat: appSettings.settings.displayCurrency }}
          </div>
        </div>
      </div>
    </div>

    <div class="uk-animation-slide-left-small" *ngIf="activePanel === 0" uk-grid>
      <div class="uk-width-1-1">
        <div class="uk-card uk-margin">

          <ul class="uk-list uk-list-striped" style="margin-bottom: 0;">
            <li class="uk-list-header">
              <div uk-grid>
                <div class="uk-width-2-5">{{ 'general.name' | transloco }}</div>
                <div class="uk-width-expand">{{ 'general.address' | transloco }}
                  <div *ngIf="numberOfTrackedBalance > 0" style="display: flex;">
                    {{ 'address-book.reload-balances' | transloco | uppercase }}
                    <ul class="uk-width-auto uk-iconnav uk-margin-remove-left" style="margin-top: -1px;">
                      <li>
                        <div
                          uk-spinner="ratio: 0.5;"
                          class="icon-transactions-refresh spinner"
                          *ngIf="(loadingBalances || walletService.wallet.updatingBalance === true) else notUpdatingTxList"
                        ></div>
                        <ng-template #notUpdatingTxList>
                          <a
                            class="icon-transactions-refresh"
                            [class.disabled]="!statsRefreshEnabled"
                            [class.uk-text-muted]="!statsRefreshEnabled"
                            uk-icon="icon: refresh;"
                            title="Reload tracked balances (for addresses not in your Nault wallet)"
                            uk-tooltip
                            (click)="updateTrackedBalances(true)"
                          ></a>
                        </ng-template>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="uk-width-1-6 uk-text-center" style="padding-left: 0;">{{ 'general.actions' | transloco }}</div>
              </div>
            </li>
          </ul>

          <ul class="uk-list uk-list-striped" style="margin-top: 0;" uk-sortable="handle: .uk-sortable-handle; cls-custom: uk-box-shadow-small uk-background uk-background-default;">
            <li class="" *ngFor="let addressBook of addressBook$ | async;" [attr.data-account]="addressBook.account">
              <div uk-grid>
                <div class="uk-width-2-5 uk-text-truncate uk-visible-toggle">
                  <div uk-grid>
                    <div class="uk-width-expand uk-text-truncate uk-margin-small-right">
                      {{ addressBook.name }}
                    </div>
                    <ul class="uk-iconnav uk-width-auto">
                      <li><a (click)="editEntry(addressBook)" title="Edit Entry" uk-tooltip uk-icon="icon: pencil;" class="edit-name-icon"></a></li>
                      <li><a class="uk-sortable-handle uk-margin-small-right" uk-icon="icon: table"></a></li>
                    </ul>
                  </div>
                </div>
                <div class="uk-width-expand uk-text-truncate uk-visible-toggle">
                  <div uk-grid>
                    <div class="uk-width-expand uk-text-truncate">
                      <a [routerLink]="'/account/' + addressBook.account" class="nano-address-clickable nano-address-monospace uk-display-block" [title]="t('general.view-on-network-explorer')" uk-tooltip>
                        <app-nano-account-id [accountID]="addressBook.account"></app-nano-account-id>
                      </a>
                    </div>
                    <ul class="nano-address-actions uk-iconnav">
                      <li><a ngxClipboard [cbContent]="addressBook.account" (cbOnSuccess)="copied()" uk-icon="icon: copy" [title]="t('general.copy-to-clipboard')" uk-tooltip></a></li>
                    </ul>
                    <div class="uk-width-expand amount-container" *ngIf="addressBook.trackBalance">
                      <ng-container *ngIf="(walletService.wallet.updatingBalance === false) else balanceLoading">
                        <div class="account-amounts-primary uk-width-1-1">
                          <span [title]="( accounts[addressBook.account]?.balanceRaw.gt(0) ? ( '+' + ( accounts[addressBook.account]?.balanceRaw.toString(10) ) + ' raw' ) : '' )">
                            <span class="amount-integer">{{ accounts[addressBook.account]?.balance | rai: 'mnano,true' | amountsplit: 0 }}</span>
                            <span class="amount-fractional">{{ accounts[addressBook.account]?.balance | rai: 'mnano,true' | amountsplit: 1 }}</span>
                            <span class="currency-name">NANO</span>
                          </span>
                          <div *ngIf="accounts[addressBook.account]?.pending.gt(0)" class="incoming-label">
                            <div class="text-snippet">New</div>
                            <div class="text-full">+{{ accounts[addressBook.account]?.pending | rai: 'mnano,true' | amountsplit: 0 }}{{ accounts[addressBook.account]?.pending | rai: 'mnano,true' | amountsplit: 1 }} NANO</div>
                          </div>
                        </div>
                        <div class="account-amounts-converted uk-width-1-1 text-half-muted">
                          {{ accounts[addressBook.account]?.balanceFiat | fiat: appSettings.settings.displayCurrency }}
                        </div>
                      </ng-container>
                      <ng-template #balanceLoading>
                        <div class="account-amounts-primary uk-width-1-1">
                          <div uk-spinner="ratio: 0.5;"></div>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </div>
                <div class="uk-width-1-6 uk-text-center" style="padding-left: 0;">
                  <a (click)="deleteAddress(addressBook.account)" class="uk-text-danger" title="Remove From Address Book" uk-tooltip><span uk-icon="icon: trash;"></span></a>
                </div>
              </div>

            </li>
            <li *ngIf="!(addressBook$ | async).length" class="uk-text-center">
              {{ 'address-book.you-do-not-have-any-address-book-entries-saved-yet.1' | transloco }} <a (click)="addEntry()">{{ 'address-book.you-do-not-have-any-address-book-entries-saved-yet.2-link' | transloco }}</a>{{ 'address-book.you-do-not-have-any-address-book-entries-saved-yet.3' | transloco }}
            </li>
          </ul>

        </div>
      </div>
    </div>

    <div class="uk-animation-slide-left-small" *ngIf="activePanel === 1" uk-grid>
      <div class="uk-width-1-1">
        <div class="uk-card uk-card-default">
          <div class="uk-card-header">
            <h2 class="uk-card-title">{{ creatingNewEntry ? ( 'address-book.add-new-entry' | transloco ) : ( 'address-book.edit-entry' | transloco ) }}</h2>
          </div>
          <div class="uk-card-body">
            <div class="uk-form-horizontal">
              <div class="uk-margin">
                <label class="uk-form-label" for="new-address-name">{{ 'general.name' | transloco }}</label>
                <div class="uk-form-controls">
                  <input type="text" class="uk-input" id="new-address-name" [(ngModel)]="newAddressName" (keyup.enter)="saveNewAddress()" [placeholder]="t('address-book.exchange-deposit-address-main-trading-account-etc')" maxlength="64">
                </div>
              </div>

              <div class="uk-margin">
                <label class="uk-form-label" for="new-address-account">{{ 'general.address' | transloco }}</label>
                <div class="uk-form-controls">
                  <div class="uk-inline uk-width-1-1">
                    <a class="uk-form-icon uk-form-icon-flip" uk-icon="icon: camera" (click)="openQR('account1','account')" uk-tooltip [title]="t('general.scan-qr-code')" *ngIf="creatingNewEntry"></a>
                    <input type="text" class="uk-input" id="new-address-account" [(ngModel)]="newAddressAccount" [disabled]="!creatingNewEntry" placeholder="nano_abc123">
                  </div>
                </div>
              </div>

              <div class="uk-margin">
                <label class="uk-form-label" for="new-track-balance">{{ 'address-book.track-balance' | transloco }} <span uk-icon="icon: info;" uk-tooltip [title]="t('address-book.the-balance-will-be-displayed-in-the-address-book')"></span></label>
                <div class="uk-form-controls">
                  <input class="uk-checkbox checkbox-aligned" type="checkbox" id="new-track-balance" value="1" [(ngModel)]="newTrackBalance">
                </div>
              </div>

              <div class="uk-margin">
                <label class="uk-form-label" for="new-track-transactions">{{ 'address-book.track-transactions' | transloco }} <span uk-icon="icon: info;" uk-tooltip [title]="t('address-book.a-notification-will-be-shown-whenever-the-address-has-a-new')"></span></label>
                <div class="uk-form-controls">
                  <input class="uk-checkbox checkbox-aligned" type="checkbox" id="new-track-transactions" value="1" [(ngModel)]="newTrackTransactions">
                </div>
              </div>
            </div>
          </div>
          <div class="uk-card-footer uk-text-right@s nlt-button-group">
            <button class="uk-button uk-button-danger uk-width-1-1@s uk-width-auto@m" (click)="cancelNewAddress()">{{ 'general.cancel' | transloco }}</button>
            <button class="uk-button uk-button-primary uk-width-1-1@s uk-width-auto@m" (click)="saveNewAddress()">{{ 'general.save' | transloco }}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="uk-animation-slide-left-small" *ngIf="activePanel === 2" uk-grid>
      <div class="uk-width-1-1">
        <div class="uk-card uk-card-default">
          <div class="uk-card-header">
            <h3 class="uk-card-title">{{ 'address-book.address-book-import' | transloco }}</h3>
          </div>
          <div class="uk-card-body">
            <p>{{ 'address-book.import-address-book-entries-from-a-file' | transloco }}</p>
          </div>
          <div class="uk-card-footer uk-text-right@s uk-text-center nlt-button-group">
            <div class="js-upload uk-text-left uk-width-auto@s uk-width-1-1" style="display: inline-block;" uk-form-custom>
              <input type="file" id="import-from-file" (change)="importFromFile($event.target.files)" multiple>
              <button class="uk-button uk-button-primary uk-width-auto@s uk-width-1-1" type="button" tabindex="-1">{{ 'address-book.import-address-book' | transloco }}</button>
            </div>
          </div>
        </div>
      </div>
      <div class="uk-width-1-1">
        <div class="uk-card uk-card-default">
          <div class="uk-card-header">
            <h3 class="uk-card-title">{{ 'address-book.address-book-export' | transloco }}</h3>
          </div>
          <div class="uk-card-body">
            <p>{{ 'address-book.export-all-address-book-entries-to-a-file-or-qr-code-url' | transloco }}</p>
            <p>{{ 'address-book.note-exported-address-book-will-not-be-encrypted-by-your-wal' | transloco }}</p>
            <div *ngIf="addressBookShowFileExport" uk-grid>
              <div class="uk-width-1-1">
                <hr class="uk-divider-icon">
              </div>

              <div class="uk-width-1-1@s uk-width-1-2@m uk-width-1-3@l">
                <img *ngIf="addressBookShowQRExport" [src]="addressBookQRExportImg" alt="QR code" style="border-radius: 6px;">
                <span *ngIf="!addressBookShowQRExport" class="uk-text-warning">{{ 'address-book.address-book-too-large-for-a-qr-code' | transloco }}<br>{{ 'address-book.if-you-need-a-qr-use-fewer-entries-or-shorter-aliases' | transloco }}</span>
              </div>
              <div class="uk-width-1-1@s uk-width-1-2@m uk-width-2-3@l">
                <span *ngIf="addressBookShowQRExport">{{ 'address-book.scan-the-qr-code-on-any-device-to-import-your-address-book' | transloco }}<br><br></span>
                <span *ngIf="addressBookShowQRExport">{{ 'address-book.you-can-also-import-your-address-book-by-using-the-url-below' | transloco }}</span>
                <span *ngIf="!addressBookShowQRExport">{{ 'address-book.you-can-still-import-your-address-book-by-using-the-url' | transloco }}</span>
                <br><br>
                <input type="text" class="uk-input" style="max-width: 750px;" value="{{ addressBookQRExportUrl }}"><br>
                <div class="nlt-button-group">
                  <button ngxClipboard [cbContent]="addressBookQRExportUrl" (cbOnSuccess)="notificationService.sendSuccess('Address book export copied to clipboard!')" class="uk-button uk-button-secondary uk-width-1-1@s uk-width-auto@m uk-margin-small-top" style="margin-left: 0;">{{ 'general.copy-to-clipboard' | transloco }}</button>
                  <button (click)="exportAddressBookToFile()" class="uk-button uk-button-primary uk-width-1-1@s uk-width-auto@m uk-margin-small-top">{{ 'address-book.export-as-file' | transloco }}</button>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!addressBookShowFileExport" class="uk-card-footer uk-text-right@s uk-text-center nlt-button-group">
            <button (click)="exportAddressBook()" class="uk-button uk-button-primary uk-width-auto@s uk-width-1-1">{{ 'address-book.export-address-book' | transloco }}</button>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>
