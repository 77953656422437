<div class="uk-animation-slide-left-small" uk-grid>
  <div class="uk-width-1-1">
    <h2 class="uk-heading-divider">{{ (sendDestinationType === 'own-address') ? 'Transfer' : 'Send' }}</h2>

    <!-- Send Panel -->
    <div uk-grid *ngIf="activePanel == 'send'" class="uk-animation-slide-left-small">
      <div class="send-types-wrapper uk-width-1-1 uk-text-center">
        <div class="send-types-frame" style="display:flex;margin:auto;width:100%;flex-wrap:wrap;">
          <button [class]="['send-types-tab', 'uk-text-truncate', (sendDestinationType === 'external-address') ? 'send-types-tab-active' : 'send-types-tab-inactive', 'uk-button', (sendDestinationType === 'external-address') ? 'uk-button-primary' : 'uk-button-secondary']" type="button" (click)="setSendDestinationType('external-address')">Send to address</button>
          <button [class]="['send-types-tab', 'uk-text-truncate', (sendDestinationType === 'own-address') ? 'send-types-tab-active' : 'send-types-tab-inactive', 'uk-button', (sendDestinationType === 'own-address') ? 'uk-button-primary' : 'uk-button-secondary']" type="button" (click)="setSendDestinationType('own-address')">Transfer between own accounts</button>
        </div>
      </div>

      <div class="uk-width-1-1">

        <div class="uk-card uk-card-default">
          <div class="uk-card-body">
            <div class="uk-form-horizontal">


              <div class="uk-margin">
                <label class="uk-form-label" for="form-horizontal-select">From Account</label>
                <div class="uk-form-controls">
                  <select class="form-select-source uk-select" [(ngModel)]="fromAccountID" (change)="resetRaw()" id="form-horizontal-select">
                    <option *ngFor="let account of accounts" [value]="account.id">{{account.addressBookName ? account.addressBookName + ' - ' : '#' + account.index + ' - ' }} {{ account.id | squeeze }} ({{ account.balance | rai: 'mnano,true' | amountsplit: 0 }}{{ account.balance | rai: 'mnano,true' | amountsplit: 1 }} NANO)</option>
                  </select>
                </div>
              </div>

              <div class="uk-margin" *ngIf="( sendDestinationType === 'external-address' )">
                <label class="uk-form-label" for="form-horizontal-text2">To Address</label>
                <div class="uk-form-controls">
                  <div class="form-input-destination uk-inline uk-width-1-1">
                    <a class="hide-on-small-viewports uk-form-icon uk-form-icon-flip" uk-icon="icon: camera" (click)="openQR('account1','account')" uk-tooltip title="Scan from QR code"></a>
                    
                    <input (blur)="validateDestination()" (input)="searchAddressBook()" (focus)="searchAddressBook()" [(ngModel)]="toAccountID" [ngClass]="{ 'uk-form-success': toAccountStatus === 2, 'uk-form-danger': toAccountStatus === 0 }" class="uk-input" id="form-horizontal-text2" type="text" placeholder="Address to send to" autocomplete="off">

                    <div *ngIf="(addressBookResults$ | async).length" [hidden]="!showAddressBook" class="nlt-dropdown uk-animation-slide-down-small uk-width-1-1 uk-card uk-card-default uk-card-body uk-position-absolute" style="z-index: 15000">
                      <ul class="uk-nav uk-nav-default">
                        <li class="uk-nav-header">Nano Known</li>
                        <li class="uk-nav-divider"></li>
                        <li *ngFor="let book of addressBookResults$ | async">
                          <a (click)="selectBookEntry(book.account)">{{ book.name }}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="uk-form-controls" *ngIf="addressBookMatch">
                  <div class="uk-inline uk-width-1-1">
                    <span class="account-label blue uk-margin-small-top">{{ addressBookMatch }}</span>
                  </div>
                </div>
              </div>

              <div class="uk-margin" *ngIf="( sendDestinationType === 'own-address' )">
                <label class="uk-form-label" for="form-horizontal-text2">To Account</label>
                <div class="uk-form-controls">
                  <select required class="form-select-destination uk-select" [(ngModel)]="toOwnAccountID" id="form-horizontal-select">
                    <option value="" disabled selected hidden>Account to transfer to</option>
                    <ng-container *ngFor="let account of accounts">
                      <option [value]="account.id" *ngIf="account.id !== fromAccountID">{{account.addressBookName ? account.addressBookName + ' - ' : '#' + account.index + ' - ' }} {{ account.id | squeeze }} ({{ account.balance | rai: 'mnano,true' | amountsplit: 0 }}{{ account.balance | rai: 'mnano,true' | amountsplit: 1 }} NANO)</option>
                    </ng-container>
                  </select>
                </div>
              </div>

              <div uk-grid>
                <div class="uk-width-1-1">
                  <label class="uk-form-label" for="form-horizontal-text">Amount</label>
                  <div class="form-amount uk-form-controls">
                    <div uk-grid>
                      <div class="uk-width-1-1">
                        <div class="uk-inline uk-width-1-1">
                          <a class="max-amt-button uk-form-icon uk-form-icon-flip form-icon-btn" (click)="setMaxAmount()" uk-tooltip title="Set Maximum Amount">Max</a>
                          <label class="uk-form-icon uk-link-reset uk-link-muted xno-symbol" for="form-horizontal-amount"></label>
                          <input [(ngModel)]="amount" [ngClass]="{ 'uk-form-danger': amountStatus === 0 }" style="padding-left: 52px !important;" class="uk-input" id="form-horizontal-text" (input)="syncFiatPrice()" type="number" step="any" placeholder="Amount of {{ selectedAmount.name }}" maxlength="40">
                        </div>

                      </div>

                      <div *ngIf="amountExtraRaw.gt(0)" class="amount-raw-extra uk-width-1-1 uk-text-small text-half-muted">
                        <span uk-tooltip title="Additional raw to {{ (sendDestinationType === 'own-address') ? 'transfer' : 'send' }}">+{{ amountExtraRaw.toString(10) }} raw</span> &nbsp; <a class="uk-text-danger" (click)="resetRaw()" uk-tooltip title="Remove the additional raw amount from the total amount" uk-icon="icon: close"></a>
                      </div>

                      <div class="uk-width-1-1" style="margin-top: 10px;" *ngIf="settings.settings.displayCurrency">
                        <div class="uk-width-1-1 uk-inline">
                          <a class="uk-form-icon uk-link-reset uk-link-muted fiat-currency-ticker" uk-tooltip title="Last Price: {{ price.price.lastPrice | fiat: settings.settings.displayCurrency }} / NANO">{{ settings.settings.displayCurrency | currencySymbol }}</a>
                          <input [(ngModel)]="amountFiat" (input)="syncNanoPrice()" style="padding-left: 52px !important;" class="uk-input" id="form-horizontal-text-fiat" type="number" step="any" placeholder="Amount of {{ settings.settings.displayCurrency }}">
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="uk-card-footer">
            <div class="uk-text-right@s nlt-button-group">
              <button class="uk-button uk-button-primary uk-width-1-1@s uk-width-auto@m uk-float-right nlt-icon-button" type="button" (click)="sendTransaction()" *ngIf="!preparingTransaction">
                {{ (sendDestinationType === 'own-address') ? 'Transfer' : 'Send' }}
              </button>
              <button class="uk-button uk-button-secondary uk-disabled uk-width-1-1@s uk-width-auto@m uk-float-right nlt-icon-button" type="button" *ngIf="preparingTransaction">
                <span class="spinner" uk-spinner="ratio: 0.5;"></span>
                Preparing transaction
              </button>
              <button
                class="only-on-small-viewports uk-button uk-button-secondary uk-width-1-1@s uk-width-auto@m uk-float-right nlt-icon-button"
                [class.uk-disabled]="preparingTransaction"
                type="button"
                (click)="preparingTransaction ? return : openQR('account1','account')"
                *ngIf="(sendDestinationType !== 'own-address')"
              >
                Scan QR code
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Send Panel -->

    <!-- Confirmation Panel -->
    <div uk-grid *ngIf="activePanel == 'confirm'" class="uk-animation-slide-left">
      <div class="uk-width-1-1">
        <div class="uk-card uk-card-default uk-width-1-1 uk-text-center" style="margin-bottom: 20px;">
          <div class="uk-card-body" style="padding: 15px;">
            <div class="uk-text-danger">You are about to send</div>
            <div class="block-amount-primary uk-text-danger">
              <span class="amount-integer">{{ rawAmount | rai: 'mnano,true' | amountsplit: 0 }}</span>
              <span class="amount-fractional">{{ rawAmount | rai: 'mnano,true' | amountsplit: 1 }}</span>
              <span class="currency-name">NANO</span>
            </div>
            <div *ngIf="amountExtraRaw.gt(0)" class="uk-text-small text-half-muted" style="margin: 2px 0 -2px 0;">+{{ amountExtraRaw.toString(10) }} raw</div>
            <div style="margin: 8px 0 -1px 0;" class="text-half-muted" *ngIf="settings.settings.displayCurrency">{{ amountFiat | fiat: settings.settings.displayCurrency }}</div>
            <div style="margin: 1px 0 -1px 0;" class="uk-text-muted" *ngIf="settings.settings.displayCurrency">{{ price.price.lastPrice | fiat: settings.settings.displayCurrency }} / NANO</div>
          </div>
        </div>

        <div class="account-snippet-container" *ngIf="fromAccount && toAccount" style="margin-bottom: 20px;">
          <div class="account-snippet uk-text-truncate">
            <div class="uk-card uk-card-default uk-text-truncate" [class.identicons-enabled]="(settings.settings.identiconsStyle !== 'none')">
              <div class="uk-card-header">
                <div class="snippet-caption uk-text-small text-half-muted">From Account</div>
                <div class="uk-flex uk-flex-nowrap uk-width-auto uk-text-truncate" style="max-width: calc(100% - 35px);">
                  <a [routerLink]="'/account/' + fromAccountID" class="uk-link-reset account-container uk-text-truncate" uk-tooltip title="View Account Details">
                    <div class="identicon-address-book-row">
                      <app-nano-identicon scale="12" [accountID]="fromAccountID" [settingIdenticonsStyle]="settings.settings.identiconsStyle" class="nano-identicon" *ngIf="(settings.settings.identiconsStyle !== 'none')"></app-nano-identicon>
                      <div class="account-label uk-text-truncate" *ngIf="fromAddressBook">{{ fromAddressBook }}</div>
                    </div>
                    <div class="nano-address-clickable nano-address-monospace uk-text-truncate"><app-nano-account-id [accountID]="fromAccountID"></app-nano-account-id></div>
                  </a>
                  <div class="snippet-address-actions uk-width-auto uk-flex uk-flex-bottom">
                    <ul class="uk-iconnav">
                      <li><a ngxClipboard [cbContent]="fromAccountID" (cbOnSuccess)="copied()" uk-icon="icon: copy" title="Copy Account Address" uk-tooltip></a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="uk-card-body">
                <div uk-grid>
                  <div class="uk-width-1-2 uk-text-muted">
                    <div class="uk-text-small text-half-muted" style="height: 25px;">Current Balance</div>
                    <span class="block-balance">
                      <span class="balance-amount-primary">
                        <span class="amount-integer">{{ (fromAccount.balance || 0) | rai: 'mnano,true' | amountsplit: 0 }}</span>
                        <span class="amount-fractional">{{ (fromAccount.balance || 0) | rai: 'mnano,true' | amountsplit: 1 }}</span>
                        <span class="currency-name">NANO</span>
                      </span>
                    </span>
                  </div>
                  <div class="uk-width-1-2 uk-text-right">
                    <div class="uk-text-small text-half-muted" style="height: 25px;"></div>
                    <span class="block-balance">
                      <span class="balance-amount-primary uk-text-danger">
                        <span class="amount-sign">-</span>
                        <span class="amount-integer">{{ rawAmount | rai: 'mnano,true' | amountsplit: 0 }}</span>
                        <span class="amount-fractional">{{ rawAmount | rai: 'mnano,true' | amountsplit: 1 }}</span>
                        <span class="currency-name">NANO</span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="account-snippet uk-text-truncate">
            <div class="uk-card uk-card-default uk-text-truncate" [class.identicons-enabled]="(settings.settings.identiconsStyle !== 'none')">
              <div class="uk-card-header">
                <div class="snippet-caption uk-text-small text-half-muted">To Account</div>
                <div class="uk-flex uk-flex-nowrap uk-width-auto uk-text-truncate" style="max-width: calc(100% - 35px);">
                  <a [routerLink]="'/account/' + getDestinationID()" class="uk-link-reset account-container uk-text-truncate" uk-tooltip title="View Account Details">
                    <div class="identicon-address-book-row">
                      <app-nano-identicon scale="12" [accountID]="getDestinationID()" [settingIdenticonsStyle]="settings.settings.identiconsStyle" class="nano-identicon" *ngIf="(settings.settings.identiconsStyle !== 'none')"></app-nano-identicon>
                      <div class="account-label uk-text-truncate" *ngIf="toAddressBook">{{ toAddressBook }}</div>
                    </div>
                    <div class="nano-address-clickable nano-address-monospace uk-text-truncate"><app-nano-account-id [accountID]="getDestinationID()"></app-nano-account-id></div>
                  </a>
                  <div class="snippet-address-actions uk-width-auto uk-flex uk-flex-bottom">
                    <ul class="uk-iconnav">
                      <li><a ngxClipboard [cbContent]="getDestinationID()" (cbOnSuccess)="copied()" uk-icon="icon: copy" title="Copy Account Address" uk-tooltip></a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="uk-card-body">
                <div uk-grid>
                  <div class="uk-width-1-2 uk-text-muted">
                    <div class="uk-text-small text-half-muted" style="height: 25px;">Current Balance</div>
                    <span class="block-balance">
                      <span class="balance-amount-primary">
                        <span class="amount-integer">{{ (toAccount.balance || 0) | rai: 'mnano,true' | amountsplit: 0 }}</span>
                        <span class="amount-fractional">{{ (toAccount.balance || 0) | rai: 'mnano,true' | amountsplit: 1 }}</span>
                        <span class="currency-name">NANO</span>
                      </span>
                    </span>
                  </div>
                  <div class="uk-width-1-2 uk-text-right">
                    <div class="uk-text-small text-half-muted" style="height: 25px;"></div>
                    <span class="block-balance">
                      <span class="balance-amount-primary uk-text-success">
                        <span class="amount-sign">+</span>
                        <span class="amount-integer">{{ rawAmount | rai: 'mnano,true' | amountsplit: 0 }}</span>
                        <span class="amount-fractional">{{ rawAmount | rai: 'mnano,true' | amountsplit: 1 }}</span>
                        <span class="currency-name">NANO</span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="uk-text-right@s nlt-button-group">
          <button (click)="activePanel = 'send'" class="uk-button uk-button-danger uk-width-1-1@s uk-width-auto@m">Cancel</button>
          <button *ngIf="!confirmingTransaction" class="uk-button uk-button-primary uk-width-1-1@s uk-width-auto@m" (click)="confirmTransaction()">Confirm & Send</button>
          <button *ngIf="confirmingTransaction" class="uk-button uk-button-secondary uk-disabled nlt-icon-button nlt-icon-button-inline uk-width-1-1@s uk-width-auto@m"><span class="spinner" uk-spinner="ratio: 0.5;"></span> Sending</button>
        </div>
      </div>
    </div>
    <!-- End Confirmation Panel -->

  </div>
</div>
