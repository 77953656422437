<div class="uk-animation-slide-left-small" uk-grid>
  <div class="uk-width-1-1">

    <ng-template #switchToMerchantModeButton>
      <button
        class="uk-width-1-1 uk-width-auto@s uk-button uk-button-primary uk-text-center nlt-icon-button nlt-button-blue"
        type="button"
        (click)="merchantModeEnable()"
      >
        <span class="nlt-icon" uk-icon="icon: cart;"></span>
        <span class="merchant-mode-text-full">Merchant Mode</span>
        <span class="merchant-mode-text-short">Merchant Mode</span>
      </button>
    </ng-template>
    
    <div class="uk-margin-bottom uk-flex uk-flex-between">
      <h2 class="uk-flex-1 uk-heading-divider uk-margin-remove">Receive</h2>
      <div class="uk-flex-none uk-visible@s uk-flex uk-flex-top uk-margin-medium-left">
        <ng-container *ngTemplateOutlet="switchToMerchantModeButton"></ng-container>
      </div>
    </div>

    <div class="uk-card uk-card-default uk-margin">
      <div class="uk-card-body">
        <div class="uk-form-horizontal receive-columns">

          <div class="uk-flex-1">
            <div class="uk-margin">
              <label for="form-horizontal-select" class="uk-form-label label-block">Account</label>
              <div class="form-account">
                <select id="form-horizontal-select" class="uk-select" [(ngModel)]="pendingAccountModel" (change)="onSelectedAccountChange(pendingAccountModel)">
                  <option [value]="0">All Accounts</option>
                  <option *ngFor="let account of accounts" [value]="account.id">{{account.addressBookName ? account.addressBookName + ' - ' : '#' + account.index + ' - ' }} {{ account.id | squeeze }} ({{ account.balance | rai: 'mnano,true' | amountsplit: 0 }}{{ account.balance | rai: 'mnano,true' | amountsplit: 1 }} XNO)</option>
                </select>
              </div>
            </div>
  
            <div class="uk-margin">
              <label for="form-horizontal-amount" class="uk-form-label label-block">Requested Amount <span class="uk-text-muted label-optional">optional</span></label>
              <div class="form-amount">
                <div class="uk-width-1-1 uk-inline">
                  <label class="uk-form-icon uk-link-reset uk-link-muted xno-symbol" for="form-horizontal-amount"></label>
                  <input [(ngModel)]="amountNano" [ngClass]="{ 'uk-form-danger': !validNano }" [attr.disabled]="pendingAccountModel == '0' || null" autocomplete="off" class="uk-input" id="form-horizontal-amount" (input)="nanoAmountChange()" style="padding-left: 52px !important;" type="number" step="any" placeholder="Amount of XNO" maxlength="40">
                </div>
                <div style="margin-top: 10px;" *ngIf="settings.settings.displayCurrency">
                  <div class="uk-width-1-1 uk-inline">
                    <a class="uk-form-icon uk-link-reset uk-link-muted fiat-currency-ticker" uk-tooltip title="Last Price: {{ price.price.lastPrice | fiat: settings.settings.displayCurrency }} / XNO">{{ settings.settings.displayCurrency | currencySymbol }}</a>
                    <input [(ngModel)]="amountFiat" [ngClass]="{ 'uk-form-danger': !validFiat }" [attr.disabled]="pendingAccountModel == '0' || null" autocomplete="off" (input)="fiatAmountChange()" style="padding-left: 52px !important;" class="uk-input" id="form-horizontal-text-fiat" type="number" step="any" placeholder="Amount of {{ settings.settings.displayCurrency }}">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="uk-flex-1 qr-column">
            <div *ngIf="qrCodeImage; else placeholder">
              <div class="qr-code">
                <img class="uk-position-absolute" [src]="qrCodeImage" alt="QR code">
                <div class="qr-confirmation" [class]="qrSuccessClass">
                  <div uk-icon="icon: check; ratio: 5"></div>
                  <span class="uk-text-large uk-text-center">Nano Received</span>
                </div>
                <div style="padding-top: 100%"></div>
              </div>
            </div>
            <ng-template #placeholder>
              <div class="qr-code qr-placeholder">
                <div class="uk-position-absolute text-half-muted" *ngIf="(pendingAccountModel === '0')">Select an account<br/>to view QR code</div>
                <div class="uk-position-absolute text-half-muted" *ngIf="(pendingAccountModel !== '0')"><div uk-spinner="ratio: 3;"></div></div>
                <div style="padding-top: 100%"></div>
              </div>
            </ng-template>
            <div>
              <div class="uk-flex uk-flex-center uk-flex-middle uk-text-center qr-address" *ngIf="(pendingAccountModel !== '0')">
                <app-nano-account-id [accountID]="pendingAccountModel" middle="break" class="nano-address-monospace uk-width-auto" style="max-width: 90%;"></app-nano-account-id>
                <a class="span-icon hide-on-small-viewports" ngxClipboard [cbContent]="pendingAccountModel" (cbOnSuccess)="copied()" uk-icon="icon: copy" title="Copy Account Address" uk-tooltip></a>
              </div>
              <div class="uk-margin-medium-top only-on-small-viewports nlt-button-group uk-flex uk-flex-column uk-flex-middle">
                <button
                  *ngIf="(pendingAccountModel !== '0')"
                  class="uk-width-1-1 uk-width-4-5@s uk-button uk-button-primary uk-text-center nlt-icon-button"
                  [class.nlt-button-success]="recentlyCopiedAccountAddress"
                  [class.uk-disabled]="recentlyCopiedAccountAddress"
                  type="button"
                  ngxClipboard
                  [cbContent]="pendingAccountModel"
                  (cbOnSuccess)="copiedAccountAddress()"
                >
                  <span class="nlt-icon" uk-icon="icon: copy;"></span>
                  {{ recentlyCopiedAccountAddress ? 'Copied!' : 'Copy address' }}
                </button>
                <div class="uk-hidden@s uk-width-1-1 uk-flex">
                  <ng-container *ngTemplateOutlet="switchToMerchantModeButton"></ng-container>
                </div>
              </div>
            </div>
          </div>
                  
        </div>
      </div>
    </div>

    <div class="transactions-list-header uk-width-1-1 uk-flex uk-flex-center uk-flex-middle uk-margin-small-bottom">
      <h3 class="uk-margin-remove-bottom">Incoming Transactions</h3>
      <ul class="uk-width-auto uk-iconnav uk-margin-remove-left" style="margin-top: -1px;">
        <li>
          <div
            uk-spinner="ratio: 0.5;"
            class="icon-transactions-refresh spinner"
            *ngIf="loadingIncomingTxList else notUpdatingTxList"
          ></div>
          <ng-template #notUpdatingTxList>
            <a uk-icon="icon: refresh;" class="icon-transactions-refresh" (click)="getPending()" uk-tooltip title="Reload incoming transactions from the network"></a>
          </ng-template>
        </li>
      </ul>
    </div>

    <div uk-grid>
      <div class="uk-width-1-1">
        <div class="transactions-list-mobile only-on-small-viewports">
          <div
            class="date-header text-half-muted"
            *ngIf="(pendingAccountModel !== '0')"
          >{{ selectedAccountAddressBookName }}</div>
          <ng-container *ngFor="let transaction of pendingBlocksForSelectedAccount; let transactionIdx = index;">
            <div
              class="date-header text-half-muted"
              *ngIf="(
                    (pendingAccountModel === '0')
                && (
                    ( pendingBlocksForSelectedAccount[transactionIdx - 1] == null )
                  || (
                      transaction.destinationAddressBookName
                    !== pendingBlocksForSelectedAccount[transactionIdx - 1].destinationAddressBookName
                  )
                )
              )"
            >{{ transaction.destinationAddressBookName }}</div>
            <app-nano-transaction-mobile
              [transaction]="transaction"
              [settingIdenticonsStyle]="settings.settings.identiconsStyle"
              (click)="showMobileMenuForTransaction(transaction)"
            ></app-nano-transaction-mobile>
          </ng-container>

          <div class="rounded-area" *ngIf="!loadingIncomingTxList && !pendingBlocksForSelectedAccount.length">
            <div style="text-align: center;">{{
                (pendingAccountModel === '0')
              ? 'No new incoming transactions'
              : 'No new incoming transactions for selected account'
            }}</div>
          </div>
          <div class="rounded-area" *ngIf="loadingIncomingTxList">
            <div style="text-align: center;"><span class="uk-margin-right" uk-spinner></span> Loading incoming transactions...</div>
          </div>
        </div>

        <table class="transactions-list hide-on-small-viewports uk-table uk-table-striped uk-table-small" style="margin-top: 0;">
          <thead>
          <tr>
            <th scope="col" class="type-column"></th>
            <th scope="col" class="account-column">Account / Block</th>
            <th scope="col" class="amount-column">Amount</th>
            <th scope="col" class="date-column">Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let pending of pendingBlocksForSelectedAccount" [class]="{ 'uk-text-muted': true }">
            <td class="type-column">
              <span class="type uk-text-small" uk-icon="icon: plus-circle; ratio: 1.2;"></span>
            </td>
            <td class="account-column uk-visible-toggle uk-text-truncate">
              <div uk-grid class="uk-flex-nowrap">
                <div class="uk-width-auto uk-text-truncate" style="max-width: calc(100% - 35px);">
                  <a [routerLink]="'/account/' + pending.account" class="account-container uk-text-truncate" title="View Account Details" uk-tooltip>
                    <div class="identicon-name-row uk-text-truncate">
                      <app-nano-identicon scale="6" [accountID]="pending.account" [settingIdenticonsStyle]="settings.settings.identiconsStyle" class="nano-identicon" *ngIf="(settings.settings.identiconsStyle !== 'none')"></app-nano-identicon>
                      <div class="account-label uk-text-truncate" *ngIf="pending.addressBookName">{{ pending.addressBookName }}</div>
                    </div>
                    <div class="nano-address-clickable nano-address-monospace uk-text-truncate">
                      <app-nano-account-id [accountID]="pending.account" middle="auto"></app-nano-account-id>
                    </div>
                  </a>
                </div>
                <div class="nano-address-actions uk-width-auto uk-flex uk-flex-bottom">
                  <ul class="uk-iconnav">
                    <li><a ngxClipboard [cbContent]="pending.account" (cbOnSuccess)="copied()" uk-icon="icon: copy" title="Copy Account Address" uk-tooltip></a></li>
                  </ul>
                </div>
              </div>
              <a [routerLink]="'/transaction/' + pending.hash" class="uk-link-text uk-text-small uk-text-muted uk-text-truncate block-hash block-hash-monospace" title="View Block Details" uk-tooltip>{{ pending.hash }}</a>
              <ng-container *ngIf="(pendingAccountModel === '0')">
                <div class="arrow-down">
                  <div class="icon" uk-icon="icon: chevron-down; ratio: 1.2;"></div>
                </div>
                <div uk-grid class="uk-flex-nowrap">
                  <div class="uk-width-auto uk-text-truncate" style="max-width: calc(100% - 35px);">
                    <a [routerLink]="'/account/' + pending.destination" class="account-container uk-text-truncate" title="View Account Details" uk-tooltip>
                      <div class="identicon-name-row uk-text-truncate">
                        <app-nano-identicon scale="6" [accountID]="pending.destination" [settingIdenticonsStyle]="settings.settings.identiconsStyle" class="nano-identicon" *ngIf="(settings.settings.identiconsStyle !== 'none')"></app-nano-identicon>
                        <div class="account-label uk-text-truncate" *ngIf="pending.destinationAddressBookName">{{ pending.destinationAddressBookName }}</div>
                      </div>
                      <div class="nano-address-clickable nano-address-monospace uk-text-truncate">
                        <app-nano-account-id [accountID]="pending.destination" middle="auto"></app-nano-account-id>
                      </div>
                    </a>
                  </div>
                  <div class="nano-address-actions uk-width-auto uk-flex uk-flex-bottom">
                    <ul class="uk-iconnav">
                      <li><a ngxClipboard [cbContent]="pending.destination" (cbOnSuccess)="copied()" uk-icon="icon: copy" title="Copy Account Address" uk-tooltip></a></li>
                    </ul>
                  </div>
                </div>
              </ng-container>
            </td>
            <ng-template #transactionActions>
              <div class="button-container">
                <button *ngIf="!pending.loading" class="uk-button uk-button-primary uk-text-center uk-width-auto" type="button" (click)="receiveReceivableBlock(pending)">RECEIVE</button>
                <button *ngIf="pending.loading" class="uk-button uk-button-secondary uk-disabled uk-button-small nlt-icon-button nlt-icon-button-inline"><span class="spinner" uk-spinner="ratio: 0.5;"></span> Receiving</button>
              </div>
            </ng-template>
            <td class="amount-column uk-text-middle uk-text-muted" [title]="('Incoming Transaction') + ( (pending.amountRaw && (pending.amountRaw > 0) ) ? ( ', +' + ( pending.amountRaw.toString(10) ) + ' raw' ) : '' )">
              <span class="uk-text-small">Ready to receive</span><br>
              <span class="amount-integer">{{ pending.amount | rai: 'mnano,true' | amountsplit: 0 }}</span>
              <span class="amount-fractional">{{ pending.amount | rai: 'mnano,true' | amountsplit: 1 }}</span>
              <span class="currency-name">NANO</span>
              <div class="compact-actions-date">
                <ng-template [ngTemplateOutlet]="transactionActions"></ng-template>
              </div>
            </td>
            <td class="date-column uk-text-middle uk-text-truncate">
              <ng-template [ngTemplateOutlet]="transactionActions"></ng-template>
            </td>
          </tr>
          <tr *ngIf="!loadingIncomingTxList && !pendingBlocksForSelectedAccount.length">
            <td colspan="4" style="text-align: center;">{{
                (pendingAccountModel === '0')
              ? 'No new incoming transactions'
              : 'No new incoming transactions for selected account'
            }}</td>
          </tr>
          <tr *ngIf="loadingIncomingTxList">
            <td colspan="4" style="text-align: center;"><span class="uk-margin-right" uk-spinner></span> Loading incoming transactions...</td>
          </tr>
        </table>

        <table class="uk-table uk-table-striped uk-table-small" *ngIf="settings.settings.minimumReceive">
          <thead>
            <tr class="uk-alert uk-alert-primary missing-accounts-hint">
              <td colspan="4" style="text-align: center;"><span uk-icon="icon: info"></span> Below <a class="hint-action" routerLink="/configure-app" routerLinkActive="active" *ngIf="( util.nano.mnanoToRaw(this.settings.settings.minimumReceive).toFixed().length > 24 ) else amountInRaw">{{ util.nano.mnanoToRaw(this.settings.settings.minimumReceive).toFixed() | rai: 'mnano,true' | amountsplit: 0 }}{{ util.nano.mnanoToRaw(this.settings.settings.minimumReceive).toFixed() | rai: 'mnano,true' | amountsplit: 1 }} XNO</a><ng-template #amountInRaw><a class="hint-action" routerLink="/configure-app" routerLinkActive="active">{{ util.nano.mnanoToRaw(this.settings.settings.minimumReceive).toFixed() }} raw</a></ng-template> will be hidden.</td>
            </tr>
          </thead>
        </table>

      </div>
    </div>

  </div>
</div>

<div class="modal-position-bottom" id="mobile-transaction-menu-modal" uk-modal>
  <div class="uk-modal-dialog">
    <div class="uk-modal-body">
      <div class="transactions-list-mobile">
        <app-nano-transaction-mobile
          [isInteractable]="false"
          [transaction]="mobileTransactionData"
          [settingIdenticonsStyle]="settings.settings.identiconsStyle"
          *ngIf="mobileTransactionData"
        ></app-nano-transaction-mobile>
      </div>
      <div class="uk-width-1-1" *ngIf="mobileTransactionData">
        <hr>
        <div
          class="menu-option uk-text-success"
          (click)="onReceiveFundsPress(mobileTransactionData)"
        >
          <span
            class="icon icon-download" uk-icon="icon: download; ratio: 1.5;"
            style="vertical-align: 2px;"
            *ngIf="!mobileTransactionData.received"
          ></span>
          <span *ngIf="!mobileTransactionData.received && !mobileTransactionData.loading">Receive funds</span>
          <span *ngIf="!mobileTransactionData.received && mobileTransactionData.loading">Receiving...</span>
          <span
            class="icon" uk-icon="icon: check; ratio: 1.3;"
            style="vertical-align: 2px;"
            *ngIf="mobileTransactionData.received"
          ></span>
          <span *ngIf="mobileTransactionData.received">Received!</span>
        </div>
        <div
          class="menu-option"
          [routerLink]="( '/transaction/' + mobileTransactionData.hash )"
          (click)="mobileTransactionMenuModal.hide()"
        >
          <span class="icon" uk-icon="icon: search; ratio: 1.3;" style="vertical-align: 2px;"></span>
          <span>View transaction details</span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="merchant-mode-overlay" id="merchant-mode-modal" uk-modal>
  <div class="merchant-mode-contents uk-flex uk-flex-column uk-flex-middle">
    <div
      class="uk-width-1-1 merchant-mode-header"
      [class.uk-margin-medium-bottom]="inMerchantModeQR === false"
      [class.uk-margin-bottom]="inMerchantModeQR === true"
    >
      <div class="merchant-mode-logo"></div>
      <div
        class="merchant-mode-exit uk-flex uk-flex-middle"
        [class.uk-visible@s]="(inMerchantModeQR === true)"
        (click)="inMerchantModeQR ? merchantModeHideQR() : merchantModeDisable()"
      >
        <span uk-icon="icon: close; ratio: 1.4;" class="uk-margin-small-right" style="margin-top: 2px;"></span>
        <span class="label">{{ inMerchantModeQR ? 'Cancel Payment' : 'Exit Merchant Mode' }}</span>
      </div>
    </div>
    <div class="merchant-centered-container uk-flex-1 uk-width-1-1 uk-flex uk-flex-column uk-flex-center uk-flex-middle uk-text-center">
      <ng-container *ngIf="(inMerchantModePaymentComplete === false) && (inMerchantModeQR === false) && (pendingAccountModel === '0')">
        <div class="uk-width-1-1 uk-card uk-card-default uk-margin-bottom">
          <div class="uk-card-body">
            <p class="uk-text-large">Select the <span class="uk-text-primary">destination account</span></p>
            <select id="form-horizontal-select" class="uk-select" [(ngModel)]="pendingAccountModel" (change)="onSelectedAccountChange(pendingAccountModel)">
              <option [value]="0">–</option>
              <option *ngFor="let account of accounts" [value]="account.id">{{account.addressBookName ? account.addressBookName + ' - ' : '#' + account.index + ' - ' }} {{ account.id | squeeze }}</option>
            </select>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="(inMerchantModePaymentComplete === false) && (inMerchantModeQR === false) && (pendingAccountModel !== '0')">
        <div class="identicon-name-row uk-text-truncate">
          <app-nano-identicon scale="6" [accountID]="pendingAccountModel" [settingIdenticonsStyle]="settings.settings.identiconsStyle" class="nano-identicon" *ngIf="(settings.settings.identiconsStyle !== 'none')"></app-nano-identicon>
          <div class="account-label uk-text-truncate">{{ selectedAccountAddressBookName }}</div>
        </div>
        <app-nano-account-id [accountID]="pendingAccountModel" middle="off" class="nano-address-monospace uk-width-auto"></app-nano-account-id>
        <button
          class="uk-width-auto uk-button uk-button-secondary uk-text-center nlt-icon-button uk-margin-small-top"
          type="button"
          (click)="unsetSelectedAccount()"
        >
          <span class="nlt-icon" uk-icon="icon: pencil;"></span>
          Change account
        </button>

        <div
          class="uk-width-1-1 uk-card uk-card-default uk-margin-bottom"
          style="margin-top: 60px;"
        >
          <div class="uk-card-body">
            <p class="uk-text-large">Enter the <span class="uk-text-primary">requested amount</span></p>
            <div class="form-amount">
              <div class="uk-width-1-1 uk-inline">
                <label class="uk-form-icon uk-link-reset uk-link-muted xno-symbol" for="form-horizontal-amount"></label>
                <input [(ngModel)]="amountNano" [ngClass]="{ 'uk-form-danger': !validNano }" [attr.disabled]="pendingAccountModel == '0' || null" autocomplete="off" class="uk-input" id="form-horizontal-amount" (input)="nanoAmountChange()" style="padding-left: 52px !important;" type="number" step="any" placeholder="Amount of XNO" maxlength="40">
              </div>
              <div style="margin-top: 10px;" *ngIf="settings.settings.displayCurrency">
                <p class="text-half-muted" style="margin: 0 0 14px 0;">or</p>
                <div class="uk-width-1-1 uk-inline">
                  <a class="uk-form-icon uk-link-reset uk-link-muted fiat-currency-ticker" uk-tooltip title="Last Price: {{ price.price.lastPrice | fiat: settings.settings.displayCurrency }} / XNO">{{ settings.settings.displayCurrency | currencySymbol }}</a>
                  <input [(ngModel)]="amountFiat" [ngClass]="{ 'uk-form-danger': !validFiat }" [attr.disabled]="pendingAccountModel == '0' || null" autocomplete="off" (input)="fiatAmountChange()" style="padding-left: 52px !important;" class="uk-input" id="form-horizontal-text-fiat" type="number" step="any" placeholder="Amount of {{ settings.settings.displayCurrency }}">
                </div>
              </div>
            </div>
            <button
              class="uk-width-1-1 uk-button uk-button-primary uk-text-center nlt-icon-button uk-margin-medium-top"
              type="button"
              (click)="merchantModeShowQR()"
            >
              <span class="merchant-mode-icon-qr-code"></span>
              Create QR code
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="(inMerchantModeQR === true)">
        <p class="uk-text-large uk-margin-remove-bottom">Send</p>
        <p class="uk-text-large uk-text-primary uk-margin-remove-top" *ngIf="(amountNano === '')">
          <span class="merchant-mode-currency-name">XNO</span>
        </p>
        <p class="uk-text-large uk-text-primary uk-margin-remove-top" *ngIf="(amountNano !== '')">
          <span
            class="merchant-mode-amount-integer"
          >{{ merchantModeRawRequestedQR | rai: 'mnano,true' | amountsplit: 0 }}</span>
          <span
            class="merchant-mode-amount-fractional"
          >{{ merchantModeRawRequestedQR | rai: 'mnano,true' | amountsplit: 1 }}</span>
          <span class="merchant-mode-currency-name merchant-mode-currency-margin">XNO</span>
        </p>
        <p class="text-half-muted" style="margin: -16px 0 10px 0;">to</p>
        <app-nano-account-id
          [accountID]="pendingAccountModel" middle="break"
          class="nano-address-monospace uk-width-auto"
          style="display: inline-block; max-width: 325px;"
        ></app-nano-account-id>
        <div
          class="uk-width-1-1 uk-flex uk-flex-center uk-margin-top"
          style="margin-bottom: 40px;"
        >
          <img class="merchant-mode-qr-code" [src]="qrCodeImage" alt="QR code" *ngIf="qrCodeImage">
          <div class="merchant-mode-qr-code" *ngIf="!qrCodeImage"><div uk-spinner="ratio: 2;"></div></div>
        </div>
        <div
          class="uk-width-1-1 uk-card uk-card-default uk-margin-bottom"
          *ngFor="let prompt of merchantModePrompts; let promptIdx = index"
        >
          <div class="uk-card-body">
            <p
              *ngIf="(amountNano === '')"
              class="uk-text-large text-half-muted uk-margin-remove-bottom"
            >
              Received
            </p>
            <p
              *ngIf="(amountNano !== '') && prompt.moreThanRequested"
              class="uk-text-large text-half-muted uk-margin-remove-bottom"
            >
              Received <span class="uk-text-success">more</span> than requested
            </p>
            <p
              *ngIf="(amountNano !== '') && prompt.lessThanRequested"
              class="uk-text-large text-half-muted uk-margin-remove-bottom"
            >
              Received <span class="uk-text-warning">less</span> than requested
            </p>
            <p
              class="uk-text-large uk-margin-remove-top"
              [class.uk-text-success]="prompt.moreThanRequested"
              [class.uk-text-warning]="prompt.lessThanRequested"
            >
              <span
                class="merchant-mode-amount-integer"
              >{{ prompt.amountRaw | rai: 'mnano,true' | amountsplit: 0 }}</span>
              <span
                class="merchant-mode-amount-fractional"
              >{{ prompt.amountRaw | rai: 'mnano,true' | amountsplit: 1 }}</span>
              <span class="merchant-mode-currency-name merchant-mode-currency-margin">XNO</span>
            </p>
            <div
              *ngIf="prompt.amountHiddenRaw.gt(0)"
              class="uk-text-small text-half-muted block-hash-monospace"
              style="margin: -22px 0 28px 0;"
            >
              +{{ prompt.amountHiddenRaw.toString(10) }} raw
            </div>
            <button
              *ngIf="prompt.moreThanRequested"
              class="uk-width-1-1 uk-button uk-button-primary uk-text-center nlt-icon-button uk-margin-bottom nlt-button-green"
              type="button"
              (click)="merchantModeMarkCompleteFromPrompt(prompt)"
            >
              <span class="nlt-icon" uk-icon="icon: check;" style="margin-top: -2px;"></span>
              Mark Payment Complete
            </button>
            <button
              *ngIf="prompt.lessThanRequested"
              class="uk-width-1-1 uk-button uk-button-primary uk-text-center nlt-icon-button uk-margin-bottom"
              type="button"
              (click)="merchantModeSubtractAmountFromPrompt(prompt, promptIdx)"
            >
              <span class="nlt-icon" uk-icon="icon: pencil;" style="margin-top: -2px;"></span>
              Subtract From Amount
            </button>
            <button
              class="uk-width-1-1 uk-button uk-button-secondary uk-text-danger uk-text-center nlt-icon-button"
              type="button"
              (click)="merchantModeDiscardPrompt(promptIdx)"
            >
              <span class="nlt-icon" uk-icon="icon: close;" style="margin-top: -2px;"></span>
              Discard As Unrelated
            </button>
          </div>
        </div>
        <ng-container *ngIf="merchantModePrompts.length === 0">
          <p class="uk-text-large uk-margin-top text-half-muted">Awaiting payment...</p>
          <button
            class="uk-width-auto uk-button uk-text-center nlt-icon-button"
            [class.uk-button-primary]="!loadingIncomingTxList"
            [class.uk-button-secondary]="loadingIncomingTxList"
            [class.uk-disabled]="loadingIncomingTxList"
            type="button"
            (click)="!loadingIncomingTxList && getPending()"
          >
            <span class="nlt-icon" uk-icon="icon: refresh;" style="margin-top: -2px;" *ngIf="!loadingIncomingTxList"></span>
            <span class="spinner" uk-spinner="ratio: 0.5;" *ngIf="loadingIncomingTxList"></span>
            {{ loadingIncomingTxList ? 'Checking Payment...' : 'Check Payment' }}
          </button>
        </ng-container>
        <div
          class="merchant-mode-exit uk-flex uk-flex-middle uk-hidden@s"
          style="margin-top: 70px;"
          (click)="merchantModeHideQR()"
        >
          <span uk-icon="icon: close; ratio: 1.4;" class="uk-margin-small-right" style="margin-top: 2px;"></span>
          <span class="label">Cancel Payment</span>
        </div>
      </ng-container>
      <ng-container *ngIf="(inMerchantModePaymentComplete === true)">
        <p class="uk-text-large uk-margin-remove-bottom uk-text-success">Received</p>
        <p class="uk-text-large uk-text-success uk-margin-remove-top">
          <span
            class="merchant-mode-amount-integer"
          >{{ merchantModeRawReceivedTotal | rai: 'mnano,true' | amountsplit: 0 }}</span>
          <span
            class="merchant-mode-amount-fractional"
          >{{ merchantModeRawReceivedTotal | rai: 'mnano,true' | amountsplit: 1 }}</span>
          <span class="merchant-mode-currency-name merchant-mode-currency-margin">XNO</span>
        </p>
        <div
          *ngIf="merchantModeRawReceivedTotalHiddenRaw.gt(0)"
          class="uk-text-small text-half-muted block-hash-monospace"
          style="margin: -22px 0 28px 0;"
        >
          +{{ merchantModeRawReceivedTotalHiddenRaw.toString(10) }} raw
        </div>
        <img style="width: 50%; margin-top: -40px;" src="data:image/svg+xml,%3Csvg width='200mm' height='200mm' viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m157.05 40.584c-2.5562-2e-6 -5.1127 0.97964-7.0714 2.9383l-65.687 65.687-34.266-34.266c-3.9174-3.9174-10.225-3.9174-14.142 1e-6l-12.915 12.915c-3.9174 3.9174-3.9174 10.225-1e-6 14.142l39.526 39.526c0.4262 0.72955 0.95143 1.4181 1.5782 2.0448l12.915 12.915c3.9174 3.9174 10.225 3.9174 14.142 0l85.906-85.907c3.9174-3.9174 3.9174-10.224 0-14.142l-12.915-12.915c-1.9587-1.9587-4.5147-2.9383-7.0709-2.9383z' fill='none' stop-color='%23000000' stroke='%2332d296' stroke-linejoin='round' stroke-width='2' style='paint-order:stroke fill markers'/%3E%3C/svg%3E" />
        <p class="uk-flex uk-flex-center uk-flex-middle text-half-muted uk-margin-remove-bottom">
          <button
            class="uk-button uk-button-secondary uk-text-center nlt-icon-button"
            type="button"
            ngxClipboard
            [cbContent]="(
                  'Amount Requested: '
                + ( merchantModeRawRequestedTotal | rai: 'mnano,true' | amountsplit: 0 )
                + ( merchantModeRawRequestedTotal | rai: 'mnano,true' | amountsplit: 1 )
                + ' XNO'
                + '\n\n'
                + 'Amount Paid: '
                + ( merchantModeRawReceivedTotal | rai: 'mnano,true' | amountsplit: 0 )
                + ( merchantModeRawReceivedTotal | rai: 'mnano,true' | amountsplit: 1 )
                + ' XNO'
                + '\n\n'
                + 'Transaction ID\'s:'
                + '\n- '
                + merchantModeTransactionHashes.join('\n- ')
            )"
          >
            <span class="nlt-icon" uk-icon="icon: copy;"></span>
            Copy Payment Information
          </button>
        </p>
        <ul class="uk-width-1-1">
          <li
            *ngFor="let hash of merchantModeTransactionHashes"
            class="uk-text-left text-half-muted block-hash-monospace"
            style="word-wrap: anywhere;"
          >
            {{ hash }}
          </li>
        </ul>
        <div
          class="merchant-mode-exit uk-flex uk-flex-middle"
          style="margin-top: 70px;"
          (click)="merchantModeResetState()"
        >
          <span uk-icon="icon: plus-circle; ratio: 1.4;" class="uk-margin-small-right" style="margin-top: 2px;"></span>
          <span class="label">New Payment</span>
        </div>
      </ng-container>
    </div>
  </div>
</div>
