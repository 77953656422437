<div class="uk-animation-slide-left-small" uk-grid>
  <div class="uk-width-1-1">

    <h2 class="uk-heading-divider">Scan QR Code</h2>

    <div uk-grid class="uk-margin-top">

      <div class="uk-width-1-1@m" [hidden]="!hasDevices">

        <div class="uk-width-1-1">
          <div class="uk-form-horizontal">

            <div class="uk-margin">
              <label class="uk-form-label">Device</label>
              <div class="uk-form-controls">
                <select class="uk-select" (change)="onDeviceSelectChange($event.target.value)">
                  <option value="" [selected]="!currentDevice">No Device Selected</option>
                  <option *ngFor="let device of availableDevices" [value]="device.deviceId"
                    [selected]="currentDevice && device.deviceId === currentDevice.deviceId">{{device.label}}</option>
                </select>
              </div>
            </div>

          </div>
        </div>

        <div class="uk-card uk-card-default uk-card-small">
          <div class="uk-card-body uk-center">
            <zxing-scanner [torch]="torchEnabled" [(device)]="currentDevice" (scanSuccess)="onCodeResult($event)"
              [formats]="formatsEnabled" [tryHarder]="tryHarder" (permissionResponse)="onHasPermission($event)"
              (camerasFound)="onCamerasFound($event)" (torchCompatible)="onTorchCompatible($event)"></zxing-scanner>
          </div>
        </div>

      </div>

    </div>

  </div>
</div>

<div *ngIf="hasPermission === undefined && hasDevices === undefined">
  <div class="uk-alert uk-alert-info nlt-inline-alert">
    <p>
      <b>Waiting for permissions.</b><br>
      If your device does not has cameras, no permissions will be asked.
    </p>
  </div>
</div>

<div *ngIf="hasPermission === false">
  <div class="uk-alert uk-alert-danger nlt-inline-alert">
    <p>
      <b>You denied the camera permission.</b><br>
      We can't scan anything without it. 😪
    </p>
  </div>
</div>

<div *ngIf="hasDevices === null || hasPermission === null">
  <div class="uk-alert uk-alert-danger nlt-inline-alert">
    <p>
      <b>Couldn't check for devices.</b><br>
      This may be caused by some security error.
    </p>
  </div>
</div>

<div *ngIf="hasDevices === false">
  <div class="uk-alert uk-alert-danger nlt-inline-alert">
    <p>
      <b>No devices were found.</b><br>
      I believe your device has no media devices attached to.
    </p>
  </div>
</div>