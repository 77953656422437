<h2 class="uk-heading-divider">New Wallet</h2>

<!-- 
<div *ngIf="isConfigured() && !walletService.isLedgerWallet() && (activePanel === panels.landing || activePanel === panels.import || activePanel === panels.mnemonicTypeSelection)" class="uk-margin-small-bottom">
  <div class="uk-alert uk-alert-danger nlt-inline-alert">
    <div class="icon-column">
      <div uk-icon="icon: warning; ratio: 2;"></div>
    </div>
    <p>
      <b>You already have a local Nault wallet configured</b><br>
      Before going any further, be 100% certain you have backed up the secret recovery phrase for your current wallet!<br>
      Without it, <b>any funds you have will become completely unrecoverable!</b>
    </p>
  </div>
</div>
 -->
 
<div class="uk-card uk-card-default wallet-setup-card" *ngIf="activePanel === panels.landing">
  <div class="uk-card-body">
    <div class="landing-container uk-grid-divider" uk-grid>
      <div class="uk-width-auto@l uk-width-1-1@m">
        <button class="wallet-option" (click)="setPasswordInit()">
          <div class="icon">
            <svg width="120" height="120" version="1.1" viewBox="0 0 160 160" xmlns="http://www.w3.org/2000/svg">
              <path d="m132.03 98.355v26.667c0 1.662-1.338 3-3 3h-112.4c-1.662 0-3-1.338-3-3v-78.19c0-1.662 1.338-3 3-3h112.4c1.662 0 3 1.338 3 3v26.132" fill="none" stroke="#000" stroke-linejoin="round" stroke-width="2"/>
              <g>
                <rect x="104.67" y="73.192" width="34.824" height="25.469" rx=".88236" ry=".90757" fill="none" stroke="#000" stroke-linejoin="round" stroke-width="2"/>
              </g>
              <g>
                <path d="m30.302 43.95 74.72-30.197c1.3768-0.55642 2.9319 0.12482 3.4851 1.5235l5.4462 13.77" fill="none" stroke="#000" stroke-linejoin="round" stroke-width="2"/>
              </g>
              <g>
                <path d="m31.086 43.794 87.915-15.48c1.4625-0.25752 2.8426 0.72898 3.0942 2.2119l2.0896 12.312" fill="none" stroke="#000" stroke-linejoin="round" stroke-width="2"/>
              </g>
              <g fill="none" stroke="#000" stroke-linejoin="round" stroke-width="2">
                <rect transform="rotate(90)" x="136.31" y="-149.14" width=".53454" height="18.174" rx="3" ry="3" stop-color="#000000" style="font-variation-settings:normal"/>
                <rect x="139.78" y="127.49" width=".53454" height="18.174" rx="3" ry="3" stop-color="#000000" style="font-variation-settings:normal"/>
              </g>
            </svg>
          </div>
          <h2>New<br>Wallet</h2>
          <p>Generate seed<br>and mnemonic</p>
        </button>
      </div>
      <div class="uk-width-expand">
        <button class="wallet-option" (click)="setPanel(panels.import); selectedImportOption = 'file';">
          <div class="icon">
            <svg width="120" height="120" version="1.1" viewBox="0 0 160 160" xmlns="http://www.w3.org/2000/svg">
              <path d="m111.24 21.503c6.0699 1.9309 10.664 6.5178 13.516 14.24m-88.044-14.24h74.527l0.32099 14.165 13.195 0.07558v99.871c0 1.5009-1.2083 2.7092-2.7092 2.7092h-85.334c-1.5009 0-2.7092-1.2083-2.7092-2.7092v-111.4c0-1.5009 1.2083-2.7092 2.7092-2.7092z" fill="none" stroke="#000" stroke-width="2"/>
            </svg>
          </div>
          <h2>Nault<br>Import</h2>
          <p>Nault.Pro <span style="opacity: 0.7">or</span> Nault.cc</p>
        </button>
    <!--     <button class="wallet-option more-options" (click)="showMoreImportOptions = true;" *ngIf="!showMoreImportOptions">
          <div class="icon">
            <div class="more-options">···</div>
          </div>
          <h2>More<br>Options</h2>
          <p>Import from file<br>or a private key</p>
        </button> -->
        <button class="wallet-option" (click)="setPanel(panels.import); selectedImportOption = 'ledger';" *ngIf="showMoreImportOptions">
          <div class="icon">
            <svg width="120" height="120" version="1.1" viewBox="0 0 160 160" xmlns="http://www.w3.org/2000/svg">
              <g>
                <path d="m42.088 127.05-9.6353 7.2071c-1.3309 0.99548-3.2037 0.72546-4.1992-0.60542l-20.857-27.884c-0.99548-1.3309-0.72546-3.2037 0.60543-4.1992l105.07-78.588c1.3309-0.99548 3.2037-0.72546 4.1992 0.60543l20.857 27.884c0.99548 1.3309 0.72546 3.2037-0.60543 4.1992l-41.858 31.309" fill="none" stroke="#000" stroke-linejoin="round" stroke-width="2"/>
              </g>
              <g fill="none" stroke="#000" stroke-width="2">
                <circle cx="35.53" cy="106.97" r="10.756"/>
                <path d="m153.08 86.935h-118.68s-19.277-0.014-19.277 20.789c0 19.291 19.655 19.655 19.655 19.655h118.31z"/>
              </g>
            </svg>
          </div>
          <h2>Use<br>Ledger</h2>
          <p>Nano S / Nano X</p>
        </button>
        <button *ngIf="showMoreImportOptions" class="wallet-option" (click)="setPanel(panels.import); selectedImportOption = 'seed';">
          <div class="icon">
            <div class="seed">{{ exampleSeed }}</div>
          </div>
          <h2>Import<br>Seed</h2>
          <p>64 hex<br>characters</p>
        </button>
        <button *ngIf="showMoreImportOptions" class="wallet-option" (click)="setPanel(panels.mnemonicTypeSelection);">
          <div class="icon">
            <div class="mnemonic">
              <div class="mnemonic-row">
                <div class="number">1.</div>
                <div class="word">{{ exampleMnemonicWords[0] }}</div>
              </div>
              <div class="mnemonic-row">
                <div class="number">2.</div>
                <div class="word">{{ exampleMnemonicWords[1] }}</div>
              </div>
              <div class="mnemonic-row">
                <div class="number">3.</div>
                <div class="word">{{ exampleMnemonicWords[2] }}</div>
              </div>
              <div class="mnemonic-row">
                <div class="number">4.</div>
                <div class="word">{{ exampleMnemonicWords[3] }}</div>
              </div>
            </div>
          </div>
          <h2>Import<br>Mnemonic</h2>
          <p>12, 15, 18, 21<br>or 24 words</p>
        </button>
        <button class="wallet-option" (click)="setPanel(panels.import); selectedImportOption = 'privateKey';" *ngIf="showMoreImportOptions">
          <div class="icon">
            <div class="seed">{{ examplePrivateKey }}</div>
          </div>
          <h2>Import<br>Private Key</h2>
          <p>64 hex<br>characters</p>
        </button>
        <button class="wallet-option" (click)="setPanel(panels.import); selectedImportOption = 'expandedKey';" *ngIf="showMoreImportOptions">
          <div class="icon">
            <div class="seed">{{ exampleExpandedPrivateKey }}</div>
          </div>
          <h2 class="long-header">Import<br>Expanded<br>Private Key</h2>
          <p>64 or 128 hex<br>characters</p>
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="import-panel-navigation"
  *ngIf="( (activePanel === panels.import) || (activePanel === panels.mnemonicTypeSelection) )"
  (click)="
      (
          (activePanel === panels.import)
        && (
             (selectedImportOption === 'mnemonic')
          || (selectedImportOption === 'bip39-mnemonic')
        )
      )
    ? setPanel(panels.mnemonicTypeSelection)
    : setPanel(panels.landing)
  ">
  <span uk-icon="icon: arrow-left; ratio: 1.5;"></span>
  <span class="label">Back</span>
</div>

<div class="uk-card uk-card-default wallet-setup-card" *ngIf="activePanel === panels.mnemonicTypeSelection">
  <div class="uk-card-body">
    <div class="landing-container uk-grid-divider" uk-grid>
      <div>
        <button class="wallet-option" (click)="setPanel(panels.import); selectedImportOption = 'mnemonic';">
          <div class="icon">
            <div class="mnemonic">
              <div class="mnemonic-row">
                <div class="number">1.</div>
                <div class="word">{{ exampleMnemonicWords[0] }}</div>
              </div>
              <div class="mnemonic-row">
                <div class="number">2.</div>
                <div class="word">{{ exampleMnemonicWords[1] }}</div>
              </div>
              <div class="mnemonic-row">
                <div class="number">3.</div>
                <div class="word">{{ exampleMnemonicWords[2] }}</div>
              </div>
              <div class="mnemonic-row">
                <div class="number">4.</div>
                <div class="word">{{ exampleMnemonicWords[3] }}</div>
              </div>
            </div>
          </div>
          <h2>Nano<br>Mnemonic</h2>
          <p>24 words</p>
        </button>
      </div>
      <div>
        <button class="wallet-option" (click)="setPanel(panels.import); selectedImportOption = 'bip39-mnemonic';">
          <div class="icon">
            <div class="mnemonic">
              <div class="mnemonic-row">
                <div class="number">1.</div>
                <div class="word">{{ exampleMnemonicWords[0] }}</div>
              </div>
              <div class="mnemonic-row">
                <div class="number">2.</div>
                <div class="word">{{ exampleMnemonicWords[1] }}</div>
              </div>
              <div class="mnemonic-row">
                <div class="number">3.</div>
                <div class="word">{{ exampleMnemonicWords[2] }}</div>
              </div>
              <div class="mnemonic-row">
                <div class="number">4.</div>
                <div class="word">{{ exampleMnemonicWords[3] }}</div>
              </div>
            </div>
          </div>
          <h2>BIP39<br>Mnemonic</h2>
          <p>12, 15, 18, 21<br>or 24 words</p>
        </button>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="(activePanel === panels.import) && ( (selectedImportOption === 'mnemonic') || (selectedImportOption === 'bip39-mnemonic') )"
>
  <div class="uk-alert uk-alert-primary nlt-inline-alert">
    <div class="icon-column">
      <div uk-icon="icon: info; ratio: 2;"></div>
    </div>
    <p style="max-width: 580px;">
      <b>Note for Ledger device owners:</b><br>
      Entering the mnemonic of your Ledger on this or any other device may expose your private keys, and should only be used as an emergency option to access the wallet.
    </p>
  </div>
</div>

<div
  *ngIf="(activePanel === panels.import) && ( (selectedImportOption === 'seed') )"
>
  <div class="uk-alert uk-alert-info nlt-inline-alert">
    <div class="icon-column">
      <div uk-icon="icon: info; ratio: 2;"></div>
    </div>
    <p style="max-width: 580px;">
      <b>Note:</b> A secret recovery seed provides access to 4,294,967,295 accounts.<br>
      To import a single-account private key instead, <a class="inline-link" (click)="selectedImportOption = 'privateKey';">click here</a>
    </p>
  </div>
</div>

<div
  *ngIf="(activePanel === panels.import) && ( (selectedImportOption === 'privateKey') )"
>
  <div class="uk-alert uk-alert-info nlt-inline-alert">
    <div class="icon-column">
      <div uk-icon="icon: info; ratio: 2;"></div>
    </div>
    <p style="max-width: 580px;">
      <b>Note:</b> A private key provides access to exactly one account.<br>
      To import a multi-account secret recovery seed instead, <a class="inline-link" (click)="selectedImportOption = 'seed';">click here</a>
    </p>
  </div>
</div>

<div class="uk-card uk-card-default" *ngIf="activePanel === panels.import">
  <div class="uk-card-body">
    <div uk-grid *ngIf="selectedImportOption === 'file'">
      <div class="uk-width-1-1">
        <h3>Import File</h3>
        <p>Click button below to import JSON file.</p>
      </div>
    </div>
    <div uk-grid *ngIf="selectedImportOption === 'seed'" class="uk-margin-top">
      <div class="uk-width-1-1">
        <h3>Import Secret Recovery Seed</h3>
        <p>
          Enter your secret recovery seed below.
        </p>
        <div class="uk-inline uk-width-1-1">
          <a class="uk-form-icon uk-form-icon-flip" uk-icon="icon: camera" (click)="openQR('seed1','hash')" uk-tooltip title="Scan from QR code"></a>
          <input type="text" class="uk-input" (keyup.enter)="setPasswordInit()" [(ngModel)]="importSeedModel" placeholder="64 hex character secret recovery seed">
        </div>
      </div>
    </div>
    <div uk-grid *ngIf="selectedImportOption === 'mnemonic'" class="uk-margin-top">
      <div class="uk-width-1-1">
        <h3>Import Secret Recovery Mnemonic</h3>
        <p>
          Enter your secret recovery mnemonic below.<br>If it came from a Ledger device or certain multi-currency wallets, use <a (click)="selectedImportOption = 'bip39-mnemonic';">BIP39 Mnemonic</a> import type instead.
        </p>
        <div class="uk-inline uk-width-1-1">
          <a class="uk-form-icon uk-form-icon-flip" uk-icon="icon: camera" (click)="openQR('mnemo1','mnemonic')" uk-tooltip title="Scan from QR code"></a>
          <textarea class="uk-textarea" rows="3" [(ngModel)]="importSeedMnemonicModel" placeholder="24-words secret recovery mnemonic"></textarea>
        </div>
      </div>
    </div>
    <div uk-grid *ngIf="selectedImportOption === 'bip39-mnemonic'" class="uk-margin-top">
      <div class="uk-width-1-1">
        <h3>Import Secret Recovery BIP39 Mnemonic</h3>
        <p>
          Enter your secret recovery BIP39 mnemonic below. It is typically generated by Ledger devices and certain multi-currency wallets.<br>If it was generated in Nault, use <a (click)="selectedImportOption = 'mnemonic';">Nano Mnemonic</a> import type instead.
        </p>
        <div class="uk-form-horizontal">
          <div class="uk-margin">
            <label class="uk-form-label" for="form-horizontal-select">BIP39 Mnemonic</label>
            <div class="uk-form-controls">
              <div class="uk-inline uk-width-expand">
                <a class="uk-form-icon uk-form-icon-flip" uk-icon="icon: camera" (click)="openQR('mnemo2','mnemonic')" uk-tooltip title="Scan from QR code"></a>
                <textarea class="uk-textarea" rows="2" [(ngModel)]="importSeedBip39MnemonicModel" placeholder="12,15,18,21 or 24-words secret recovery BIP39 mnemonic" autocomplete="off"></textarea>
              </div>
           </div>
          </div>
        </div>
        <div class="uk-form-horizontal">
          <div class="uk-margin">
            <label class="uk-form-label" for="form-horizontal-select">Account Index <span uk-icon="icon: info;" uk-tooltip title="The wallet will be imported as a single private key for the account index you provide."></span></label>
            <div class="uk-form-controls">
              <input type="text" class="uk-input uk-margin-small-bottom {{validIndex ? '':'uk-form-danger'}}" (keyup.enter)="setPasswordInit()" [(ngModel)]="importSeedBip39MnemonicIndexModel" (ngModelChange)="accountIndexChange(importSeedBip39MnemonicIndexModel)" maxLength="10" placeholder="0 to {{indexMax}}" autocomplete="off">
            </div>
          </div>
        </div>
        <div class="uk-form-horizontal">
          <div class="uk-margin">
            <label class="uk-form-label" for="form-horizontal-select">BIP39 Passphrase <span uk-icon="icon: info;" uk-tooltip title="Required only if the BIP39 seed is protected by a password or passphrase."></span></label>
            <div class="uk-form-controls">
              <input type="password" class="uk-input" (keyup.enter)="setPasswordInit()" [(ngModel)]="importSeedBip39MnemonicPasswordModel" placeholder="Optional" autocomplete="off">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div uk-grid *ngIf="selectedImportOption === 'ledger'" class="uk-margin-top">
      <div class="uk-width-1-1">
        <h3>Use Ledger Hardware Wallet</h3>
        <p>
          Make sure you have the nano app installed and running on your Ledger, then click one of the buttons below to connect via either USB or Bluetooth.<br> 
          <a href="https://docs.nault.cc/2020/08/04/ledger-guide.html" target="_blank" rel="noopener noreferrer">Ledger/Nault User Guide and Troubleshooting</a><br>
          <br>
        </p>
        <div *ngIf="!ledgerService.supportsUSB">
          <div class="uk-alert uk-alert-warning nlt-inline-alert">
            <div class="icon-column">
              <div uk-icon="icon: warning; ratio: 2;"></div>
            </div>
            <p>
              <b>Unsupported Browser</b><br>
              It looks like your browser does not support USB communication. Recommended browsers are: Chrome, Edge, Brave, or Opera.<br>
              Note: iOS is currently unsupported. On Mac, use any browser other than Safari.
            </p>
          </div>
        </div>
        <div *ngIf="ledgerService.supportsUSB">
          <div *ngIf="ledger.status === ledgerStatus.NOT_CONNECTED">
            <div class="uk-alert uk-alert-warning nlt-inline-alert">
              <div class="icon-column">
                <div uk-icon="icon: warning; ratio: 2;"></div>
              </div>
              <p>
                <b>Ledger Device Not Connected</b><br>
                Connect your Ledger device and make sure you have the nano app installed and running, then press the button below.<br>
                <span *ngIf=!ledgerService.supportsBluetooth>Bluetooth is currently not supported by your browser, please use Google Chrome or <a href="https://github.com/Nault/Nault/releases/latest" target="_blank" rel="noopener noreferrer">download the Desktop App</a></span>
              </p>
            </div>
          </div>
          <div *ngIf="ledger.status === ledgerStatus.LOCKED">
            <div class="uk-alert uk-alert-warning nlt-inline-alert">
              <div class="icon-column">
                <div uk-icon="icon: warning; ratio: 2;"></div>
              </div>
              <p>
                <b>Ledger Device Locked</b><br>
                Unlock your Ledger device, then press the button below to retry
              </p>
            </div>
          </div>
          <div *ngIf="ledger.status === ledgerStatus.READY">
            <div class="uk-alert uk-alert-success nlt-inline-alert">
              <div class="icon-column">
                <div uk-icon="icon: check; ratio: 2;"></div>
              </div>
              <p>
                <b>Ledger Device Ready</b><br>
                Press the import button below to use your ledger wallet
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div uk-grid *ngIf="selectedImportOption === 'privateKey'" class="uk-margin-top">
      <div class="uk-width-1-1">
        <h3>Import Private Key</h3>
        <p>
          Enter your nano account's private key below.
        </p>
        <div class="uk-inline uk-width-1-1">
          <a class="uk-form-icon uk-form-icon-flip" uk-icon="icon: camera" (click)="openQR('priv1','hash')" uk-tooltip title="Scan from QR code"></a>
          <input type="text" class="uk-input" (keyup.enter)="setPasswordInit()" [(ngModel)]="importPrivateKeyModel" placeholder="64 hex character Nano Private Key">
        </div>
      </div>
    </div>
    <div uk-grid *ngIf="selectedImportOption === 'expandedKey'" class="uk-margin-top">
      <div class="uk-width-1-1">
        <h3>Import Expanded Private Key</h3>
        <p>
          Enter your nano account's expanded private key below. These are rare, and are usually generated from multi-party cryptography magic.
        </p>
        <div class="uk-inline uk-width-1-1">
          <a class="uk-form-icon uk-form-icon-flip" uk-icon="icon: camera" (click)="openQR('expanded1','hash')" uk-tooltip title="Scan from QR code"></a>
          <input type="text" class="uk-input" (keyup.enter)="setPasswordInit()" [(ngModel)]="importExpandedKeyModel" placeholder="64 or 128 hex character Nano Expanded Private Key">
        </div>
      </div>
    </div>
  </div>
  <div class="uk-card-footer uk-text-right nlt-button-group">
    <div *ngIf="selectedImportOption === 'file'" class="js-upload uk-width-auto@s uk-width-1-1" uk-form-custom style="display: inline-block;">
      <input type="file" id="import-from-file" (change)="importFromFile($event.target.files)" multiple>
      <button class="uk-button uk-button-primary uk-width-auto@s uk-width-1-1" type="button" tabindex="-1">Import From File</button>
    </div>
    <button *ngIf="selectedImportOption === 'mnemonic'" class="uk-button uk-button-primary uk-width-auto@s uk-width-1-1" (click)="setPasswordInit()">Import From Mnemonic</button>
    <button *ngIf="selectedImportOption === 'bip39-mnemonic'" class="uk-button uk-button-primary uk-width-auto@s uk-width-1-1" [disabled]="!validIndex" (click)="setPasswordInit()">Import From Mnemonic</button>
    <button *ngIf="selectedImportOption === 'seed'" class="uk-button uk-button-primary uk-width-auto@s uk-width-1-1" (click)="setPasswordInit()">Import From Seed</button>
    <button *ngIf="selectedImportOption === 'ledger' && ledger.status === ledgerStatus.NOT_CONNECTED" [disabled]="!ledgerService.supportsUSB" class="uk-button uk-button-primary uk-width-auto@s uk-width-1-1" (click)="connectLedgerByUsb()">Connect via USB</button>
    <button *ngIf="selectedImportOption === 'ledger' && ledger.status === ledgerStatus.NOT_CONNECTED" [disabled]="!ledgerService.supportsBluetooth" class="uk-button uk-button-primary uk-width-auto@s uk-width-1-1" (click)="connectLedgerByBluetooth()">Connect via Bluetooth</button>
    <button *ngIf="selectedImportOption === 'ledger' && ledger.status !== ledgerStatus.NOT_CONNECTED" class="uk-button uk-button-primary uk-width-auto@s uk-width-1-1" (click)="importLedgerWallet(true)">Refresh Ledger Status</button>
    <button *ngIf="selectedImportOption === 'privateKey'" class="uk-button uk-button-primary uk-width-auto@s uk-width-1-1" (click)="setPasswordInit()">Import From Private Key</button>
    <button *ngIf="selectedImportOption === 'expandedKey'" class="uk-button uk-button-primary uk-width-auto@s uk-width-1-1" (click)="setPasswordInit()">Import From Expanded Private Key</button>
  </div>
</div>



<div class="uk-card uk-card-default" *ngIf="activePanel === panels.backup">

  <div class="uk-card-body">
    <div uk-grid>
      <div class="uk-width-1-4@m">{{ 'configure-wallet.new-wallet.secret-recovery-seed' | transloco }}</div>
      <div class="uk-width-3-4@m">
        <div class="new-wallet-seed uk-margin-small-bottom">{{ newWalletSeed }}</div>
        <button
          class="uk-button uk-button-secondary uk-text-center nlt-icon-button"
          type="button"
          ngxClipboard
          [cbContent]="newWalletSeed"
          (cbOnSuccess)="copiedNewWalletSeed()"
        >
          <span class="nlt-icon" uk-icon="icon: copy;"></span>
          {{ 'configure-wallet.new-wallet.copy-secret-recovery-seed' | transloco }}
        </button>
      </div>
    </div>

    <hr class="uk-divider-icon">

    <div uk-grid>
      <div class="uk-width-1-4@m">{{ 'configure-wallet.new-wallet.secret-recovery-mnemonic' | transloco }}</div>
      <div class="uk-width-3-4@m">
        <pre class="mne-box"><span *ngFor="let line of newWalletMnemonicLines; let l = index" class="mne-cont"><span *ngFor="let word of line; let i = index" class="mne-word"><span class="mne-num">{{ (l * 4) + i + 1 }}</span> {{ word }}</span></span></pre>
        <button
          class="uk-button uk-button-secondary uk-text-center nlt-icon-button"
          type="button"
          ngxClipboard
          [cbContent]="newWalletMnemonic"
          (cbOnSuccess)="copiedNewWalletMnemonic()"
        >
          <span class="nlt-icon" uk-icon="icon: copy;"></span>
          {{ 'configure-wallet.new-wallet.copy-secret-recovery-mnemonic' | transloco }}
        </button>
      </div>
    </div>
    <hr/>
    <div class="uk-width-1-1">
      <label style="cursor: pointer;"><input type="checkbox" class="uk-checkbox" [(ngModel)]="hasConfirmedBackup"> &nbsp; {{ 'configure-wallet.new-wallet.i-have-securely-stored-recovery-phrases' | transloco }}</label>
    </div>
  </div>
  <div class="uk-card-footer uk-text-right nlt-button-group">
    <button class="uk-button uk-button-primary uk-width-auto@s uk-width-1-1" id="create-wallet" [disabled]="!hasConfirmedBackup" (click)="confirmNewSeed()">{{ 'configure-wallet.new-wallet.create-wallet' | transloco }}</button>
  </div>
</div>

<div class="uk-card uk-card-default" *ngIf="activePanel === panels.password">
  <div class="uk-card-header">
    <h3 class="uk-card-title">{{ 'configure-wallet.set-wallet-password.set-wallet-password' | transloco }}</h3>
  </div>
  <div class="uk-card-body">
    <p>{{ 'configure-wallet.set-wallet-password.choose-a-wallet-password-that-will-be-used-to-unlock-this-application' | transloco }}</p>
    <br>
    <div uk-grid>
      <div class="uk-width-1-2@m">
        <input type="password" class="uk-input" [(ngModel)]="walletPasswordModel" placeholder="New Wallet Password">
        <!-- <password-strength-meter *ngIf="walletPasswordModel.length > 0" [password]="walletPasswordModel" [enableFeedback]="true" [minPasswordLength]="6"></password-strength-meter> -->
        <span class="password-helper" *ngIf="walletPasswordModel.length > 0 && walletPasswordModel.length < 3">{{ 'configure-wallet.set-wallet-password.errors.password-must-be-at-least-x-characters-long' | transloco: { minCharacters: 3 } }}</span>
        <span class="password-helper" *ngIf="walletPasswordConfirmModel.length >= 6 && walletPasswordModel !== walletPasswordConfirmModel">{{ 'configure-wallet.set-wallet-password.errors.passwords-do-not-match' | transloco }}</span>
      </div>
      <div class="uk-width-1-2@m">
        <input type="password" class="uk-input" (keyup.enter)="saveWalletPassword()" [(ngModel)]="walletPasswordConfirmModel" placeholder="Confirm Wallet Password">
      </div>
    </div>
  </div>
  <div class="uk-card-footer uk-text-right">
    <button class="uk-button uk-button-primary uk-width-auto@s uk-width-1-1" [disabled]="walletPasswordModel.length < 3 || (walletPasswordModel !== walletPasswordConfirmModel)" (click)="saveWalletPassword()">{{isNewWallet ? 'Next':'Import Wallet'}}</button>
  </div>
</div>

<div class="uk-card uk-card-default" *ngIf="activePanel === panels.final">
  <div class="uk-card-header">
    <h3 class="uk-card-title">Wallet Created</h3>
  </div>
  <div class="uk-card-body">
    <p>
      Your wallet has been created and ready to use.<br>
    </p>
  </div>
  <div class="uk-card-footer uk-text-right">
    <button routerLink="/accounts" class="uk-button uk-button-primary uk-width-auto@s uk-width-1-1">View Accounts</button>
  </div>
</div>

<!-- <div *ngIf="activePanel === panels.final && !walletService.isLedgerWallet()" class="uk-alert-primary" uk-alert>
  <p><strong>ProTip:</strong> If you lose your password, you can always restore your wallet using your seed or mnemonic you were given.</p>
</div>
<div *ngIf="activePanel === panels.final && walletService.isLedgerWallet()" class="uk-alert-primary" uk-alert>
  <p><strong>ProTip:</strong> Press the Ledger status bar on the left side to reload your Ledger when connecting or disconnecting it.</p>
</div> -->
