<div class="uk-animation-slide-left-small" uk-grid>
  <div class="uk-width-1-1">
    <h2 class="uk-heading-divider">Import Wallet</h2>

    <div class="uk-card uk-card-default uk-margin" *ngIf="activePanel == 'error'">
      <div class="uk-card-header">
        <h3 class="uk-card-title">Bad Import Data</h3>
      </div>
      <div class="uk-card-body">
        <p>There was an issue reading the import data for your wallet.  Double check the address and try again.</p>
      </div>
    </div>

    <div class="uk-card uk-card-default uk-margin" *ngIf="activePanel == 'import'">
      <div *ngIf="walletService.isConfigured()" class="uk-alert uk-alert-danger">
        <b>You already have a local Nault wallet configured</b><br>
        Before going any further, be 100% certain you have backed up the secret recovery phrase for your current wallet!<br>
        Without it, <b>any funds you have will become completely unrecoverable!</b>
      </div>
      <div class="uk-card-header">
        <h3 class="uk-card-title">Confirm Wallet Import</h3>
      </div>
      <div class="uk-card-body">
        <p>You are about to import a wallet {{hostname ? 'from ' + hostname : ''}} - just enter your {{hostname ? 'wallet' : ''}} password to decrypt it!</p>
        <div uk-grid>
          <div class="uk-width-1-1">
            <input class="uk-input" [(ngModel)]="walletPassword" placeholder="Wallet Password" type="password">
          </div>
        </div>
      </div>
      <div class="uk-card-footer uk-text-right">
        <button (click)="decryptWallet()" class="uk-button uk-button-secondary">Import Wallet</button>
      </div>
    </div>

    <div class="uk-card uk-card-default uk-margin" *ngIf="activePanel == 'imported'">
      <div class="uk-card-header">
        <h3 class="uk-card-title">Wallet Imported</h3>
      </div>
      <div class="uk-card-body">
        <p>Your wallet has been successfully imported!</p>
      </div>
      <div class="uk-card-footer uk-text-right">
        <button routerLink="/accounts" class="uk-button uk-button-secondary">View Accounts</button>
      </div>
    </div>


  </div>
</div>
