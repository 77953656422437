<div class="uk-animation-slide-left-small" uk-grid>
  <div class="uk-width-1-1">

    <h2 class="uk-heading-divider">Unit Converter</h2>

    <div class="uk-card uk-card-default">
      <div class="uk-card-body">
        <div uk-grid>
          <div class="uk-width-1-1 narrow-div">
            <div class="uk-form-horizontal">
              <div class="uk-margin">
                <label class="uk-form-label" for="mnano">XNO <span uk-icon="icon: info;" uk-tooltip title="The main unit used in nano wallets and exchanges."></span></label>
                <div class="uk-form-controls">
                  <div uk-grid>
                    <div class="uk-width-1-1">
                      <div class="uk-inline uk-width-1-1">
                        <a class="uk-form-icon uk-form-icon-flip" ngxClipboard [cbContent]="Mnano" (cbOnSuccess)="notifications.sendSuccess('Value Copied!')" uk-icon="icon: copy" title="Copy Value" uk-tooltip></a>
                        <input [(ngModel)]="Mnano" class="uk-input uk-margin-small-bottom" [ngClass]="{ 'uk-form-danger': invalidMnano }" id="mnano" type="text" (ngModelChange)="unitChange('mnano')" placeholder="" autocomplete="off">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="uk-width-1-1 narrow-div">
            <div class="uk-form-horizontal">
              <div class="uk-margin">
                <label class="uk-form-label" for="raw">raw <span uk-icon="icon: info;" uk-tooltip title="The smallest possible unit of XNO."></span></label>
                <div class="uk-form-controls">
                <div uk-grid>
                    <div class="uk-width-1-1">
                    <div class="uk-inline uk-width-1-1">
                      <a class="uk-form-icon uk-form-icon-flip" ngxClipboard [cbContent]="raw" (cbOnSuccess)="notifications.sendSuccess('Value Copied!')" uk-icon="icon: copy" title="Copy Value" uk-tooltip></a>
                      <input [(ngModel)]="raw" class="uk-input uk-margin-small-bottom" [ngClass]="{ 'uk-form-danger': invalidRaw }" id="raw" type="text" (ngModelChange)="unitChange('raw')" placeholder="" autocomplete="off">
                    </div>
                    </div>
                </div>
                </div>
              </div>
            </div>
          </div>

          <div class="uk-width-1-1 narrow-div">
            <div class="uk-form-horizontal">
              <div class="uk-margin">
                <label class="uk-form-label" for="fiat">{{settings.settings.displayCurrency}} <span uk-icon="icon: info;" uk-tooltip title="Latest fiat price. The currency can be changed from the app settings."></span></label>
                <div class="uk-form-controls">
                <div uk-grid>
                    <div class="uk-width-1-1">
                    <div class="uk-inline uk-width-1-1">
                      <a class="uk-form-icon uk-form-icon-flip" ngxClipboard [cbContent]="fiatPrice" (cbOnSuccess)="notifications.sendSuccess('Value Copied!')" uk-icon="icon: copy" title="Copy Value" uk-tooltip></a>
                      <input [(ngModel)]="fiatPrice" class="uk-input uk-margin-small-bottom" [ngClass]="{ 'uk-form-danger': invalidFiat }" id="fiat" type="text" (ngModelChange)="unitChange('fiat')" placeholder="" autocomplete="off">
                    </div>
                    </div>
                </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  
  </div>
  