<div uk-grid class="app" (mousemove)="updateIdleTime()" (keypress)="updateIdleTime()" [style.height]="( innerHeight + 'px' )" style="overflow-y: hidden;">
  <div class="uk-width-1-1" style="position: relative; overflow-y: hidden;">

    <!-- Mobile top bar -->
    <div uk-grid class="mobile-top-bar uk-background-primary">
      <div class="uk-width-1-2 button-column">
        <div class="nav-button" (click)="toggleNav()">
          <span uk-icon="icon: menu;"></span>
          <span class="app-name"><b>Nano</b> Pro</span>
        </div>
      </div>
      <div class="uk-width-1-2" style="text-align: right;">
      </div>
    </div>

    <!-- Accounts dropdown -->
    <div #accountsDropdown [class]="[ 'accounts-dropdown', showAccountsDropdown ? 'visible' : 'hidden' ]">
      <div class="header">Currently Selected</div>
      <div class="account active" *ngIf="wallet.selectedAccount !== null"> <!-- active nano_ account -->
        <div class="name-column">
          <div class="name">{{ wallet.selectedAccount.addressBookName ? wallet.selectedAccount.addressBookName : (('general.account' | transloco) + ' #' + wallet.selectedAccount.index) }}</div>
          <div class="address nano-address-monospace">{{ wallet.selectedAccount.id }}</div>
        </div>
        <div class="balance-column">
          <ng-container *ngIf="(walletService.wallet.updatingBalance === false) else balanceLoading">
            <div class="balance primary">
              {{ wallet.selectedAccount.balance | rai: 'mnano,true' | amountsplit: 0 }}{{ wallet.selectedAccount.balance | rai: 'mnano,true' | amountsplit: 1 }} XNO
            </div>
            <div class="balance converted">{{ wallet.selectedAccount.balanceFiat | fiat: settings.settings.displayCurrency }}</div>
          </ng-container>
          <ng-template #balanceLoading>
            <div class="balance loading">
              <div uk-spinner="ratio: 0.5;"></div>
            </div>
          </ng-template>
        </div>
      </div>
      <div [class]="[ 'account', (wallet.selectedAccount === null) ? 'active' : 'inactive' ]" (click)="selectAccount(null)"> <!-- active/inactive total balance -->
        <div class="name-column">
          <div class="name">Balance</div>
          <div class="address"> </div>
        </div>
        <div class="balance-column">
          <ng-container *ngIf="(walletService.wallet.updatingBalance === false) else balanceLoading">
            <div class="balance primary">{{ wallet.balance | rai: 'mnano,true' | amountsplit: 0 }}{{ wallet.balance | rai: 'mnano,true' | amountsplit: 1 }} XNO</div>
            <div class="balance converted">{{ wallet.balanceFiat | fiat: settings.settings.displayCurrency }}</div>
          </ng-container>
          <ng-template #balanceLoading>
            <div class="balance loading">
              <div uk-spinner="ratio: 0.5;"></div>
            </div>
          </ng-template>
        </div>
      </div>
      <ng-container *ngFor="let account of wallet.accounts"> <!-- inactive accounts -->
        <div class="account inactive" (click)="selectAccount(account)" *ngIf="( (wallet.selectedAccount === null) || (wallet.selectedAccount.id !== account.id) )">
          <div class="name-column">
            <div class="name">{{ account.addressBookName ? account.addressBookName : (('general.account' | transloco) + ' #' + account.index) }}</div>
            <div class="address nano-address-monospace">{{ account.id }}</div>
          </div>
          <div class="balance-column">
            <ng-container *ngIf="(walletService.wallet.updatingBalance === false) else balanceLoading">
              <div class="balance primary">
                <span class="incoming-label" *ngIf="account.pending.gt(0)">
                  <span class="text-snippet">{{ 'general.new' | transloco }}</span>
                  <span class="text-full">+{{ account.pending | rai: 'mnano,true' | amountsplit: 0 }}{{ account.pending | rai: 'mnano,true' | amountsplit: 1 }} XNO</span>
                </span>
                {{ account.balance | rai: 'mnano,true' | amountsplit: 0 }}{{ account.balance | rai: 'mnano,true' | amountsplit: 1 }} XNO
              </div>
              <div class="balance converted">{{ account.balanceFiat | fiat: settings.settings.displayCurrency }}</div>
            </ng-container>
            <ng-template #balanceLoading>
              <div class="balance loading">
                <div uk-spinner="ratio: 0.5;"></div>
              </div>
            </ng-template>
          </div>
        </div>
      </ng-container>
    </div>

    <!-- Main content container-->
    <div
      uk-grid
      class="app-grid"
      [style.height]="( innerHeightWithoutMobileBar + 'px' )"
    >
      <div
        class="uk-width-1-4 nav-container"
        [style.height]="( innerHeightWithoutMobileBar + 'px' )"
      >
        <div class="nav-header">

          <div uk-grid>
            <div class="uk-width-expand">

              <div [class]="[ 'nano-card', (wallet.selectedAccount === null) ? 'is-wallet' : 'is-card' ]">
                <div class="logo"></div>
                <div class="card-contents">
                  <div class="account-name-container">
                    <a
                      [routerLink]="(
                          (wallet.selectedAccount !== null)
                        ? '/account/' + wallet.selectedAccount.id
                        : '/accounts'
                      )"
                      [queryParams]="{ compact: '1' }"
                      class="name"
                    >{{
                        (wallet.selectedAccount !== null)
                      ? (
                          wallet.selectedAccount.addressBookName
                        ? wallet.selectedAccount.addressBookName
                        : (('general.account' | transloco) + ' #' + wallet.selectedAccount.index)
                      )
                      : 'Balance'
                    }}</a>
                    <div #selectButton [class]="[ 'select-button', showAccountsDropdown ? 'open' : 'closed' ]" (click)="toggleAccountsDropdown()">
                      <div class="circle"></div>
                      <div class="circle"></div>
                      <div class="circle"></div>
                    </div>
                  </div>
                  <div class="account-balances">
                    <ng-container *ngIf="( ( node.status && (wallet.balanceInitialized === true) ) || !isConfigured() ) else balancesLoading">
                      <div class="balance-container primary">
                        <!-- <div class="currency-name">NANO</div> -->
                        <div class="amount-container">
                          <div class="amount-integer">{{ (wallet.selectedAccount !== null ? wallet.selectedAccount.balance : wallet.balance) | rai: 'mnano,true' | amountsplit: 0 }}</div>
                          <div class="amount-fractional">{{ (wallet.selectedAccount !== null ? wallet.selectedAccount.balance : wallet.balance) | rai: 'mnano,true' | amountsplit: 1 }}</div>
                          <div class="amount-updating" *ngIf="wallet.updatingBalance && isConfigured()"><div uk-spinner="ratio: 0.5;"></div></div>
                        </div>
                      </div>
                      <div class="balance-container converted" *ngIf="settings.settings.displayCurrency">
                        <div class="currency-name"><span class="estimate-symbol">~</span>{{ settings.settings.displayCurrency }}</div>
                        <div class="amount-container">
                          <div class="amount-integer">{{ (wallet.selectedAccount !== null ? wallet.selectedAccount.balanceFiat : wallet.balanceFiat) | fiat: settings.settings.displayCurrency | amountsplit: 0 }}</div>
                          <div class="amount-fractional">{{ (wallet.selectedAccount !== null ? wallet.selectedAccount.balanceFiat : wallet.balanceFiat) | fiat: settings.settings.displayCurrency | amountsplit: 1 }}</div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-template #balancesLoading>
                      <div class="spinner-container" *ngIf="(node.status !== false)">
                        <div uk-spinner="ratio: 0.6;"></div>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>

              <ng-container *ngIf="(wallet.selectedAccount !== null) ? wallet.selectedAccount.pending.gt(0) : walletService.hasPendingTransactions()">
                <ng-container *ngIf="walletService.processingPending else notProcessingPending">
                  <div class="nav-action-receive" *ngIf="walletService.processingPending">
                    <div class="icon" uk-icon="icon: chevron-up; ratio: 1.2;"></div>
                    <div class="label"><span uk-spinner="ratio: 0.5;"></span>Processing Transactions...</div>
                  </div>
                </ng-container>
                <ng-template #notProcessingPending>
                  <ng-container *ngIf=" (settings.settings.pendingOption !== 'manual') else manuallyReceivingPending ">
                    <div class="nav-action-receive user-action-required uk-animation-slide-left" *ngIf="walletService.isLocked()">
                      <div class="icon" uk-icon="icon: chevron-up; ratio: 1.2;"></div>
                      <div class="label">Unlock Wallet to Receive</div>
                    </div>
                  </ng-container>
                  <ng-template #manuallyReceivingPending>
                    <div class="nav-action-receive uk-animation-slide-left">
                      <div class="icon" uk-icon="icon: chevron-up; ratio: 1.2;"></div>
                      <div class="label">Set to Receive Manually</div>
                    </div>
                  </ng-template>
                </ng-template>
                <div class="nano-card incoming-card uk-animation-slide-left">
                  <div class="card-contents">
                    <div class="account-name-container">
                      <a routerLink="/receive" class="name">Incoming Balance</a>
                    </div>
                    <div class="account-balances">
                      <div class="balance-container primary">
                        <!-- <div class="currency-name">NANO</div> -->
                        <div class="amount-container">
                          <div class="amount-integer">{{ (wallet.selectedAccount ? wallet.selectedAccount.pending : wallet.pending) | rai: 'mnano,true' | amountsplit: 0 }}</div>
                          <div class="amount-fractional">{{ (wallet.selectedAccount ? wallet.selectedAccount.pending : wallet.pending) | rai: 'mnano,true' | amountsplit: 1 }}</div>
                        </div>
                      </div>
                      <div class="balance-container converted" *ngIf="settings.settings.displayCurrency">
                        <div class="currency-name"><span class="estimate-symbol">~</span>{{ settings.settings.displayCurrency }}</div>
                        <div class="amount-container">
                          <div class="amount-integer">{{ (wallet.selectedAccount ? wallet.selectedAccount.pendingFiat : wallet.pendingFiat) | fiat: settings.settings.displayCurrency | amountsplit: 0 }}</div>
                          <div class="amount-fractional">{{ (wallet.selectedAccount ? wallet.selectedAccount.pendingFiat : wallet.pendingFiat) | fiat: settings.settings.displayCurrency | amountsplit: 1 }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="nav-separator-top"></div>
              </ng-container>

              <div class="nav-status-row" *ngIf="(node.status === false) && isConfigured()">
                <div class="status-icon">
                  <span class="uk-text-danger" uk-icon="icon: warning; ratio: 1.2;"></span>
                </div>
                <div class="status-labels" *ngIf="settings.settings.serverAPI">
                  <div class="label primary uk-text-danger" style="text-decoration: none">Offline Mode</div>
                  <div class="label secondary"><a (click)="retryConnection()">Refresh</a> or <a routerLink="/configure-app">change Node</a></div>
                </div>
                <div class="status-labels" *ngIf="!settings.settings.serverAPI">
                  <div class="label primary uk-text-danger">Wallet is in offline mode</div>
                  <div class="label secondary"><a routerLink="/configure-app">Select a server</a> to interact with the network</div>
                </div>
              </div>

              <app-change-rep-widget *ngIf="isConfigured()"></app-change-rep-widget>
              <app-wallet-widget></app-wallet-widget>

            </div>
          </div>

        </div>

        <ul class="uk-nav uk-nav-default uk-nav-parent-icon left-nav" uk-nav>
          <li><a routerLink="/accounts" routerLinkActive="active"><div class="label">{{ 'general.accounts' | transloco }}</div></a></li>
          <li><a routerLink="/send" routerLinkActive="active"><div class="label">{{ 'general.send' | transloco }}</div></a></li>
          <li>
            <a routerLink="/receive" routerLinkActive="active">
              <div uk-grid>
                <div class="uk-width-3-4">
                  <div class="label">{{ 'general.receive' | transloco }}</div>
                </div>
                <div class="uk-width-1-4 uk-text-center label-new">
                  <span *ngIf="walletService.hasPendingTransactions()" class="uk-badge uk-text-top uk-align-center"><div class="label">{{ 'general.new' | transloco }}</div></span>
                </div>
              </div>
            </a>
          </li>
          <li>
            <a routerLink="/search" routerLinkActive="active"><div class="label">Search</div></a>
          </li>
        <!--   <li>
            <a routerLink="/donate" routerLinkActive="active"><div class="label">Donate</div></a>
          </li> -->
          <li>
            <a routerLink="/ai" routerLinkActive="active"><div class="label">NaultGPT</div></a>
          </li>
          <!-- <li>
            <a routerLink="/social" routerLinkActive="active"><div class="label">XnoSocial</div></a>
          </li> -->
          <li class="uk-parent">
            <a href="#">{{ 'general.settings' | transloco }}</a>
            <ul class="uk-nav-sub">
              <li><a routerLink="/configure-app" routerLinkActive="active"><div class="label">{{ 'general.app-settings' | transloco }}</div></a></li>
              <li *ngIf="isConfigured()"><a routerLink="/manage-wallet" routerLinkActive="active"><div class="label">{{ 'general.wallet-settings' | transloco }}</div></a></li>
              <li><a routerLink="/representatives" [queryParams]="" routerLinkActive="active"><div class="label">{{ 'general.representatives' | transloco }}</div></a></li>
             
              <!-- <li class="uk-parent"> -->
                <!-- <a href="#">Advanced</a> -->
                <!-- <ul class="uk-nav-sub"> -->
                  <li><a href="https://simpleswap.io/?ref=ecc1985b556a" target="_blank"><div class="label">SimpleSwap 
                    <div style="display: inline-block;filter: invert(1);margin-left: 6px;zoom: 0.8;vertical-align: text-bottom;"><svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="M15,10 L15,14 C15,15.1045695 14.1045695,16 13,16 L2,16 C0.8954305,16 0,15.1045695 0,14 L0,3 C0,1.8954305 0.8954305,1 2,1 L6,1 L6,3 L2,3 L2,14 L13,14 L13,10 L15,10 Z M13.9971001,3.41421356 L7.70420685,9.70710678 L6.28999329,8.29289322 L12.5828865,2 L8.99710007,2 L8.99710007,0 L15.9971001,0 L15.9971001,7 L13.9971001,7 L13.9971001,3.41421356 Z" fill-rule="evenodd"></path></svg></div>

                  </div></a></li>
                  <!-- <li><a routerLink="/address-book" routerLinkActive="active"><div class="label">{{ 'general.address-book' | transloco }}</div></a></li> -->
                  <!-- <li><a routerLink="/qr-scan" routerLinkActive="active"><div class="label">{{ 'general.scan-qr-code' | transloco }}</div></a></li> -->
                  <!-- <li><a routerLink="/sweeper" routerLinkActive="active"><div class="label">Wallet Sweeper</div></a></li> -->
                  <li><a routerLink="/remote-signing" routerLinkActive="active"><div class="label">Remote Signing</div></a></li>
                  <li><a routerLink="/multisig" routerLinkActive="active"><div class="label">Multi Signature</div></a></li>
                  <li><a routerLink="/keygenerator" routerLinkActive="active"><div class="label">Keypair Generator</div></a></li>
                  <li><a routerLink="/converter" routerLinkActive="active"><div class="label">Unit Converter</div></a></li>
                  <li><a routerLink="/qr-generator" routerLinkActive="active"><div class="label">QR Generator</div></a></li>
                  <li><a routerLink="/configure-wallet" routerLinkActive="active"><div class="label">Configure New Wallet</div></a></li>
                <!-- </ul> -->
              <!-- </li> -->
            </ul>
          </li>
        </ul>

        <app-install-widget></app-install-widget>

        <div class="nav-search">
          <form class="uk-search uk-search-default uk-width-1-1">
            <a href="javascript:void(0)" (click)="performSearch()" class="uk-search-icon-flip" uk-search-icon></a>
            <input class="uk-search-input uk-width-1-1" id="search-input" type="search" placeholder="Search for an account or block hash" [(ngModel)]="searchData" name="searchData" (keyup.enter)="performSearch()">
          </form>
        </div>

        <div class="footer footer-actions uk-margin-remove-top uk-flex-middle" uk-grid>
          <div class="uk-width-1-3 uk-text-center">
            <a class="support-nault" target="https://nano.to" target="_blank">
              <div class="button">
                <a class="footer-link donation-icon"><span uk-icon="icon: heart; ratio: 1.2;"></span></a>
              </div>
              <div class="section-caption">Nano.to</div>
            </a>
          </div>
          <div class="uk-width-1-3 uk-text-center community-column">
            <div class="icons-row">
              <a href="https://github.com/fwd/Nault-Pro" uk-icon="icon: github-alt; ratio: 1.2;" uk-tooltip title="View our GitHub" class="footer-link" target="_blank" rel="noopener noreferrer"></a>
              <a href="https://discord.gg/2xYr2ZthZF" uk-tooltip title="Join Nault.Pro on Discord" class="footer-link discord-icon" target="_blank" rel="noopener noreferrer">
                <svg width="28" height="28" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 245 240"><path class="st0" d="M104.4 103.9c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1.1-6.1-4.5-11.1-10.2-11.1zM140.9 103.9c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1s-4.5-11.1-10.2-11.1z"/><path class="st0" d="M189.5 20h-134C44.2 20 35 29.2 35 40.6v135.2c0 11.4 9.2 20.6 20.5 20.6h113.4l-5.3-18.5 12.8 11.9 12.1 11.2 21.5 19V40.6c0-11.4-9.2-20.6-20.5-20.6zm-38.6 130.6s-3.6-4.3-6.6-8.1c13.1-3.7 18.1-11.9 18.1-11.9-4.1 2.7-8 4.6-11.5 5.9-5 2.1-9.8 3.5-14.5 4.3-9.6 1.8-18.4 1.3-25.9-.1-5.7-1.1-10.6-2.7-14.7-4.3-2.3-.9-4.8-2-7.3-3.4-.3-.2-.6-.3-.9-.5-.2-.1-.3-.2-.4-.3-1.8-1-2.8-1.7-2.8-1.7s4.8 8 17.5 11.8c-3 3.8-6.7 8.3-6.7 8.3-22.1-.7-30.5-15.2-30.5-15.2 0-32.2 14.4-58.3 14.4-58.3 14.4-10.8 28.1-10.5 28.1-10.5l1 1.2c-18 5.2-26.3 13.1-26.3 13.1s2.2-1.2 5.9-2.9c10.7-4.7 19.2-6 22.7-6.3.6-.1 1.1-.2 1.7-.2 6.1-.8 13-1 20.2-.2 9.5 1.1 19.7 3.9 30.1 9.6 0 0-7.9-7.5-24.9-12.7l1.4-1.6s13.7-.3 28.1 10.5c0 0 14.4 26.1 14.4 58.3 0 0-8.5 14.5-30.6 15.2z"/></svg>
              </a>
            </div>
            <div class="section-caption">Community</div>
          </div>
          <div class="uk-width-1-3 uk-text-center dark-mode-column">
            <div [class]="{'dark-mode-click-area': true, 'enabled': !settings.settings.lightModeEnabled}" (click)="toggleLightMode()">
              <div class="dark-mode-area">
                <div class="dark-mode-switch">
                  <svg width="16" height="16" version="1.1" viewBox="0 0 160 160" xmlns="http://www.w3.org/2000/svg">
                   <g fill="#EEE" stroke="#EEE" stroke-linejoin="round" stroke-width="2">
                    <path d="m145.54 108.99c-5.5673 25.239-34.67 39.667-62.22 39.667-37.888 0-68.603-30.714-68.603-68.603-1e-6 -37.888 30.714-68.603 68.603-68.603 9.8615 0 19.237 2.0808 27.712 5.8272-105.27 26.034-34.024 146.53 34.508 91.711z" stop-color="#000000"/>
                    <path transform="matrix(.69599 .69599 -.69599 .69599 66.459 -75.221)" d="m120.54 62.862c-2.3901-1e-6 -9.2713 6.4507-10.961 4.7607s4.7607-8.5712 4.7607-10.961c0-2.3901-6.4507-9.2713-4.7607-10.961s8.5712 4.7607 10.961 4.7607 9.2712-6.4507 10.961-4.7607c1.69 1.6901-4.7607 8.5712-4.7607 10.961 0 2.3901 6.4507 9.2713 4.7607 10.961-1.6901 1.6901-8.5712-4.7607-10.961-4.7607z" stop-color="#000000"/>
                   </g>
                  </svg>
                </div>
              </div>
              <div class="section-caption">Night Mode</div>
            </div>
          </div>
        </div>
        <div class="footer footer-attribution uk-margin-remove-top" uk-grid>
          <div class="attribution uk-width-1-1 uk-text-center">
            Based on <a href="https://github.com/Nault/Nault" target="_blank" rel="noopener noreferrer">Nault</a>
          </div>
        </div>


      </div>
      <div
        class="uk-width-expand content-container uk-panel"
        [class.nav-expanded]="navExpanded"
        [class.nav-animating]="navAnimating"
        [style.height]="( innerHeightWithoutMobileBar + 'px' )"
        (click)="closeNav()"
      >
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

<app-notifications></app-notifications>
