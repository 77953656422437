<div class="uk-animation-slide-left-small" uk-grid>
  <div class="uk-width-1-1">
    
    <div class="uk-margin-bottom" uk-grid>
      <div class="uk-width-expand@s uk-width-1-1">
        <h2 class="uk-heading-divider uk-text-truncate">
          {{ 'general.accounts' | transloco }}
          <a (click)="viewAdvanced = !viewAdvanced" class="uk-text-truncate" style="font-size: 12px;margin-left: 25px;opacity: 0.4;color: #FFF;">{{ viewAdvanced ? ('accounts.simple-mode' | transloco | uppercase) : ('accounts.advanced-mode' | transloco | uppercase) }}</a>
        </h2>
      </div>
      <div class="">
        <div class="uk-grid-small uk-child-width-expand" uk-grid>
          <div>
            <input type="number" class="uk-input uk-form-width-small uk-width-1-1@m" [(ngModel)]="newAccountIndex" placeholder="Account #" *ngIf="viewAdvanced">
          </div>
          <div class="uk-width-auto@m">
            <button class="uk-button uk-button-secondary uk-width-1-1@m" (click)="createAccount()">
              {{ 'accounts.add-new-account' | transloco }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="uk-card uk-overflow-hidden">
      <table class="accounts-list uk-table uk-table-striped uk-table-small">
        <thead>
        <tr>
          <th scope="col" class="account-column uk-width-3-5@m uk-width-1-2" [class.identicons-enabled]="(settings.settings.identiconsStyle !== 'none')">
            {{ 'general.account' | transloco }}
          </th>
          <th scope="col" class="account-amounts-column uk-width-1-5@m uk-width-1-4 uk-text-right">{{ 'general.balance' | transloco }}</th>
          <th scope="col" [class]="{
            'account-actions-column': true,
            'table-header-delete-account': true,
            'uk-width-1-5@m': true,
            'uk-width-1-4': true,
            'uk-text-right': true,
            'hidden-column': ( isSingleKeyWallet || !viewAdvanced )
          }"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let account of accounts" class="uk-visible-toggle">
          <ng-template #accountAmounts>
            <ng-container *ngIf="(walletService.wallet.updatingBalance === false) else balanceLoading">
              <div class="account-amounts-primary uk-width-1-1">
                <div *ngIf="account.pending.gt(0)" class="incoming-label">
                  <div class="text-snippet">{{ 'general.new' | transloco }}</div>
                  <div class="text-full">+{{ account.pending | rai: 'mnano,true' | amountsplit: 0 }}{{ account.pending | rai: 'mnano,true' | amountsplit: 1 }} NANO</div>
                </div>
                <span class="amounts" [title]="( account.balanceRaw.gt(0) ? ( '+' + ( account.balanceRaw.toString(10) ) + ' raw' ) : '' )">
                  <span class="amount-integer">{{ account.balance | rai: 'mnano,true' | amountsplit: 0 }}</span>
                  <span class="amount-fractional">{{ account.balance | rai: 'mnano,true' | amountsplit: 1 }}</span>
                  <span class="currency-name">NANO</span>
                </span>
              </div>
              <div class="account-amounts-converted uk-width-1-1 text-half-muted">
                {{ account.balanceFiat | fiat: settings.settings.displayCurrency }}
              </div>
            </ng-container>
            <ng-template #balanceLoading>
              <div class="account-amounts-primary uk-width-1-1">
                <div uk-spinner="ratio: 0.5;"></div>
              </div>
            </ng-template>
          </ng-template>
          <ng-template #accountActions>
            <!-- Icon for regular view -->
            <a (click)="deleteAccount(account)" class="icon-delete-account uk-text-danger" [title]="'accounts.hide-account' | transloco" uk-tooltip><span uk-icon="icon: close;"></span></a>
            <!-- Button for compact view -->
            <button
              (click)="deleteAccount(account)"
              class="button-delete-account uk-button uk-button-secondary uk-text-danger uk-text-center uk-width-auto nlt-icon-button"
              type="button"
            ><span class="nlt-icon" uk-icon="icon: close;" style="vertical-align: 1px;"></span>{{ 'accounts.hide-account' | transloco | uppercase }}</button>
          </ng-template>
          <td class="account-column uk-visible-toggle uk-text-truncate" [class.identicons-enabled]="(settings.settings.identiconsStyle !== 'none')">
            <div uk-grid>
              <a
                (click)="navigateToAccount(account); $event.stopPropagation()"
                [routerLink]="( '/account/' + account.id )" [queryParams]="{ compact: '1' }"
                [class]="[ 'uk-width-auto', 'uk-text-truncate', 'account-container', !isLedgerWallet ? 'narrow' : 'wide' ]"
              >
                <div class="uk-flex">
                  <app-nano-identicon scale="12" [accountID]="account.id" [settingIdenticonsStyle]="settings.settings.identiconsStyle" class="nano-identicon" *ngIf="(settings.settings.identiconsStyle !== 'none')"></app-nano-identicon>
                  <div class="uk-width-expand">
                    <div class="uk-width-1-1">
                      <div class="account-index" *ngIf="viewAdvanced"><span>#</span>{{ account.index }}</div>
                      <div class="account-label">{{
                          account.addressBookName
                        ? account.addressBookName
                        : (
                            viewAdvanced
                          ? ('general.account' | transloco)
                          : ('general.account' | transloco) + ' #' + account.index
                        )
                      }}</div>
                    </div>
                    <div class="nano-address-clickable nano-address-monospace uk-text-truncate">
                      <app-nano-account-id [accountID]="account.id" middle="auto"></app-nano-account-id>
                    </div>
                  </div>
                </div>
              </a>
              <div class="nano-address-actions uk-width-auto copy-container">
                <ul class="uk-iconnav copy-icons">
                  <li><a ngxClipboard [cbContent]="account.id" (cbOnSuccess)="copied()" uk-icon="icon: copy" [title]="'general.copy' | transloco" uk-tooltip></a></li>
                  <li *ngIf="isLedgerWallet"><a uk-icon="icon: commenting" [title]="'accounts.confirm-address-on-ledger' | transloco" (click)="showLedgerAddress(account)" uk-tooltip></a></li>
                </ul>
              </div>
            </div>
            <div class="compact-account-amounts">
              <ng-template [ngTemplateOutlet]="accountAmounts"></ng-template>
            </div>
            <div class="compact-account-actions" [class.hidden-section]="( isSingleKeyWallet || !viewAdvanced )">
              <ng-template [ngTemplateOutlet]="accountActions"></ng-template>
            </div>
          </td>
          <td class="account-amounts-column uk-text-right">
            <ng-template [ngTemplateOutlet]="accountAmounts"></ng-template>
          </td>
          <td [class]="{
            'account-actions-column': true,
            'uk-text-right': true,
            'hidden-column': ( isSingleKeyWallet || !viewAdvanced )
          }">
            <div class="uk-float-right">
              <ng-template [ngTemplateOutlet]="accountActions"></ng-template>
            </div>
          </td>
        </tr>
        <tr *ngIf="!accounts.length">
          <td colspan="4" style="text-align: center;">{{ 'accounts.you-dont-have-any-accounts-yet.1' | transloco }} <a (click)="createAccount()">{{ 'accounts.you-dont-have-any-accounts-yet.2-link' | transloco }}</a> {{ 'accounts.you-dont-have-any-accounts-yet.3' | transloco }}</td>
        </tr>
        </tbody>
      </table>
      <table class="uk-table uk-table-striped uk-table-small" *ngIf="false && !isSingleKeyWallet">
        <thead>
          <tr class="uk-alert uk-alert-primary missing-accounts-hint">
            <td colspan="4" style="text-align: center;"><span uk-icon="icon: info"></span> {{ 'accounts.missing-accounts.1' | transloco }} <a class="hint-action" (click)="createAccount()">{{ 'accounts.missing-accounts.2-link' | transloco }}</a> {{ 'accounts.missing-accounts.3' | transloco }}</td>
          </tr>
        </thead>
      </table>
    </div>

  </div>
</div>
