<div *ngIf="walletService.isLedgerWallet()">
  <div class="nav-status-row" *ngIf="ledgerStatus === 'not-connected' || !ledgerStatus">
    <div class="status-icon">
      <span class="uk-text-warning" uk-icon="icon: warning; ratio: 1.2;"></span>
    </div>
    <div class="status-labels">
      <div class="label primary uk-text-warning">Ledger disconnected</div>
      <div class="label secondary"><a (click)="reloadLedger()">Reconnect</a> or <a href="https://docs.nault.cc/2020/08/04/ledger-guide.html#troubleshooting" target="_blank" rel="noopener noreferrer">view Troubleshooting Guide</a></div>
    </div>
  </div>
  <div class="nav-status-row" *ngIf="( ledgerStatus === 'locked' )">
    <div class="status-icon">
      <span class="uk-text-warning" uk-icon="icon: warning; ratio: 1.2;"></span>
    </div>
    <div class="status-labels">
      <div class="label primary uk-text-warning">Ledger locked</div>
      <div class="label secondary">Unlock the device, then <a (click)="reloadLedger()">click here</a> to reconnect</div>
    </div>
  </div>
  <div class="nav-status-row interactable" (click)="reloadLedger()" *ngIf="( ledgerStatus === 'ready' )">
    <div class="status-icon">
      <span class="uk-text-success" uk-icon="icon: bolt; ratio: 1.2;"></span>
    </div>
    <div class="status-labels">
      <div class="label primary uk-text-success">Ledger Ready</div>
    </div>
  </div>
</div>

<div class="nav-status-row" *ngIf="powAlert">
  <div class="status-icon">
    <span class="uk-text-warning" uk-icon="icon: warning; ratio: 1.2;"></span>
  </div>
  <div class="status-labels">
    <div class="label primary uk-text-warning">Proof of Work is taking a while...</div>
    <div class="label secondary"><a routerLink="/configure-app" routerLinkActive="active">Change PoW method</a> or <a (click)="cancelPow()">cancel PoW</a></div>
  </div>
</div>

<ng-container *ngIf="walletService.isConfigured() && !walletService.isLedgerWallet()">
  <div class="nav-status-row half-muted interactable" (click)="showModal()" *ngIf="walletService.isLocked()">
    <div class="status-icon">
      <span uk-icon="icon: lock; ratio: 1.2;" class="uk-text-top"></span>
    </div>
    <div class="status-labels">
      <div class="label primary half-muted">Wallet Locked</div>
    </div>
  </div>
  <div class="nav-status-row half-muted interactable" (click)="lockWallet()" *ngIf="!walletService.isLocked()">
    <div class="status-icon">
      <span uk-icon="icon: unlock; ratio: 1.2;" class="uk-text-top"></span>
    </div>
    <div class="status-labels">
      <div class="label primary half-muted">Wallet Unlocked</div>
    </div>
  </div>
</ng-container>

<a routerLink="/configure-wallet" class="nav-status-row interactable" *ngIf="!walletService.isConfigured()">
  <div class="status-icon">
    <span class="uk-text-warning" uk-icon="icon: warning; ratio: 1.2;"></span>
  </div>
  <div class="status-labels">
    <div class="label primary uk-text-warning">Configure Wallet</div>
  </div>
</a>

<div class="modal-position-bottom" id="unlock-wallet-modal" uk-modal>
  <div class="uk-modal-dialog">
    <button class="uk-modal-close-default" type="button" uk-close></button>
    <div class="uk-modal-header">
      <h2 class="uk-modal-title">Unlock</h2>
    </div>
    <div class="uk-modal-body">
      <input #passwordInput autofocus type="password" [(ngModel)]="unlockPassword" (keyup.enter)="unlockWallet()"
        [class]="{ 'uk-input': true, 'password-input': true, 'uk-form-danger': !mayAttemptUnlock }"
        [placeholder]="( mayAttemptUnlock ? 'Enter wallet password' : 'Incorrect password' )"
        [disabled]="!mayAttemptUnlock"
      >
    </div>
    <div class="uk-modal-footer uk-text-right nlt-button-group">
      <button class="uk-button uk-button-default uk-modal-close" type="button">Cancel</button>
      <button class="uk-button uk-button-primary" type="button" (click)="unlockWallet()" [disabled]="!mayAttemptUnlock">Unlock</button>
    </div>
  </div>
</div>
