<div class="notification-container">
  <div
  	*ngFor="let notification of notifications"
  	[class]="['uk-alert', notification.cssClass]"
  	class="uk-animation-slide-bottom-small wallet-notification"
  	(click)="removeNotification(notification)"
  	uk-alert
  >
    <a class="uk-alert-close close-notification" uk-close></a>
    <p [innerHTML]="notification.message"></p>
  </div>
</div>
